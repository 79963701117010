import { differenceInMinutes, differenceInSeconds } from 'date-fns';

export const findTimeDifference = (
  startDate: Date | string,
  endDate: Date | string,
) => {
  const minutes = differenceInMinutes(startDate, endDate);
  const seconds = differenceInSeconds(startDate, endDate) % 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return { minutes: formattedMinutes, seconds: formattedSeconds };
};
