import { useMutation } from '@tanstack/react-query';
import AuthAPIClient from '@/services/auth-api-client';

interface ForgotPassword {
  email: string;
}

export interface ErrorResponse {
  response: {
    data: {
      errors: string[];
    };
  };
}

const useForgotPassword = () => {
  const authApiClient = new AuthAPIClient('/v1/auth/password');

  return useMutation({
    mutationKey: ['forgotPassword'],
    mutationFn: (forgotPassword: ForgotPassword) =>
      authApiClient.forgotPassword(forgotPassword),
  });
};

export default useForgotPassword;
