import { addMinutes, isPast, parseISO } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';
import * as moment from 'moment-timezone';

interface LocalDateTimeInfo {
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  bookingExpired?: boolean;
}

export const combineDateAndTime = (
  dateString: string,
  timeString: string | null | undefined,
) => {
  const combinedDateTimeString = `${dateString}T${timeString}:00`;

  const dateTime = new Date(combinedDateTimeString);
  return dateTime.toString();
};

export const getBrowserTimeZone = () => moment?.tz?.guess();

export const convertUTCToLocalTime = (
  utcDateTimeString: string,
  taskDuration: number,
): LocalDateTimeInfo => {
  const utcDateTime = parseISO(utcDateTimeString);
  const localStartDateTime = toZonedTime(utcDateTime, getBrowserTimeZone());
  const localEndDateTime = addMinutes(localStartDateTime, taskDuration);
  const isTaskExpired = isPast(localEndDateTime);

  return {
    startDate: format(localStartDateTime, 'dd-MM-yyyy'),
    startTime: format(localStartDateTime, 'hh:mm a'),
    endDate: format(localEndDateTime, 'dd-MM-yyyy'),
    endTime: format(localEndDateTime, 'hh:mm a'),
    bookingExpired: isTaskExpired,
  };
};
