import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import ForgotPasswordForm from '@/components/Authentication/ForgotPasswordForm';
import ResetPasswordForm from '@/components/Authentication/ResetPasswordForm';
import SignInForm from '@/components/Authentication/SignInForm';
import SignUpForm from '@/components/Authentication/SignUpForm';
import StripeForm from '@/components/BookingConfirmation/StripeForm';
import ChangePassword from '@/components/DashBoard/ChangePassword';
import BookingPage from '@/components/DashBoard/ManageReservations/BookingPage';
import ManageStudioReservations from '@/components/DashBoard/ManageStudioReservations';
import ManageStudios from '@/components/DashBoard/ManageStudios';
import Index from '@/components/DashBoard/UserProfile';
import BasicLayout from '@/pages/Authentication';
import ConfirmationPage from '@/pages/ConfirmationPage';
import StudioFormPage from '@/pages/StudioFormPage';
import Dashboard from '@/pages/Dashboard';
import LandingPage from '@/pages/LandingPage';
import NavbarLayout from '@/pages/NavbarLayout';
import SearchBarLayout from '@/pages/SearchBarLayout';
import StudioDetailPage from '@/pages/StudioDetailPage';
import Studios from '@/pages/Studios';
import ErrorFallback from '@/components/Utilities/ErrorFallBack';
import CreateStudioConfirmation from '@/components/CreateStudio/CreateStudioConfirmation';
import PaymentSettings from '@/components/DashBoard/PaymentSettings';
import PaymentSettingsCallback from '@/components/DashBoard/PaymentSettingsCallback';
import BookingImport from '@/components/DashBoard/BookingImport';
import PrivateRoute from '@/routes/PrivateRoute';
import ListYourStudioCTAPage from '@/pages/ListYourStudioCTAPage';
import PrivacyPolicyPage from '@/pages/PrivacyPolicyPage';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LandingPage />
      </ErrorBoundary>
    ),
  },
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <SearchBarLayout />
      </ErrorBoundary>
    ),
    children: [
      { path: '/studios', element: <Studios /> },
      { path: '/studios/:id', element: <StudioDetailPage /> },
      { path: '/stripe', element: <StripeForm /> },
    ],
  },
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BasicLayout />
      </ErrorBoundary>
    ),
    children: [
      { path: '/privacy-policy', element: <PrivacyPolicyPage /> },
      {
        path: '/studios/:id/confirmation',
        element: (
          <PrivateRoute>
            <ConfirmationPage />
          </PrivateRoute>
        ),
      },
      {
        path: '/studios/new/confirmation', // Add the new route
        element: (
          <PrivateRoute>
            <CreateStudioConfirmation />
          </PrivateRoute>
        ),
      },
    ],
  },
  {
    path: '/',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <NavbarLayout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: 'list-your-studio',
        element: <ListYourStudioCTAPage />,
      },
      {
        path: '/studios/new',
        element: (
          <PrivateRoute>
            <StudioFormPage />
          </PrivateRoute>
        ),
      },
      {
        path: '/studios/edit/:id',
        element: (
          <PrivateRoute>
            <StudioFormPage />
          </PrivateRoute>
        ),
      },
    ],
  },

  {
    path: '/dashboard',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Dashboard />
      </ErrorBoundary>
    ),
    children: [
      { path: '/dashboard/bookings', element: <BookingPage /> },
      { path: '/dashboard/user_profile_edit', element: <Index /> },
      { path: '/dashboard/change_password', element: <ChangePassword /> },
      { path: '/dashboard/manage_studios', element: <ManageStudios /> },
      { path: '/dashboard/payment_settings', element: <PaymentSettings /> },
      {
        path: '/dashboard/payment_settings/callback',
        element: <PaymentSettingsCallback />,
      },
      {
        path: '/dashboard/manage_reservations',
        element: <ManageStudioReservations />,
      },
      {
        path: '/dashboard/booking_import',
        element: <BookingImport />,
      },
    ],
  },
  {
    path: 'auth',
    element: (
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <BasicLayout />
      </ErrorBoundary>
    ),
    children: [
      { path: 'sign_in', element: <SignInForm /> },
      { path: 'sign_up', element: <SignUpForm /> },
      { path: 'forgot_password', element: <ForgotPasswordForm /> },
      { path: 'reset_password', element: <ResetPasswordForm /> },
    ],
  },
]);

export default router;
