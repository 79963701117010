import React from 'react';
import { FormControl, Input, FormErrorMessage } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import CustomLabel from '@/components/Forms/CustomLabel';

interface Props {
  name: string;
  control: any;
  label: string;
  type?: string;
  isRequired?: boolean;
  defaultValue?: string;
  disabled?: boolean;
}

const CustomInputField = ({
  name,
  control,
  label,
  type = 'text',
  isRequired = false,
  disabled = false,
  defaultValue = '',
  ...props
}: Props) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field, fieldState }) => (
      <FormControl
        mt={2}
        id={name}
        isInvalid={!!fieldState.error}
        isRequired={isRequired}
      >
        <CustomLabel labelText={label} />
        <Input
          type={type}
          {...field}
          {...props}
          disabled={disabled}
          borderWidth="1px"
          borderColor="powder.600"
          _hover={{ borderColor: 'honey.600' }}
        />
        <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
      </FormControl>
    )}
  />
);

export default CustomInputField;
