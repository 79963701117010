import { useInfiniteQuery } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import ms from 'ms';
import APIClient, {
  FetchResponse,
  PaginationMeta,
} from '@/services/api-client';
import { flattenAttributes } from '@/components/Utilities/helper';
import { Studio } from '@/hooks/useStudios';

const apiClient = new APIClient<Studio>('/v1/studios/list_studios');

const useStudiosList = () => {
  const { data, ...rest } = useInfiniteQuery<FetchResponse<Studio>, Error>({
    queryKey: ['studios'],
    queryFn: async ({ pageParam = 1 }) =>
      apiClient.getAll({
        params: {
          page: pageParam,
          perPage: 12,
        },
      }),
    staleTime: ms('1d'),
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      const meta = lastPage.meta as PaginationMeta;
      return meta.nextPage;
    },
  });

  const totalEntries =
    data?.pages?.[data.pages.length - 1]?.meta?.totalEntries ?? 0;

  const flattenedData =
    data?.pages?.map((page) => ({
      ...page,
      data: flattenAttributes(page?.data || []),
    })) ?? [];

  return { totalEntries, data: { ...data, pages: flattenedData }, ...rest };
};

export default useStudiosList;
