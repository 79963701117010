import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormErrorMessage } from '@chakra-ui/react';
import CustomLabel from '@/components/Forms/CustomLabel';
import NumericStepperInput from '@/components/Forms/NumericStepperInput';

interface Props {
  name: string;
  control: any;
  labelText: string | React.ReactNode;
  toolTipLabel?: string | React.ReactNode;
  conversionFactor?: number;
}

const NumericStepperInputController: React.FC<Props> = ({
  name,
  control,
  labelText,
  conversionFactor = 1,
  toolTipLabel,
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormControl isInvalid={!!fieldState.error}>
        <CustomLabel labelText={labelText} mb={0} toolTipLabel={toolTipLabel} />
        <NumericStepperInput
          defaultValue={field.value / conversionFactor || 1}
          onChange={(value) => field.onChange(value * conversionFactor)}
          formatValue={(val: number) => `${val > 1 ? 'hours' : 'hour'}`}
          maxW="250px"
        />
        {fieldState.error?.message && (
          <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
        )}
      </FormControl>
    )}
  />
);

export default NumericStepperInputController;
