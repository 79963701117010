import React, { useEffect, useMemo, useState } from 'react';
import {
  Calendar,
  Views,
  dayjsLocalizer,
  View,
  Event,
} from 'react-big-calendar';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import timezone from 'dayjs/plugin/timezone';
import CustomCalendarToolbar from '@/components/ReactBigCalendar/CustomCalendarToolBar';
import CustomCalendarEvent from '@/components/ReactBigCalendar/CustomCalendarEvent';
import '@/components/ReactBigCalendar/calendar-style.scss';
import {
  DateRangeProps,
  handleRangeChange,
} from '@/components/ReactBigCalendar/helper';

interface Props {
  views?: View[];
  initialViews?: View;
  events?: Event[];
  onRangeChange?: (dateRange: DateRangeProps) => void;
  hideDateRangeSelector?: boolean;
  date?: Date;
  onSelectEvent?: (event: Event) => void;
  isBaseBreakpoint?: boolean;
  defaultBreakpointView?: View;
}

dayjs.extend(timezone);

const djLocalize = dayjsLocalizer(dayjs);

const ReactBigCalendar: React.FC<Props> = ({
  views: allowedViews = Object.values(Views),
  initialViews,
  events = [],
  onRangeChange,
  hideDateRangeSelector = false,
  date,
  onSelectEvent,
  isBaseBreakpoint,
  defaultBreakpointView = 'day',
}) => {
  const [currentDate, setCurrentDate] = useState(date);
  const [view, setView] = useState<View>(initialViews || 'day');
  const handleNavigate = (newDate: Date) => {
    setCurrentDate(newDate);
  };
  const { components, defaultDate, views } = useMemo(
    () => ({
      components: {
        event: CustomCalendarEvent,
        // eslint-disable-next-line react/no-unstable-nested-components
        toolbar: (toolbarProps: any) => (
          <CustomCalendarToolbar
            {...toolbarProps}
            hideDateRangeSelector={hideDateRangeSelector}
            hideViewSelector={allowedViews.length === 1}
          />
        ),
      },
      defaultDate: new Date(),
      views: allowedViews,
    }),
    [hideDateRangeSelector, allowedViews],
  );

  const getDefaultViews = () => {
    if (initialViews) {
      return initialViews;
    }
    if (allowedViews.includes('month')) {
      return 'month';
    }
    return allowedViews[0];
  };

  const eventStyleGetter = () => {
    const style = {
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      border: '1px solid #F4A935',
      borderLeft: '5px solid #F4A935',
      display: 'block',
      backgroundColor: '#FBDDAE',
    };

    return { style };
  };

  useEffect(() => {
    if (date !== currentDate) {
      setCurrentDate(date);
    }
  }, [date]);

  return (
    <Calendar
      components={components as any}
      defaultDate={defaultDate}
      date={currentDate}
      onNavigate={handleNavigate}
      eventPropGetter={eventStyleGetter}
      events={events}
      localizer={djLocalize}
      views={views}
      defaultView={getDefaultViews()}
      onRangeChange={(range) =>
        onRangeChange
          ? handleRangeChange(range, view, onRangeChange)
          : undefined
      }
      showMultiDayTimes
      onSelectEvent={onSelectEvent}
      view={isBaseBreakpoint ? defaultBreakpointView : view}
      onView={setView}
    />
  );
};

export default React.memo(ReactBigCalendar);
