import React, { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useHandleStripeCallback } from '@/components/Utilities/StripeConnection';
import LoadingSpinner from '@/components/LoadingSpinner';

const PaymentSettingsCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const handleStripeCallback = useHandleStripeCallback(code);
  const hasExecutedRef = useRef(false);

  useEffect(() => {
    if (code && !hasExecutedRef.current) {
      hasExecutedRef.current = true; // Mark as executed
      handleStripeCallback();
    }
  }, [code, handleStripeCallback]);

  return <LoadingSpinner />;
};

export default React.memo(PaymentSettingsCallback);
