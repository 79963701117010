import React from 'react';

export const timeOptions: JSX.Element[] = [];
// eslint-disable-next-line no-plusplus
for (let i = 0; i < 24; i++) {
  for (let j = 0; j < 60; j += 30) {
    const hour12 = i % 12 === 0 ? 12 : i % 12; // Convert 24-hour time to 12-hour for display
    const period = i < 12 ? 'AM' : 'PM';
    const hourFormatted12 = hour12.toString().padStart(2, '0'); // 12-hour format for display
    const hourFormatted24 = i.toString().padStart(2, '0'); // 24-hour format for value
    const minute = j.toString().padStart(2, '0');
    timeOptions.push(
      <option
        key={`${hourFormatted24}:${minute}`}
        value={`${hourFormatted24}:${minute}`}
      >
        {`${hourFormatted12}:${minute} ${period}`}
      </option>,
    );
  }
}
