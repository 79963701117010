import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  HStack,
  PinInput,
  PinInputField,
  FormControlProps,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

interface CustomPinInputProps extends FormControlProps {
  control: any;
  name: string;
  pinLength?: number;
  placeholder?: any;
}

const CustomPinInput: React.FC<CustomPinInputProps> = ({
  control,
  name,
  placeholder,
  pinLength = 6,
  ...formControlProps
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormControl isInvalid={!!fieldState.error} {...formControlProps}>
        <HStack justify="center">
          <PinInput
            value={field.value}
            onChange={field.onChange}
            otp
            size={{ base: 'xs', sm: 'md', md: 'lg' }}
            type="number"
            variant="outline"
            placeholder={placeholder}
            focusBorderColor="honey.500"
          >
            {[...Array(pinLength)].map((_, index) => (
              <PinInputField key={index} />
            ))}
          </PinInput>
        </HStack>
        <FormErrorMessage justifyContent="center">
          {fieldState.error?.message}
        </FormErrorMessage>
      </FormControl>
    )}
  />
);

export default React.memo(CustomPinInput);
