import { useMutation } from '@tanstack/react-query';
import AuthAPIClient from '@/services/auth-api-client';

export interface ErrorResponse {
  response: {
    data: {
      errors: {
        full_messages: string[];
      };
    };
  };
}

const useAuth = () => {
  const authApiClient = new AuthAPIClient('/v1/auth');

  return useMutation({
    mutationKey: ['auth'],
    mutationFn: (user: any) => authApiClient.signup(user),
  });
};

export default useAuth;
