import {
  Box,
  Button,
  Heading,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import useChangePassword from '@/hooks/useChangePassword';
import { passwordSchema } from '@/components/Authentication/helper';
import { BorderedBox } from '@/components/Utilities/CustomBorder';

const ChangePassword = () => {
  const changePasswordSchema = zod
    .object({
      currentPassword: zod.string().min(8),
      password: passwordSchema,
      passwordConfirmation: passwordSchema,
    })
    .refine((data) => data.password === data.passwordConfirmation, {
      // eslint-disable-next-line
      message: "Passwords don't match",
      path: ['passwordConfirmation'],
    });

  const { control, handleSubmit, reset } = useForm({
    mode: 'onChange',
    resolver: zodResolver(changePasswordSchema),
  });
  const { mutate, isPending } = useChangePassword();
  const showToast = useShowToastTop();

  const handleReset = () => {
    reset({
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    });
  };

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: (event) => {
        const { message } = event;
        showToast({
          message,
          status: 'success',
        });
        reset({
          currentPassword: '',
          password: '',
          passwordConfirmation: '',
        });
      },
      onError: (error) => {
        const errorMessage =
          (error as any).response.data.errors.full_messages[0] ||
          'An error occurred';
        showToast({ message: errorMessage, status: 'error' });
        reset({ currentPassword: '', password: '', passwordConfirmation: '' });
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ height: '90%' }}>
      <Heading fontSize={{ base: 'xl', md: '2xl' }}>Change Password</Heading>
      <Box rounded="xl" h="full" display="flex" alignItems="start" mt={15}>
        <BorderedBox
          display="flex"
          flexDirection="column"
          gap={6}
          w="full"
          maxW={{ base: 'full', lg: '2xl' }}
          bg={useColorModeValue('white', 'gray.700')}
          rounded="xl"
          p={8}
        >
          <CustomInputField
            name="currentPassword"
            label="Current Password"
            control={control}
            type="password"
            isRequired
          />
          <CustomInputField
            name="password"
            label="New Password"
            control={control}
            type="password"
            isRequired
          />
          <CustomInputField
            name="passwordConfirmation"
            label="Confirm New Password"
            control={control}
            type="password"
            isRequired
          />
          <Stack
            spacing={[4, 6]}
            width="full"
            direction={['column-reverse', 'row']}
          >
            <Button
              type="button"
              variant="outline"
              colorScheme="gray"
              w="full"
              onClick={handleReset}
              isDisabled={isPending}
            >
              Clear
            </Button>
            <Button
              type="submit"
              w="full"
              colorScheme="honey"
              isLoading={isPending}
            >
              Save
            </Button>
          </Stack>
        </BorderedBox>
      </Box>
    </form>
  );
};

export default ChangePassword;
