import React from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  IconButton,
  Image,
  Box,
  VStack,
  Heading,
  Flex,
  Text,
  Tag,
  Icon,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  MdAttachMoney,
  MdContactPhone,
  MdEmail,
  MdEvent,
  MdInfoOutline,
  MdTimer,
} from 'react-icons/md';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { FaUser } from 'react-icons/fa';
import {
  statusColors,
  FlattenedBooking,
} from '@/components/DashBoard/ManageReservations/helper';
import { formatDuration } from '@/components/Utilities/FormatDuration';
import { calculateEndDateTime } from '@/services/dateTimeUtility';

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  reservation: FlattenedBooking | null;
}

const ReservationViewModal: React.FC<ReservationModalProps> = ({
  isOpen,
  onClose,
  reservation,
}) => {
  if (!reservation) return null;

  const {
    image,
    roomName,
    studioName,
    duration,
    userEmail,
    netEarnings,
    status,
    bookingNumber,
    startDatetime,
    specialRequests,
    guestDetails,
  } = reservation;
  const DATE_FORMAT = 'dd MMM yy, hh:mm a';

  const reservationDetails = [
    {
      icon: MdEvent,
      label: 'Date:',
      value: `${format(new Date(startDatetime), DATE_FORMAT)} - ${format(calculateEndDateTime(new Date(startDatetime), duration), DATE_FORMAT)}`,
    },
    {
      icon: MdTimer,
      label: 'Duration:',
      value: formatDuration(duration),
    },
    userEmail && {
      icon: MdEmail,
      label: 'User Email:',
      value: userEmail,
    },
    guestDetails?.name && {
      icon: FaUser,
      label: 'User Name:',
      value: guestDetails?.name,
    },
    guestDetails?.email && {
      icon: MdEmail,
      label: 'Contact Email:',
      value: guestDetails?.email,
    },
    guestDetails?.contactNumber && {
      icon: MdContactPhone,
      label: 'Contact Number:',
      value: guestDetails?.contactNumber,
    },
    {
      icon: MdAttachMoney,
      label: 'Net Earnings:',
      value: `$${Number(netEarnings).toFixed(2)}`,
    },
    {
      icon: MdInfoOutline,
      label: 'Status:',
      value: (
        <Tag size="md" variant="solid" colorScheme={statusColors[status]}>
          {capitalize(status)}
        </Tag>
      ),
    },
  ].filter(Boolean);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: 'xs', sm: 'md', md: 'lg' }}
    >
      <ModalOverlay />
      <ModalContent>
        <IconButton
          aria-label="Close modal"
          icon={<CloseIcon />}
          position="absolute"
          right={-3}
          top={-3}
          bg="lightgray"
          borderRadius="full"
          size="sm"
          onClick={onClose}
          zIndex="modal"
        />
        <ModalBody p={0}>
          {image && (
            <Image
              src={image}
              objectFit="contain"
              borderTopRadius="md"
              width="100%"
              height="300px" // Fixed height for all images
            />
          )}
          <Box px={4} my={4}>
            <VStack spacing={4} align="stretch">
              <Heading size="md">{roomName}</Heading>
              <Heading size="sm" fontWeight="normal">
                {studioName}
              </Heading>
              <Grid templateColumns="1fr 2fr" gap={1} color="gray.600">
                {reservationDetails.map((detail: any, index) => (
                  <React.Fragment key={index}>
                    <GridItem>
                      <Flex alignItems="center">
                        {detail?.icon && <Icon as={detail.icon} mr={2} />}
                        <Text fontWeight="bold">{detail.label}</Text>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Text>{detail.value}</Text>
                    </GridItem>
                  </React.Fragment>
                ))}
              </Grid>
              <VStack spacing={1} align="stretch" mt={2}>
                {specialRequests && (
                  <Text>Special Requests: {specialRequests}</Text>
                )}
                <Text>Booking ID: {bookingNumber}</Text>
              </VStack>
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(ReservationViewModal);
