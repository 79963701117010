import React, { useRef } from 'react';
import { Box, Button, Flex, Icon, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { PiPencilLight, PiTrash } from 'react-icons/pi';
import { UserStudios } from '@/hooks/useOwnedStudios';
import { useStudioStore } from '@/services/store';
import { BorderedBox } from '@/components/Utilities/CustomBorder';
import TextWithTooltip from '@/components/TextWithTooltip';

type Props = {
  studio: UserStudios;
};

const cardActions = [
  {
    label: 'Edit',
    key: 'edit',
    icon: PiPencilLight,
    colorScheme: 'oak',
  },
  { label: 'Delete', key: 'delete', icon: PiTrash, colorScheme: 'honey' },
];

const StudioCard = ({ studio }: Props) => {
  const { updateDeleteModalVisibility, setStudioDetails } = useStudioStore();
  const navigate = useNavigate();
  const { studioName, roomName, price, images } = studio;
  const pricePerHour = price.toFixed(2);

  const studioNameRef = useRef<HTMLDivElement>(null);
  const roomNameRef = useRef<HTMLDivElement>(null);

  const handleAction = (key: string) => {
    if (key === 'delete') {
      setStudioDetails(studio as any);
      updateDeleteModalVisibility();
    } else if (key === 'edit') {
      navigate(`/studios/edit/${studio?.slug || studio.id}`);
    }
  };

  return (
    <Flex w="full" align="center" justify="center" mx="auto">
      <BorderedBox
        w="sm"
        bg="white"
        rounded="lg"
        h="425px"
        maxW="300px"
        overflow="hidden"
        _dark={{ bg: 'gray.800' }}
      >
        <Image w="full" h={56} fit="cover" src={images[0]} alt={studioName} />
        <Box p={4}>
          <TextWithTooltip
            text={studioName}
            fontSize="xl"
            ref={studioNameRef}
          />
          <TextWithTooltip text={roomName} fontSize="lg" ref={roomNameRef} />
          <Text py={2} color="gray.700" _dark={{ color: 'gray.400' }}>
            Price: ${pricePerHour}/hr
          </Text>
          <Flex gap={2} justifyContent="right" mt={4}>
            {cardActions.map(({ label, icon, key, colorScheme }) => (
              <Button
                key={label}
                // w="full"
                // borderRadius={0}
                // bg="white"
                mt={4}
                fontSize="sm"
                colorScheme={colorScheme}
                // textColor="garageGrey.500"
                display="flex"
                alignItems="center"
                justifyContent="start"
                onClick={() => handleAction(key)}
              >
                <Icon as={icon} h={4} w={4} mr={2} />
                {label}
              </Button>
            ))}
          </Flex>
        </Box>
      </BorderedBox>
    </Flex>
  );
};

export default React.memo(StudioCard);
