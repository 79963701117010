import * as moment from 'moment-timezone';
import { allCountries } from 'country-region-data';

export type TimeZoneData = {
  name: string;
  offset: string;
};

const countryMap = new Map<string, string>(
  allCountries.map(([name, code]) => [name, code]),
);

const getCountryCode = (countryName: string): string | undefined =>
  countryMap.get(countryName);

export const allTimeZones = (countryName?: string): TimeZoneData[] => {
  const countryCode = countryName ? getCountryCode(countryName) : undefined;
  const timeZones = countryCode
    ? moment.tz.zonesForCountry(countryCode)
    : moment.tz.names();
  return timeZones.map((tz) => {
    const offset = moment.tz(tz).format('Z');
    return { name: tz, offset };
  });
};
