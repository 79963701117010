import { useMutation } from '@tanstack/react-query';
import APIClient from '@/services/api-client';

interface User {
  user: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    mobileNumber: string;
  };
}

const apiClient = new APIClient<User>('/v1/profile');

// Update a user's profile
const useCreateStudio = () =>
  useMutation({
    mutationKey: ['userData'],
    mutationFn: (userData: User) => apiClient.patch(userData),
  });

export default useCreateStudio;
