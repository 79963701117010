import { Box, Divider, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { Studio } from '@/hooks/useStudios';

interface Props {
  studio: Studio;
  duration: number | null;
}

const PriceCalculator = ({ studio, duration }: Props) => {
  const { price } = studio;
  const priceInDollars = price || 0;

  const durationFormat = (durationInMin: number) => {
    const hours = durationInMin / 60;
    return hours > 1 ? `${hours} hours` : `${hours} hour`;
  };

  return (
    <Flex gap={2} paddingY={4} flexDirection="column" width="100%">
      <Box display="flex" justifyContent="space-between">
        <Text fontSize="md">
          {priceInDollars && duration
            ? `S$${priceInDollars} x ${durationFormat(duration)} `
            : '-'}
        </Text>
        <Text fontSize="lg">
          S$
          {priceInDollars && duration ? priceInDollars * (duration / 60) : '-'}
        </Text>
      </Box>
      <Divider my={2} borderColor="#D6D6D6" />
      <Box display="flex" justifyContent="space-between">
        <Text fontWeight="bold" fontSize="lg">
          Total
        </Text>
        <Text fontWeight="bold" fontSize="xl">
          S$
          {priceInDollars && duration
            ? (priceInDollars * (duration / 60)).toFixed(2)
            : '-'}
        </Text>
      </Box>
    </Flex>
  );
};

export default PriceCalculator;
