import { convertUTCToLocalTime } from '@/components/Utilities/FormatDateTime';
import { parseLocalDateTimeToUTC } from '@/services/dateTimeUtility';

export type Reservation = {
  id: number;
  userId: number;
  status: string;
  paymentStatus: string;
  bookingType: string;
  specialRequests: string | null;
  duration: number;
  bookingNumber: string;
  refundedAmount: number;
  guestDetails: string | null;
  totalPrice: number;
  netEarnings: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  userEmail: string;
};

export interface DateRangeProps {
  start: Date;
  end: Date;
}

export const buildEventForStudioDetails = (
  availability: any[] | undefined,
  isFromStudioListPage?: boolean,
): any[] =>
  availability?.map(({ attributes, ...rest }) => {
    const item = { ...rest, ...attributes };
    const { endDate, endTime } = convertUTCToLocalTime(
      item.startDatetime,
      item.duration,
    );

    return {
      id: item.id,
      title: isFromStudioListPage
        ? `${item?.studioName} - ${item?.roomName}`
        : 'Booked',
      start: new Date(item.startDatetime),
      end: parseLocalDateTimeToUTC(endDate, endTime),
    };
  }) || [];

export const handleRangeChange = (
  range: any,
  view: string | undefined,
  setRangeData: (dateFormat: DateRangeProps) => void,
) => {
  const customRangeFormat =
    Array.isArray(range) && range.length > 0
      ? { start: range[0], end: range[range.length - 1] }
      : range;

  setRangeData(customRangeFormat);
};
