import { Tooltip, Text } from '@chakra-ui/react';
import React, { forwardRef, RefObject, memo } from 'react';
import { useTextOverflow } from '@/hooks/useTextOverflow';

interface TextWithTooltipProps {
  text: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
}

/* eslint-disable react/prop-types */
const TextWithTooltip = forwardRef<HTMLDivElement, TextWithTooltipProps>(
  ({ text, fontSize = 'md', fontWeight = 'bold', color = 'gray.800' }, ref) => {
    const showTooltip = useTextOverflow(ref as RefObject<HTMLDivElement>);

    return (
      <Tooltip label={text} isDisabled={!showTooltip} hasArrow>
        <Text
          ref={ref} // Use ref here
          fontSize={fontSize}
          fontWeight={fontWeight}
          color={color}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {text}
        </Text>
      </Tooltip>
    );
  },
);
/* eslint-enable react/prop-types */

export default memo(TextWithTooltip);
