import React, { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
  Link,
  Collapse,
  Button,
  List,
  ListItem,
  useClipboard,
  Tooltip,
} from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';

const Faq = () => {
  const { hasCopied: hasCopiedFooter, onCopy: onCopyFooter } = useClipboard(
    'support@musehivebooking.com',
  );

  const [faq, setFaq] = useState([
    {
      question: 'What is MuseHive?',
      answer: (
        <>
          MuseHive is an online platform that connects studio owners with
          customers looking to book creative spaces. Whether your studio is
          ideal for practice, recording, or rehearsal, MuseHive makes it simple
          for customers to discover and book your space, helping you bookings.
        </>
      ),
      open: false,
    },
    {
      question: 'How do I sign up as a host on MuseHive?',
      answer: (
        <>
          You can register an account online{' '}
          <Link
            as={RouterLink}
            to="/auth/sign_up"
            color="blue.600"
            fontWeight="medium"
            _hover={{ textDecoration: 'underline' }}
          >
            here
          </Link>
          . The process takes less than 5 minutes. If you don’t have a Stripe
          account, follow the instructions in the payment settings to set one
          up. Once your Stripe Connect is set up (usually within 3 minutes),
          you’ll be able to start listing your space. We’ll review your listing
          and publish it once your studio has been verified.
        </>
      ),
      open: false,
    },
    {
      question: 'Am I eligible to list my space on MuseHive?',
      answer: (
        <>
          You're welcome to list on MuseHive as long as you have a space
          available.
        </>
      ),
      open: false,
    },
    // {
    //   question: 'How much commission does MuseHive charge?',
    //   answer: (
    //     <>
    //       We charge a flat 8% commission on completed bookings. No platform fees
    //       apply for listing your space or if a booking is canceled.
    //     </>
    //   ),
    //   open: false,
    // },
    {
      question: 'What if I already have existing bookings for my studio?',
      answer: (
        <>
          We offer a feature that allows you to import your existing
          reservations seamlessly into our database. This way, you can keep all
          bookings in one place without any overlap or missed details.
        </>
      ),
      open: false,
    },
    {
      question: 'What happens if there is a last-minute cancellation?',
      answer: (
        <>
          <Text>
            MuseHive has a last-minute cancellation policy to protect studio
            owners. Refunds are automatically processed based on the time
            remaining before the booking:
          </Text>

          <List spacing={2} mt={2} pl={4}>
            <ListItem>
              <Text as="span" fontWeight="bold">
                More than 48 hours before:
              </Text>{' '}
              100% refund
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                Between 24 and 48 hours before:
              </Text>{' '}
              50% refund
            </ListItem>

            <ListItem>
              <Text as="span" fontWeight="bold">
                Less than 24 hours before:
              </Text>{' '}
              No refund
            </ListItem>
          </List>

          <Text mt={4}>
            Refunds can take up to 10 business days to show up in your Stripe
            account.
          </Text>
        </>
      ),
      open: false,
    },
    {
      question: 'Is there a fee for online transactions?',
      answer: (
        <>
          Yes, all transactions on MuseHive are processed through Stripe, which
          applies a fee based on the payment method used. We accept Apple Pay,
          Google Pay, PayNow, and credit cards. For more details on Stripe’s
          fees, please visit their{' '}
          <Link
            href="https://stripe.com/en-sg/pricing?utm_campaign=APAC_SG_EN_Search_Brand_Core_EXA-PHR-21288937667&utm_medium=cpc&utm_source=google&ad_content=699502443023&utm_term=stripe%20fees%20singapore&utm_matchtype=e&utm_adposition=&utm_device=c&gad_source=1&gclid=CjwKCAjwg-24BhB_EiwA1ZOx8qdj7Clks6by7hU0BIs2piv30LXzd38whceK0mh1PzOWiLPnP4qjpxoC9nUQAvD_BwE"
            color="blue.600"
            fontWeight="medium"
            isExternal
            _hover={{ textDecoration: 'underline' }}
          >
            pricing page here
          </Link>
          .
        </>
      ),
      open: false,
    },
    {
      question: 'I need help with setting up my listing. Who can I contact?',
      answer: (
        <>
          If you need assistance with setting up your account and listing,
          please reach out to our team at{' '}
          <Text
            as="span"
            color="blue.600"
            fontWeight="medium"
            textDecoration="underline"
          >
            support@musehivebooking.com
          </Text>
          . We’ll guide you through the setup process and provide any additional
          advice you may need.
        </>
      ),
      open: false,
    },
  ]);

  const [showAll, setShowAll] = useState(false);

  const toggleFaq = (index: any) => {
    setFaq((prevFaq) =>
      prevFaq.map((item, i) => ({
        ...item,
        open: i === index ? !item.open : false,
      })),
    );
  };

  return (
    <Box py={{ base: 10, sm: 16, lg: 24 }}>
      <Box maxW="7xl" mx="auto" px={{ base: 4, sm: 6, lg: 8 }}>
        <Box textAlign="center" maxW="2xl" mx="auto">
          <Heading
            fontSize={{ base: '3xl', sm: '4xl', lg: '5xl' }}
            color="garageGrey.500"
          >
            Frequently Asked Questions
          </Heading>
        </Box>

        <VStack spacing={4} mt={{ base: 8, md: 16 }} maxW="3xl" mx="auto">
          {(showAll ? faq : faq.slice(0, 4)).map((item, index) => (
            <Box
              key={index}
              bg="white"
              border="1px"
              borderColor="oak.500"
              w="full"
              rounded="md"
              _hover={{ bg: 'oak.100' }}
            >
              <Flex
                align="center"
                justify="space-between"
                p={{ base: 4, sm: 6 }}
                cursor="pointer"
                onClick={() => toggleFaq(index)}
              >
                <Text
                  fontSize="lg"
                  fontWeight="semibold"
                  color="garageGrey.500"
                >
                  {item.question}
                </Text>
                <Icon
                  as={ChevronDownIcon}
                  boxSize={6}
                  color="oak.500"
                  transform={item.open ? 'rotate(180deg)' : 'rotate(0deg)'}
                  transition="transform 0.2s ease"
                />
              </Flex>

              <Collapse in={item.open} animateOpacity>
                <Box px={{ base: 4, sm: 6 }} pb={{ base: 4, sm: 6 }}>
                  <Text fontSize="md" color="garageGrey.400">
                    {item.answer}
                  </Text>
                </Box>
              </Collapse>
            </Box>
          ))}
          <Flex justify="flex-start">
            <Button
              onClick={() => setShowAll(!showAll)}
              colorScheme="oak"
              textDecoration="underline"
              variant="plain"
              rightIcon={showAll ? <ChevronUpIcon /> : <ChevronDownIcon />}
            >
              {showAll ? 'Show Less' : 'Show More'}
            </Button>
          </Flex>
        </VStack>

        <Text mt={9} textAlign="center" fontSize="base" color="garageGrey.500">
          Didn’t find the answer you are looking for? Contact our support at{' '}
          <Tooltip
            label="Email copied!"
            isOpen={hasCopiedFooter}
            closeOnClick={false}
          >
            <Text
              as="span"
              color="blue.600"
              cursor="pointer"
              fontWeight="medium"
              onClick={onCopyFooter}
              _hover={{ textDecoration: 'underline', color: 'blue.700' }}
            >
              support@musehivebooking.com
            </Text>
          </Tooltip>
        </Text>
      </Box>
    </Box>
  );
};

export default Faq;
