import { useMutation } from '@tanstack/react-query';
import APIClient from '@/services/api-client';

interface CancelBooking {
  cancellationReason: string;
  cancellationDescription: string;
  cancellationUserRole: string;
}

const useCancelStudioBooking = (userId: number, reservationId: number) => {
  const apiClient = new APIClient<CancelBooking>(
    `/v1/users/${userId}/reservations/${reservationId}/cancel`,
  );
  return useMutation({
    mutationKey: ['cancel', userId, reservationId],
    mutationFn: (updatedReservation: CancelBooking) =>
      apiClient.patch(updatedReservation),
  });
};

export default useCancelStudioBooking;
