import React, { useEffect } from 'react';
import { FormControl, Select } from '@chakra-ui/react';
import { SetLocalFormData } from '@/components/searchValidation';
import {
  selectFieldStyles,
  useBorderStyle,
} from '@/components/inputfields/inputUtilities';
import { getDurationOptions } from '@/components/inputfields/DurationOption';
import CustomLabel from '@/components/Forms/CustomLabel';

interface DurationInputProps {
  setLocalFormData: SetLocalFormData;
  localFormData: { duration: number | null };
  defaultValue?: number | null;
  height?: string;
  enableCustomStyles?: boolean;
  width?: string;
  minDuration?: number;
  hideDropDownIcon?: boolean;
  borderColor?: string
}

const DurationInput: React.FC<DurationInputProps> = ({
  setLocalFormData,
  localFormData,
  defaultValue,
  height,
  enableCustomStyles = true,
  width = 'auto',
  minDuration = 0,
  hideDropDownIcon = false,
  borderColor = 'powder.600'
}) => {
  const styles = enableCustomStyles ? selectFieldStyles() : {};
  const borderStyles = useBorderStyle('130px', 2, 'all');
  const durationOptions = getDurationOptions(minDuration);
  const selectedDuration =
    localFormData.duration?.toString() || defaultValue?.toString() || '';

  const handleUpdate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = parseInt(e.target.value, 10) || null;
    setLocalFormData((prev) => ({ ...prev, duration: value }));
  };

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const getIcon = () => (hideDropDownIcon ? <></> : undefined);

  useEffect(() => {
    if (minDuration && defaultValue && minDuration > defaultValue) {
      const nextValue = durationOptions.find((option) => !option.props.disabled)
        ?.props.value;
      if (nextValue) {
        setLocalFormData((prev) => ({ ...prev, duration: nextValue }));
      }
    } else if (defaultValue) {
      if (defaultValue % 30 !== 0) {
        const nextValue = durationOptions.find(
          (option) => option.props.value >= defaultValue,
        )?.props.value;
        if (nextValue) {
          setLocalFormData((prev) => ({ ...prev, duration: nextValue }));
        }
      }
    }
  }, [minDuration, defaultValue, durationOptions]);

  return (
    <FormControl {...(enableCustomStyles ? borderStyles : {})} w={width}>
      <CustomLabel labelText="Duration" mb={0} />
      <Select
        {...styles}
        height={height}
        value={selectedDuration}
        icon={getIcon()}
        onChange={handleUpdate}
        borderWidth={enableCustomStyles ? 0 : 1 }
        focusBorderColor='honey.300'
        _hover={{ borderColor: 'honey.300', borderWidth: enableCustomStyles ? '2px' : '1px' }}
        borderColor={borderColor}
      >
        <option value="" disabled>
          Duration
        </option>
        {durationOptions.map((option) => option)}
      </Select>
    </FormControl>
  );
};

export default React.memo(DurationInput);
