import React from 'react';
import { ButtonGroup, Button, ButtonGroupProps } from '@chakra-ui/react';

interface CustomButtonGroupProps extends ButtonGroupProps {
  buttons: {
    label: string | React.ReactNode;
    onClick: () => void;
    isDisabled?: boolean;
    variant?: string;
    hoverBgColor?: string;
  }[];
}

const CustomButtonGroup: React.FC<CustomButtonGroupProps> = ({
  buttons,
  ...props
}) => (
  <ButtonGroup {...props}>
    {buttons.map(
      (
        { label, isDisabled, onClick, variant, hoverBgColor, ...rest },
        index,
      ) => (
        <Button
          key={index}
          onClick={onClick}
          isDisabled={isDisabled}
          variant={variant || 'primary'}
          _hover={{ bg: hoverBgColor || 'default' }}
          {...rest}
        >
          {label}
        </Button>
      ),
    )}
  </ButtonGroup>
);

export default React.memo(CustomButtonGroup);
