import React from 'react';
import { Select } from '@chakra-ui/react';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CustomSelectProps {
  id: string;
  name: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  isLoading?: boolean;
  options: { value: string; label: string }[];
  labelText: string;
  placeholder?: string;
  customLabelStyle?: Record<string, string | number>;
  isRequired?: boolean;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  id,
  name,
  value,
  onChange,
  isLoading = false,
  options,
  labelText,
  placeholder = 'Select an option',
  customLabelStyle = {},
  isRequired,
}) => (
  <>
    <CustomLabel
      labelText={labelText}
      isRequired={isRequired}
      htmlFor={id}
      {...customLabelStyle}
    />
    <Select
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      isDisabled={isLoading}
    >
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option?.value} value={option?.value}>
          {option?.label}
        </option>
      ))}
    </Select>
  </>
);

export default React.memo(CustomSelect);
