import {
  GridItem,
  Input,
  FormControl,
  FormErrorMessage,
  GridItemProps,
} from '@chakra-ui/react';
import React from 'react';
import { Controller } from 'react-hook-form';
import CustomLabel from '@/components/Forms/CustomLabel';

interface Props {
  control: any;
  name: string;
  colSpan?: GridItemProps['colSpan'];
  isRequired?: boolean;
}

const AddressField = ({
  control,
  name,
  colSpan,
  isRequired,
  ...props
}: Props) => (
  <GridItem colSpan={colSpan}>
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={fieldState.isTouched && fieldState.invalid}
          mt={3}
          isRequired={isRequired}
        >
          <CustomLabel labelText="Address" />
          <Input
            {...field}
            placeholder="Address"
            _placeholder={{
              fontSize: 'sm',
              color: 'gray.400',
            }}
            {...props}
          />
          <FormErrorMessage>
            {fieldState.isTouched && fieldState.error?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  </GridItem>
);

export default AddressField;
