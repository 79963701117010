import { useBreakpointValue } from '@chakra-ui/react';

export const selectFieldStyles = () => ({
  padding: 0,
  variant: 'flushed',
  width: '100%',
  cursor: 'pointer',
  sx: {
    paddingBottom: '0px',
    paddingRight: '0px',
  },
  bg: 'white',
  border: '0',
  borderBottom: '2px solid',
  borderColor: 'transparent',
  _hover: {
    borderColor: 'honey.500',
  },
  _focus: {
    borderColor: 'honey.500',
    boxShadow: 'none',
  },
});

export const useBorderStyle = (width = '130px', mx = 2, borderType = 'all') => {
  const isBaseBreakpoint = useBreakpointValue({ base: true, md: false });

  let borderStyles;

  if (isBaseBreakpoint) {
    borderStyles = {
      border: '1px solid',
    };
    // eslint-disable-next-line no-param-reassign
    mx = 0;
  } else {
    // Adjust styles for larger screens based on borderType
    switch (borderType) {
      case 'left':
        borderStyles = {
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderLeft: '1px solid',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        };
        // eslint-disable-next-line no-param-reassign
        width = '130px';
        break;
      case 'right':
        borderStyles = {
          borderTop: '1px solid',
          borderBottom: '1px solid',
          borderRight: '1px solid',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        };
        break;
      default:
        borderStyles = {
          border: '1px solid',
        };
    }
  }

  return {
    borderRadius: 5,
    ...borderStyles,
    mx,
    pt: 2,
    pl: 4,
    pr: 4,
    borderColor: 'powder.600',
    width: isBaseBreakpoint ? 'full' : width,
    height: '65px',
  };
};

// When break
// set width to 100%
// set height to auto
// set padding to 2
// set mx to 0
