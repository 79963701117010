import React, { useEffect, useState, useMemo } from 'react';
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from '@choc-ui/chakra-autocomplete';

export interface Option {
  label: string | React.ReactNode;
  value: string;
  key?: string | number;
}

interface CustomAutoCompleteProps {
  options: Option[];
  placeholder?: string;
  onChange: (value: string, id?: string | number) => void;
  value?: string;
  inputStyle?: Record<string, any>;
  dropDownWidth?: string | number;
}

const CustomAutoComplete: React.FC<CustomAutoCompleteProps> = ({
  options,
  placeholder = 'Select an option',
  onChange,
  value = '',
  inputStyle = {},
  dropDownWidth = 'auto',
}) => {
  const [inputValue, setInputValue] = useState(value || '');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onChange(newValue);
  };

  const handleSelectOption = (selectedValue: string) => {
    const selectedOption = options.find(
      (option) => option.value === selectedValue,
    );
    setInputValue(selectedValue);
    onChange(selectedValue, selectedOption?.key);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <AutoComplete
      openOnFocus
      emptyState
      onSelectOption={({ item }) => handleSelectOption(item.value)}
    >
      <AutoCompleteInput
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        rounded="md"
        onChange={handleInputChange}
        height={10}
        p={2}
        pl={4}
        pr={4}
        _placeholder={{ fontSize: 'sm', color: 'gray.400' }}
        value={inputValue}
        {...inputStyle}
      />
      {options.length > 0 && (
        <AutoCompleteList
          gap={2}
          px={0}
          width={dropDownWidth}
          borderRadius="md"
        >
          {options.map((option) => (
            <AutoCompleteItem
              key={option.key || option.value}
              value={option.value}
              bg="white"
              color="garageGrey"
              _hover={{ bg: 'garageGrey.500', color: 'white' }}
              _focus={{ bg: 'garageGrey.500', color: 'white' }}
            >
              {option.label}
            </AutoCompleteItem>
          ))}
        </AutoCompleteList>
      )}
    </AutoComplete>
  );
};

export default React.memo(CustomAutoComplete);
