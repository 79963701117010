import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom';
import PageFooter from '@/components/Layouts/Footer/PageFooter';
import Sidebar from '@/components/DashBoard/SideBar';
import NavbarMain from '@/components/Layouts/Navbar/NavbarMain';

const DashboardLayout = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <>
      <NavbarMain />
      <Box
        maxWidth="1300px"
        width="100%"
        mx="auto"
        mt={6}
        px={4}
        display="flex"
        gap={10}
      >
        <Box
          width={{ base: '100%', md: '300px' }}
          flexShrink={0}
          overflowY="auto"
          height="100%"
          display={{ base: 'none', lg: 'block' }}
          position="sticky"
          top="90px"
        >
          <Sidebar />
        </Box>
        <Box flex="1" overflowY="auto">
          <Outlet />
        </Box>
      </Box>
      <PageFooter />
    </>
  );
};

export default React.memo(DashboardLayout);
