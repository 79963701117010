import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuthStore, useStudioStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import LoadingSpinner from '@/components/LoadingSpinner';
import { getStudioDetails } from '@/components/Utilities/StudiosHandler';
import StudioForm from '@/components/CreateStudio/StudioForm';
import { useStudioConstants } from '@/hooks/useStudioConstants';
import { handleStudioNavigation } from '@/components/Utilities/helper';

const StudioFormPage = () => {
  const { user } = useAuthStore();
  const { setImages, setStudioDetails, studioDetails } = useStudioStore();
  const { id } = useParams();
  const { data } = useStudioConstants(id!);
  const navigate = useNavigate();
  const showToast = useShowToastTop();
  const location = useLocation();

  useEffect(() => {
    handleStudioNavigation(navigate, showToast, user, location.pathname);
    return () => {
      setImages([]);
      setStudioDetails(null);
    };
  }, []);

  useEffect(() => {
    if (id && data?.daysOfWeek) {
      getStudioDetails(
        id,
        setStudioDetails,
        data?.daysOfWeek,
        showToast,
        navigate,
      );
    } else {
      setStudioDetails(null);
    }
  }, [id, setStudioDetails, data]);

  if (
    !user?.stripeAccountId ||
    user?.stripeAccountStatus?.toLowerCase() !== 'active' ||
    (id && !studioDetails) ||
    !data
  ) {
    return <LoadingSpinner height="calc(100vh - 88px)" />;
  }

  return <StudioForm data={data} />;
};

export default React.memo(StudioFormPage);
