export const commonErrorMessage =
  'An unexpected error occurred. Please try again.';

export const publicRoutes = [
  '/',
  '/auth/sign_in',
  '/auth/sign_up',
  '/auth/change_password',
  '/auth/reset_password',
  '/auth/forgot_password',
  '/studios',
  '/studios/:id',
  '/list-your-studio',
  '/privacy-policy',
];

export const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Other', label: 'Other' },
];

export const countryCode = '+65';

export const profileDetailsUpdateMessage =
  'Please update your profile to proceed';

export const verifyContactMessage =
  'Please verify your contact number to proceed';
