import { Controller } from 'react-hook-form';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
} from '@chakra-ui/react';
import React, { FC, memo } from 'react';
import CustomLabel from '@/components/Forms/CustomLabel';

interface DataProps {
  label: string;
  value: string;
  [key: string]: any;
}

interface Props {
  control: any;
  name: string;
  labelText: string;
  isRequired?: boolean;
  data?: DataProps[];
  initialValue?: string;
}

const CustomRadioGroup: FC<Props> = ({
  control,
  name,
  labelText,
  isRequired,
  data,
  initialValue = '',
}) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, onBlur, value, ref }, fieldState }) => (
      <FormControl as={GridItem} isInvalid={!!fieldState.error}>
        <CustomLabel labelText={labelText} isRequired={isRequired} />
        <RadioGroup
          colorScheme="honey"
          value={value || initialValue}
          onChange={onChange}
        >
          <Stack direction={['column', 'row']} gap={4}>
            {(data || []).map((option, index) => (
              <Radio key={index} value={option.value} onBlur={onBlur} ref={ref}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
        <FormErrorMessage>
          {fieldState.isTouched && fieldState.error?.message}
        </FormErrorMessage>
      </FormControl>
    )}
  />
);

export default memo(CustomRadioGroup);
