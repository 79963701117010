import React from 'react';
import { Button } from '@chakra-ui/react';

interface CustomSubmitButtonProps {
  children: React.ReactNode;
  background?: string;
  isLoading?: boolean;
  onClick?: () => void | null;
  mt?: number;
  width?: string;
}

const CustomSubmitButton: React.FC<CustomSubmitButtonProps> = ({
  children,
  isLoading = false,
  background = 'garageGrey.500',
  onClick = () => {},
  mt,
  width,
  ...props
}) => (
  <Button
    type="submit"
    loadingText="Submitting"
    size="lg"
    bg={background}
    color="white"
    _hover={{
      bg: 'garageGrey.600',
    }}
    onClick={onClick}
    mt={mt}
    isLoading={isLoading}
    width={width}
    {...props}
  >
    {children}
  </Button>
);

export default CustomSubmitButton;
