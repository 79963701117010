import { memo } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import FormModal from '@/components/Modal/FormModal';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import CustomNumberInput from '@/components/Forms/CustomNumberInput';
import { countryCode } from '@/components/Utilities/constants';
import { OTVerificationSchema } from '@/components/DashBoard/UserProfile/helper';
import useSendOtp from '@/hooks/useSendOtp';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';

const ContactNumberModal = () => {
  const { isContactModalVisible, setContactModalVisibility, isBtnLoading } =
    useUserProfileStore();
  const { user } = useAuthStore();
  const handleSendOtp = useSendOtp();
  const toast = useShowToastTop();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { mobileNumber: '' },
    resolver: zodResolver(OTVerificationSchema),
  });

  const handleSubmit = (data: { mobileNumber: string }) => {
    if (user?.mobileNumber === data.mobileNumber) {
      toast({
        message:
          'The new mobile number must be different from the existing one.',
        status: 'error',
      });
    } else {
      handleSendOtp(data.mobileNumber, countryCode, false);
    }
  };

  return (
    <FormModal
      visible={isContactModalVisible}
      onClose={() => setContactModalVisibility(false)}
      onSubmit={handleSubmit}
      title="OTP Verification"
      methods={methods}
      modalSize={{ base: 'xs', sm: 'sm' }}
      hideCancelButton
      modalFooterStyle={{ border: 0, pt: 0 }}
      submitButtonStyle={{ width: '100%' }}
      submitButtonText="Get OTP"
      isSubmitting={isBtnLoading}
    >
      <VStack justifyContent="space-between">
        <Text fontSize="sm" color="gray">
          Enter your new mobile number to receive a One-Time Password for
          verification.
        </Text>
        <CustomNumberInput
          name="mobileNumber"
          inputText="Contact No."
          countryCode={countryCode}
          control={methods.control}
          isRequired
          hideLabel
        />
      </VStack>
    </FormModal>
  );
};

export default memo(ContactNumberModal);
