import { useCallback } from 'react';
import ApiClient from '@/services/api-client';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import { commonErrorMessage } from '@/components/Utilities/constants';

const useVerifyOtp = () => {
  const { user, setAuth } = useAuthStore();
  const { setOTPModalVisibility, setIsBtnLoading } = useUserProfileStore();
  const showToast = useShowToastTop();
  const apiClient = new ApiClient<any>(`v1/users/${user?.id}/verify_otp`);

  return useCallback(
    async (otp: string) => {
      setIsBtnLoading(true);
      try {
        const response = await apiClient.post(
          {},
          {
            params: {
              otp,
            },
          },
        );
        showToast({
          message: response?.message,
          status: 'success',
        });
        setAuth({ ...user, ...(response?.data || {}) });
        setOTPModalVisibility(false);
      } catch (error: any) {
        const errorMessage = error?.response?.data?.error || commonErrorMessage;
        showToast({
          message: errorMessage,
          status: 'error',
        });
      } finally {
        setIsBtnLoading(false);
      }
    },
    [apiClient],
  );
};

export default useVerifyOtp;
