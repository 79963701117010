import { Card, CardBody, Skeleton, SkeletonText } from '@chakra-ui/react';
import React from 'react';

interface GameCardSkeletonProps {
  width?: string;
}

const GameCardSkeleton: React.FC<GameCardSkeletonProps> = ({
  width = '100%',
}) => (
  <Card>
    <Skeleton height="200px" width={width} />
    <CardBody>
      <SkeletonText />
    </CardBody>
  </Card>
);

export default GameCardSkeleton;
