import { useMutation } from '@tanstack/react-query';
import AuthAPIClient from '@/services/auth-api-client';

interface ResetPassword {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
  uid: string;
  client: string;
  accessToken: string;
}

const useResetPassword = () => {
  const authApiClient = new AuthAPIClient('/v1/auth/password');

  return useMutation({
    mutationKey: ['resetPassword'],
    mutationFn: (resetPasswordData: ResetPassword) =>
      authApiClient.resetPassword(resetPasswordData),
  });
};

export default useResetPassword;
