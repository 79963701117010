import React from 'react';
import { Text, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import { calculateEndDateTime } from '@/services/dateTimeUtility';

interface BookingTimeDetailsProps {
  startDateTime: string;
  duration: number;
}

const StudioBookingTimeDetails: React.FC<BookingTimeDetailsProps> = ({
  startDateTime,
  duration,
}) => {
  const DATE_FORMAT = 'EEE, dd MMM yyyy, hh:mm a';
  const formattedStartDate = format(new Date(startDateTime), DATE_FORMAT);
  const formattedEndDate = format(
    calculateEndDateTime(new Date(startDateTime), duration),
    DATE_FORMAT,
  );

  return (
    <Flex direction="column">
      <Text fontSize="sm">Start Date: {formattedStartDate}</Text>
      <Text fontSize="sm">End Date: {formattedEndDate}</Text>
    </Flex>
  );
};

export default React.memo(StudioBookingTimeDetails);
