import React from 'react';
import { VStack, Flex } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import NavbarMain from '@/components/Layouts/Navbar/NavbarMain';
import PageFooter from '@/components/Layouts/Footer/PageFooter';

const NavbarLayout = () => (
  <VStack spacing={0} minHeight="100vh">
    <NavbarMain />
    <Flex flex="1" direction="column" width="full">
      <Outlet />
    </Flex>
    <PageFooter />
  </VStack>
);

export default NavbarLayout;
