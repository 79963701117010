import { useToast } from '@chakra-ui/react';

export interface ToastTopProps {
  message: string | undefined;
  status: 'info' | 'warning' | 'success' | 'error' | 'loading' | undefined;
  duration?: number;
  title?: string;
  colorScheme?: string;
}

export const useShowToastTop = () => {
  const toast = useToast();
  return (props: ToastTopProps) => {
    toast({
      title: props.message,
      status: props.status,
      duration: props.duration || 3000,
      isClosable: true,
      position: 'top',
      colorScheme: props?.colorScheme,
    });
  };
};
