import { FC, memo } from 'react';
import ManageReservationAddModal from '@/components/DashBoard/ManageReservations/ManageReservationAddModal';
import CancelBookingDialog from '@/components/DashBoard/ManageReservations/CancelBookingDialog';
import ReservationViewModal from '@/components/DashBoard/ManageReservations/ReservationViewModal';
import { FlattenedBooking } from '@/components/DashBoard/ManageReservations/helper';

interface ModalsProps {
  selectedReservation: FlattenedBooking | null;
  onClose: () => void;
  isOpen: boolean;
  user: any;
  handleReFetch: () => void;
}

const ManageReservationModals: FC<ModalsProps> = ({
  selectedReservation,
  onClose,
  isOpen,
  user,
  handleReFetch,
}) => (
  <>
    <ManageReservationAddModal user={user} refetch={handleReFetch} />
    {selectedReservation && (
      <CancelBookingDialog
        reservationId={selectedReservation.id}
        userId={selectedReservation.userId}
        bookingType={selectedReservation?.bookingType}
        refetch={handleReFetch}
        userRole="studio_owner"
      />
    )}
    <ReservationViewModal
      isOpen={isOpen}
      onClose={onClose}
      reservation={selectedReservation}
    />
  </>
);

export default memo(ManageReservationModals);
