import React, { FC, memo, useEffect, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { UserReservation } from '@/hooks/useUserReservations';
import StripeForm from '@/components/BookingConfirmation/StripeForm';
import { findTimeDifference } from '@/components/BookingConfirmation/helper';
import { Studio } from '@/hooks/useStudios';
import BookingInfoModal from '@/components/BookingConfirmation/BookingInfoModal';

interface PaymentSessionTimeoutProps {
  reservation: UserReservation;
  studio: Studio;
}

const PaymentSessionTimeout: FC<PaymentSessionTimeoutProps> = ({
  reservation,
  studio,
}) => {
  const [timeDifference, setTimeDifference] = useState(() => {
    const { minutes, seconds } = findTimeDifference(
      reservation?.reservationSessionTimeout || '',
      new Date(),
    );
    if (minutes <= '00' && seconds <= '00') {
      return { minutes: '00', seconds: '00' };
    }
    return { minutes, seconds };
  });

  useEffect(() => {
    if (!reservation?.reservationSessionTimeout) return;

    const timer = setInterval(() => {
      const { minutes, seconds } = findTimeDifference(
        reservation?.reservationSessionTimeout || '',
        new Date(),
      );
      if (minutes <= '00' && seconds <= '00') {
        clearInterval(timer);
        setTimeDifference({ minutes: '00', seconds: '00' });
      } else {
        setTimeDifference({ minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [reservation]);

  const isTimeoutReached =
    timeDifference.minutes <= '00' && timeDifference.seconds <= '00';

  return (
    <>
      {timeDifference && (
        <Box display="flex" gap={2} bg="honey.100" w="full" p={2}>
          Your reservation payment will expire in
          <Text color="red">
            {timeDifference.minutes}:{timeDifference.seconds}
          </Text>
        </Box>
      )}
      {!isTimeoutReached && <StripeForm studio={studio} />}
      <BookingInfoModal
        visible={isTimeoutReached}
        studioId={studio.slug || studio.id}
      />
    </>
  );
};

export default memo(PaymentSessionTimeout);
