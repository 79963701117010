import React from 'react';
import { Box, Flex, Heading, VStack } from '@chakra-ui/react';
import HomeBanner from '@/components/Layouts/Banner/HomeBanner';
import PageFooter from '@/components/Layouts/Footer/PageFooter';
import NavbarMain from '@/components/Layouts/Navbar/NavbarMain';
import StudioList from '@/components/StudioList';
import ScrollToTopButton from '@/components/Utilities/ScrollToTopButton';

const LandingPage = () => {
  const title =
    'Musehive | Studios rental, jamming studios, practice rooms & more';
  const description =
    'Find and book the best studios with MuseHive booking platform.';
  const image = 'https://musehivebooking.com/assets/homepage-banner.jpg';
  const url = 'https://musehivebooking.com/';

  return (
    <VStack spacing={0} minHeight="100vh" overflow="auto">
      {/* Page Content */}
      <ScrollToTopButton />
      <NavbarMain />
      <HomeBanner />
      <Flex
        direction="column"
        width="full"
        justifyContent="center"
        maxW="1300px"
        mx="auto"
        py={10}
      >
        <Box mt={10}>
          <Heading size="lg" p={4} mx={2}>
            Discover Studios
          </Heading>
        </Box>
        <Box>
          <StudioList />
        </Box>
        <Box>
          <PageFooter />
        </Box>
      </Flex>
    </VStack>
  );
};

export default LandingPage;
