import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { FormControl, Text, useBreakpointValue } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { SearchInputProps } from '@/components/searchValidation';
import { useBorderStyle } from '@/components/inputfields/inputUtilities';
import CustomLabel from '@/components/Forms/CustomLabel';
import useTopSearchStudios from '@/hooks/useGetTopSearch';
import { Studio } from '@/hooks/useStudios';
import { useStudioQueryStore } from '@/services/store';
import CustomAutoComplete from '@/components/Forms/CustomAutoComplete';

const SearchInput = ({
  inputStyles,
  setLocalFormData,
  value: initialValue = '',
}: SearchInputProps) => {
  const width = useBreakpointValue({ base: 'full', md: '250px' });
  const borderStyles = useBorderStyle(width, 2, 'all');

  const [value, setValue] = useState<string>(initialValue || '');

  const { setStudioSearchQuery, studioSearchQuery } = useStudioQueryStore();
  const { data, isLoading } = useTopSearchStudios(studioSearchQuery);

  const debouncedHandleChange = useMemo(
    () =>
      debounce((newValue: string) => {
        setStudioSearchQuery({
          studioName: newValue,
          address: newValue,
          roomName: newValue,
        });
        setLocalFormData((prev) => ({ ...prev, query: newValue }));
        setValue(newValue);
      }, 300),
    [setLocalFormData, setStudioSearchQuery]
  );

  const handleChange = useCallback(
    (newValue: string) => {
      debouncedHandleChange(newValue);
    },
    [debouncedHandleChange]
  );

  // Cancel debounce on component unmount
  useEffect(() => {
    return () => {
      debouncedHandleChange.cancel();
    };
  }, [debouncedHandleChange]);


  useEffect(() => {
    setValue(initialValue || '');
    setLocalFormData((prev: any) => ({
      ...prev,
      query: initialValue,
    }));
  }, [initialValue, setLocalFormData]);

  const searchOptions = useMemo(() => {
    if (Array.isArray(data) && !isLoading) {
      return data.map((item: Studio) => ({
        value: `${item.studioName}, ${item.roomName}, ${item.location.address}`,
        label: (
          <Text noOfLines={2}>
            {item.studioName}, {item.roomName}, {item.location.address}
          </Text>
        ),
        key: item.id,
      }));
    }
    return [];
  }, [data, isLoading]);

  return (
    <FormControl {...borderStyles}>
      <CustomLabel labelText="Where" mb={0} />
      <CustomAutoComplete
        options={searchOptions || []}
        onChange={handleChange}
        inputStyle={inputStyles}
        value={value}
        dropDownWidth={400}
      />
    </FormControl>
  );
};

export default React.memo(SearchInput);
