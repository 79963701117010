import React, { useState } from 'react';
import {
  Box,
  Grid,
  Image,
  GridItem,
  useBreakpointValue,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Button,
} from '@chakra-ui/react';
import ImageCarouselModal from '@/components/StudioImages/ImageCarouselModal';

interface Props {
  imagesURLs: string[] | undefined;
}

interface ImageItem {
  img: string;
}

const ImagesLayout = ({ imagesURLs }: Props) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const gridTemplateColumns = useBreakpointValue({
    base: '1fr',
    md: '2fr 1fr 1fr',
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const dummyImageUrl = 'https://via.placeholder.com/150';

  const images: ImageItem[] = [];

  for (let i = 0; i < 5; i += 1) {
    images.push({
      img: imagesURLs && i < imagesURLs.length ? imagesURLs[i] : dummyImageUrl,
    });
  }

  const openModal = (index: number) => {
    setCurrentImageIndex(index);
    onOpen();
  };

  return (
    <>
      {isMobile ? (
        <ImageCarouselModal
          slides={images}
          setCurrentSlide={setCurrentImageIndex}
          currentSlide={currentImageIndex}
        />
      ) : (
        <Grid
          templateRows="repeat(2, 1fr)"
          templateColumns={gridTemplateColumns}
          gap={2}
          height={{ base: 'auto', md: '400px', lg: '500px', xl: '600px' }}
          overflow="hidden"
        >
          <GridItem
            rowSpan={{ base: 1, md: 2 }}
            borderLeft={{ base: 0, md: 10 }}
            overflow="hidden"
          >
            <Image
              src={images[0].img}
              alt="Segun Adebayo"
              boxSize="full"
              objectFit="cover"
              onClick={() => openModal(0)}
              _hover={{ cursor: 'pointer', filter: 'brightness(0.8)' }}
              borderLeftRadius={{ base: 0, md: 10 }}
            />
          </GridItem>
          {Array.from(Array(4).keys()).map((index) => (
            <GridItem key={index} overflow="hidden">
              <Image
                src={images[index + 1].img}
                alt="Segun Adebayo"
                boxSize="full"
                objectFit="cover"
                onClick={() => openModal(index + 1)}
                _hover={{ cursor: 'pointer', filter: 'brightness(0.8)' }}
                borderTopRightRadius={index === 1 ? { base: 0, md: 10 } : 0}
                borderBottomRightRadius={index === 3 ? { base: 0, md: 10 } : 0}
              />
            </GridItem>
          ))}
        </Grid>
      )}
      {isOpen && (
        <Modal isOpen onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent>
            <Box position="absolute" top="4" right="4">
              {/* Custom Close Button */}
              <Button
                onClick={onClose}
                bg="transparent"
                color="gray.400"
                _hover={{ bg: 'transparent', color: 'white' }}
              >
                Close <span style={{ marginLeft: '8px' }}>X</span>
              </Button>
            </Box>
            <ModalBody
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="black"
            >
              <ImageCarouselModal
                slides={images}
                setCurrentSlide={setCurrentImageIndex}
                currentSlide={currentImageIndex}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default ImagesLayout;
