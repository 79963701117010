import {
  Box,
  Heading,
  VStack,
  Text,
  Flex,
  Link as ChakraLink,
} from '@chakra-ui/react';
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { orderBy, isEqual } from 'lodash';
import { isPast } from 'date-fns';
import useUserReservations, {
  UserReservation,
} from '@/hooks/useUserReservations';
import { useAuthStore } from '@/services/store';
import { convertUTCToLocalTime } from '@/components/Utilities/FormatDateTime';
import ReservationCard from '@/components/DashBoard/ManageReservations/ReservationCard';

const BookingPage = () => {
  const { user } = useAuthStore();
  const timeInterval = 30000;
  const [queryParams, setQueryParams] = useState<number[]>([]);
  const [reservationList, setReservationList] = useState<UserReservation[]>([]);

  const {
    data: reservations,
    isError: reservationError,
    refetch,
  } = useUserReservations(user?.id, queryParams);
  let intervalId: any;

  const updateQueryParams = useCallback(() => {
    if (reservations && Array.isArray(reservations)) {
      const pendingReservations = reservations.filter(
        (item: UserReservation) =>
          item.status === 'pending' && !isPast(item.startDatetime),
      );
      setQueryParams(
        pendingReservations.map((item: UserReservation) => item.id),
      );
      if (pendingReservations.length === 0) {
        clearInterval(intervalId);
      } else {
        refetch();
      }
    }
  }, [reservations]);

  useEffect(() => {
    if (reservations && Array.isArray(reservations)) {
      const updatedReservations = reservationList.map((reservation) => {
        const matchingReservation = reservations.find(
          (item: UserReservation) => item.id === reservation.id,
        );
        return matchingReservation || reservation;
      });
      const shouldUpdateReservations =
        !isEqual(updatedReservations, reservationList) ||
        (updatedReservations.length === 0 &&
          reservationList.length === 0 &&
          reservations.length > 0) ||
        updatedReservations.length !== reservations.length;

      if (shouldUpdateReservations) {
        setReservationList(
          queryParams.length > 0 ? updatedReservations : reservations,
        );
      }
    }

    intervalId = setInterval(updateQueryParams, timeInterval);
    return () => clearInterval(intervalId);
  }, [reservations]);

  const sortedReservations = useMemo(() => {
    if (!reservationList || reservationList.length === 0) return [];

    return orderBy(
      reservationList,
      [
        (reservation) =>
          convertUTCToLocalTime(reservation.startDatetime, reservation.duration)
            .bookingExpired,
        (reservation) => reservation.status === 'cancelled',
        'createdAt',
      ],
      ['asc', 'asc', 'desc'],
    );
  }, [reservationList]);

  if (reservationError) {
    return <Box>Something went wrong, please try again later.</Box>;
  }

  if (sortedReservations.length === 0) {
    return (
      <Flex align="center" justify="center">
        <VStack spacing={4}>
          <Text fontSize="lg" mb={4}>
            You have no reservations.
          </Text>
          <Text>Your Next Experience Awaits. Book Today!</Text>
          <ChakraLink
            as={RouterLink}
            to="/"
            colorScheme="honey"
            fontWeight="bold"
            fontSize="lg"
          >
            Make a Reservation
          </ChakraLink>
        </VStack>
      </Flex>
    );
  }

  return (
    <Box>
      <Heading fontSize={{ base: 'xl', md: '2xl' }} mb={4}>
        My Bookings
      </Heading>
      <VStack gap={4}>
        {sortedReservations.map((reservation: UserReservation) => (
          <ReservationCard
            key={reservation.id}
            reservation={reservation}
            refetch={refetch}
          />
        ))}
      </VStack>
    </Box>
  );
};

export default BookingPage;
