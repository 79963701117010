import { useCallback } from 'react';
import ApiClient from '@/services/api-client';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import { commonErrorMessage } from '@/components/Utilities/constants';

const useSendOtp = () => {
  const { user, setAuth } = useAuthStore();
  const {
    setOTPModalVisibility,
    setContactModalVisibility,
    setIsBtnLoading,
    setResendIsBtnLoading,
  } = useUserProfileStore();
  const showToast = useShowToastTop();
  const apiClient = new ApiClient<any>(`v1/users/${user?.id}/send_otp`);
  return useCallback(
    async (mobileNumber: string, countryCode: string, resendOtp: boolean) => {
      setIsBtnLoading(true);
      if (resendOtp) {
        setResendIsBtnLoading(true);
      }
      try {
        const response = await apiClient.post(
          {},
          {
            params: {
              mobile_number: mobileNumber,
              country_code: countryCode,
              resend_otp: resendOtp,
            },
          },
        );
        showToast({
          message: response?.message,
          status: 'success',
        });
        setAuth({ ...user, ...(response?.data || {}) });
        setContactModalVisibility(false);
        setOTPModalVisibility(true);
      } catch (error: any) {
        const errorMessage = error?.response?.data?.error || commonErrorMessage;
        showToast({
          message: errorMessage,
          status: 'error',
        });
      } finally {
        setIsBtnLoading(false);
        setResendIsBtnLoading(false);
      }
    },
    [apiClient],
  );
};

export default useSendOtp;
