import {
  ListItem,
  List,
  Text,
  Box,
  Link,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
} from '@chakra-ui/react';
import React, { useState } from 'react';

const CancellationPolicy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onOpenModal = () => setIsModalOpen(true);
  const onCloseModal = () => setIsModalOpen(false);

  return (
    <div>
      <Text fontWeight="bold" mb={4}>
        Cancellation & Refund Policy
      </Text>
      <Text fontSize="sm">
        By proceeding with the payment, I acknowledge and agree to MuseHive's{' '}
        <Link color="blue.500" textDecoration="underline" onClick={onOpenModal}>
          Cancellation and Refund Policy
        </Link>
        . I also understand and accept that my payment method may be charged by
        MuseHive in the event that I am liable for any damages incurred.
      </Text>

      <Modal isOpen={isModalOpen} onClose={onCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Cancellation & Refund Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Insert the policy details here */}
            {/* You can reuse the existing Box component with the List */}
            <Box padding={2} height="300px" overflowY="scroll">
              <Text fontSize="sm">
                <List spacing={2}>
                  {' '}
                  <ListItem>
                    Cancellations made more than 48 hours before the scheduled
                    booking time will receive a full refund.
                  </ListItem>
                  <ListItem>
                    Cancellations made less than 48 hours in advance will be
                    charged 50% of the booking fee.
                  </ListItem>
                  <ListItem>
                    Cancellations made less than 24 hours in advance will be
                    charged the full booking fee.
                  </ListItem>
                  <ListItem>
                    Failure to arrive for your booking without prior
                    cancellation will be considered a no-show and will not be
                    eligible for a refund.
                  </ListItem>
                  <ListItem>
                    Exceptions to this policy may be considered in cases of
                    emergency or unforeseen circumstances, subject to proof and
                    at the discretion of the studio management.
                  </ListItem>
                  <ListItem>
                    In the event of a dispute, MuseHive reserves the right to
                    make the final decision.
                  </ListItem>
                  <ListItem>
                    Refund will be processed within 5 to 10 business days.
                  </ListItem>
                </List>
              </Text>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CancellationPolicy;
