export const cancelBookingReasons = [
  { value: 'change_of_plans', label: 'Change of Plans' },
  { value: 'better_price_location', label: 'Found a Better Price/Location' },
  {
    value: 'unsatisfactory_experience',
    label: 'Unsatisfactory experience with host',
  },
  { value: 'booking_mistake', label: 'Booking Mistake' },
  { value: 'service_not_needed', label: 'Service No Longer Needed' },
  { value: 'technical_issues', label: 'Technical Issues' },
  { value: 'personal_reasons', label: 'Personal Reasons' },
];

export const cancelBookingWarningText = {
  boldText: 'This action cannot be undone',
  normalText:
    'and you may incur a penalty based on our cancellation policy. If eligible for a refund, it will be processed within 7-14 working days.',
};

export const cancelBookingConfirmationText =
  'Are you sure you want to cancel this booking?';
