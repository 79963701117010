import { useEffect, useState, RefObject } from 'react';

export const useTextOverflow = (ref: RefObject<HTMLElement>) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const checkOverflow = () => {
    if (ref.current) {
      const isOverflowing =
        ref.current.offsetWidth < ref.current.scrollWidth ||
        ref.current.offsetHeight < ref.current.scrollHeight;
      setShowTooltip(isOverflowing);
    }
  };

  useEffect(() => {
    checkOverflow();

    const handleResize = () => {
      checkOverflow();
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [ref]);

  return showTooltip;
};
