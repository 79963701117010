import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import React from 'react';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Terms and Conditions</ModalHeader>
      <ModalCloseButton />
      <ModalBody
        fontSize="sm"
        textAlign="justify"
        whiteSpace="pre-wrap"
        px={10}
      >
        {`MuseHive Pte Ltd Terms and Conditions
Last updated: 23 September 2024

Welcome to MuseHive, a platform that connects studio owners with users looking to book spaces by the hour. These Terms and Conditions (“T&Cs”) govern your access to and use of MuseHive’s services. By using the platform, you agree to these T&Cs.

1. Introduction
MuseHive Pte Ltd (referred to as "MuseHive", "we", "our", or "us") is an online platform that facilitates the connection between studio owners and users for hourly studio rentals. All transactions on the platform are made via Stripe payment, and MuseHive charges a commission for each successful transaction.

2. Eligibility
To create an account on MuseHive and list or book studios, you must meet the following eligibility criteria:
• You must be at least 18 years old, as users under this age may not have the legal capacity to enter into binding contracts. Users aged below 18 may only use the platform with the consent of a parent or legal guardian, and MuseHive reserves the right to request proof of consent.
• By creating an account, you confirm that all information you provide is accurate, truthful, and up-to-date. We reserve the right to terminate accounts that provide false or misleading information.
• Users must ensure that they comply with all applicable laws and regulations in Singapore or their local jurisdiction.

3. User Responsibilities
When you create an account or list a studio on MuseHive, you are responsible for ensuring the accuracy and completeness of the information provided. This applies to all users, including studio owners and individuals looking to book a studio.

Studio Owners:
• You are responsible for providing accurate and up-to-date information about your studio, including the location, availability, pricing, equipment, and any additional rules or policies.
• You must ensure that your studio complies with all applicable laws and regulations, including safety, building permits, and health regulations. You are also responsible for any tax obligations related to your earnings on MuseHive.
• Failure to provide accurate information or comply with laws may result in the suspension or removal of your listing and account.

Users:
• You must respect the studio space and follow any rules set by the studio owner during your booking. You are responsible for any damage caused to the studio or its equipment during your rental period.
• Users must not engage in any prohibited activities on the platform, including illegal activities, harassment, or attempts to circumvent MuseHive’s booking system or fees.

4. Prohibited Activities
To maintain the integrity of the MuseHive platform, the following activities are strictly prohibited:
• False or misleading advertising: Studio owners must provide truthful and accurate descriptions of their studio spaces. Any intentional misrepresentation, including false claims about amenities or availability, is prohibited.
• Copyright infringement: You must not upload or use any content (images, videos, etc.) on your listing or profile that infringes upon the intellectual property rights of others. This includes using images of studios or equipment without permission.
• Illegal activities: The platform must not be used for any illegal or unlawful activities, including but not limited to money laundering, fraud, or criminal activity.
• Circumventing platform fees: Users and studio owners are prohibited from conducting transactions outside of MuseHive to avoid paying platform fees or commissions.
• Misuse of user data: Users are prohibited from harvesting or misusing any personal information obtained from other users on the platform.

Engaging in any of these activities may result in the immediate termination of your account and legal action, depending on the severity of the violation.

5. Content Ownership and Use
Studio Owners:
When you upload content, such as studio descriptions, photos, or videos, you retain ownership of this content. However, by uploading it to MuseHive, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, display, modify, and promote this content in connection with the operation, marketing, and promotion of the platform.

You represent and warrant that:
• You own the content or have the necessary rights and permissions to upload it.
• Your content does not infringe the intellectual property rights, privacy, or publicity rights of any third party.

Platform Rights:
MuseHive may modify, crop, or edit content for the purpose of maintaining a consistent appearance on the platform or to comply with legal or safety standards. You grant MuseHive the right to use your content in promotional materials, advertisements, and social media campaigns to promote the platform or your studio.

MuseHive reserves the right to remove any content that violates these T&Cs, infringes on the rights of others, or is deemed inappropriate for the platform.

6. Payments and Fees
Studio Owners:
All payments made by users to book your studio are processed through Stripe, and you are required to connect your Stripe account to receive payments. Payments are subject to the following conditions:
• MuseHive will deduct a commission from each booking before disbursing the remaining payment to your Stripe account.

Users:
By booking a studio, you agree to pay the full booking amount at the time of booking. Payments will be processed securely through Stripe, and your payment information will not be stored by MuseHive.

MuseHive is not responsible for any tax obligations associated with the earnings of studio owners. Studio owners are solely responsible for reporting and paying any applicable taxes on their earnings.

7. Liability Disclaimer
MuseHive acts solely as an intermediary between studio owners and users. We do not own, operate, or manage any studios listed on the platform and are not responsible for:
• Disputes between users and studio owners regarding bookings, cancellations, or refunds.
• Any inaccuracies, omissions, or misrepresentations in studio listings.
• Any damages, injuries, or losses incurred during the use of studio spaces.
• Data breaches, loss of personal information, or other issues caused by third-party service providers (e.g., Stripe or cloud hosting).

MuseHive is not liable for any loss of revenue, profits, opportunities, or indirect damages resulting from:
• Platform outages or downtime.
• Technical issues or errors on the platform.
• Delayed or failed payments due to third-party service providers.

Studio owners and users acknowledge that MuseHive provides the platform "as is" and assumes no liability for the performance or availability of third-party services.

8. Indemnification
You agree to indemnify, defend, and hold MuseHive harmless from any claims, damages, liabilities, losses, or expenses (including legal fees) arising out of or related to:
• Your use of the platform.
• Your violation of these T&Cs.
• Any dispute between you and another user or studio owner.
• Any damage or injury caused to a studio or its equipment during your booking.

MuseHive reserves the right to assume the exclusive defense of any claims subject to indemnification by you, and you agree to cooperate fully with us in such defense.

9. Termination of Service
MuseHive reserves the right to suspend or terminate accounts and remove studio listings for any of the following reasons:
• Violation of these T&Cs or any applicable laws.
• Engaging in prohibited activities on the platform.
• Providing false or misleading information in studio listings or during registration.
• Consistently poor user reviews or complaints.

If your account is terminated, you will no longer have access to the platform, and any pending bookings will be canceled without refund. MuseHive may also take legal action for serious violations.

10. Cancellations and Refunds
Studio Owners:
All studios listed on MuseHive are required to follow a preset cancellation policy, which is managed through Stripe and applies to all bookings made via the platform. Studio owners do not have the option to set individual cancellation policies, and by listing your studio on MuseHive, you agree to adhere to the platform-wide policy.

Users:
By booking a studio through MuseHive, you agree to the following platform-wide cancellation policy:
• Cancellations made more than 48 hours before the scheduled booking: Full refund.
• Cancellations made less than 48 hours before the booking: 50% refund.
• Cancellations made less than 24 hours before the booking or no-shows: No refund.

Refunds will be processed automatically via Stripe within 5 to 10 business days after the cancellation. In the event of emergencies or unforeseen circumstances, MuseHive reserves the right to intervene and make the final decision regarding refunds.

11. Rate Parity
To maintain fairness and transparency, studio owners must adhere to the following rate parity policy:
11.1. Consistent Pricing
Studio owners agree to list their studios on MuseHive at rates that are equal to or lower than the rates offered on their own websites, social media, or other booking channels.
11.2. No Lower Rates on Direct Channels
Studio owners must not offer lower rates on direct booking channels (e.g., their own website, social media) than those listed on MuseHive.
11.3. Reporting Obligations
Studio owners must promptly inform MuseHive of any changes in pricing or discounts offered on other channels to ensure that the platform reflects accurate pricing.
11.4. Enforcement and Monitoring
MuseHive reserves the right to monitor the pricing of studio listings regularly. Any violations of this rate parity policy may result in:
• A warning or request to correct the price discrepancy.
• Temporary suspension of the studio listing until the issue is rectified.
• Permanent removal of the studio listing from the platform if rate parity violations persist.

12. Intellectual Property
12.1. MuseHive’s Intellectual Property
All content, features, and functionality available on the MuseHive platform, including but not limited to text, graphics, logos, images, videos, software, and data compilations, are the intellectual property of MuseHive Pte Ltd or its licensors and are protected by Singaporean and international copyright, trademark, patent, trade secret, and other intellectual property laws.

Studio owners and users may not:
• Copy, modify, distribute, or create derivative works of any part of the MuseHive platform or its content without prior written consent from MuseHive.
• Use MuseHive’s logos, trademarks, or other branding without express permission for promotional or commercial purposes.
• Attempt to reverse-engineer, decompile, or disassemble any software or services provided by MuseHive.

The use of MuseHive’s intellectual property is limited to lawful use of the platform as intended by these T&Cs.

12.2. User-Generated Content (Studio Listings)
By uploading content (including but not limited to images, videos, studio descriptions, and other materials) to the MuseHive platform, studio owners and users represent and warrant that:
• They hold all necessary rights, licenses, and permissions to upload, distribute, and use the content.
• The content does not violate any third-party intellectual property rights, privacy rights, or other legal rights.

When you upload content to MuseHive, you retain ownership of the intellectual property in your content. However, by submitting content to MuseHive, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, and sublicensable license to use, reproduce, distribute, modify, adapt, publicly display, and otherwise utilize your content in connection with the operation, promotion, and improvement of the platform.

MuseHive reserves the right to modify, crop, or edit user-generated content for consistency with the platform’s design and for compliance with legal and safety standards.

12.3. Restrictions on User-Generated Content
Studio owners and users are prohibited from uploading content that:
• Infringes on third-party intellectual property rights.
• Is offensive, defamatory, or otherwise harmful to individuals or groups.
• Violates any applicable laws, including copyright, trademark, or privacy laws.

MuseHive reserves the right to remove or modify any content that violates these T&Cs or is deemed inappropriate for the platform.

12.4. Reporting Intellectual Property Infringement
If you believe that any content on the MuseHive platform infringes your intellectual property rights, you may submit a notice of infringement by contacting us at [contact details]. We will promptly investigate and take appropriate action, including removing or disabling access to the infringing content if necessary.

13. Dispute Resolution
In the event of a dispute between a user and a studio owner, MuseHive encourages both parties to attempt to resolve the issue directly. If direct communication fails, MuseHive may assist in facilitating a resolution but is not obligated to mediate or arbitrate the dispute.

13.1. Mediation and Arbitration
If a dispute cannot be resolved through direct communication, both parties agree to first seek mediation in Singapore under mutually agreed terms. If mediation fails, disputes may be submitted to binding arbitration according to the rules of the Singapore International Arbitration Centre (SIAC).

13.2. Jurisdiction
Both parties agree to submit to the jurisdiction of Singaporean courts for any legal proceedings that arise from or relate to the use of the MuseHive platform.

14. Modifications to the Service
MuseHive reserves the right to modify or discontinue any services at any time. Users and studio owners will be notified of major changes, and continued use of the platform will constitute acceptance of the new terms.

15. Governing Law
These T&Cs and any disputes arising out of or related to them are governed by the laws of Singapore. Both users and studio owners agree that any legal matters or disputes will be resolved exclusively under Singaporean law, regardless of their location or jurisdiction.

MuseHive will comply with all applicable local laws and consumer protection regulations in Singapore and other relevant jurisdictions where the platform operates.

16. Platform Security
MuseHive takes the security of its platform and user data seriously. We employ industry-standard security measures, including encryption, secure server hosting, and firewalls, to safeguard the platform from unauthorized access and cyber threats. Our security protocols are regularly reviewed and updated to stay ahead of emerging risks.

However, while we implement these protective measures, no online platform can guarantee absolute immunity from all cyber threats. By using MuseHive, you acknowledge that we cannot be held liable for damages, data loss, or unauthorized access caused by third-party attacks, such as hacking, phishing, or malware, that are beyond our reasonable control.

We encourage all users to take appropriate steps to secure their own devices and account information to further protect their data.
`}
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="honey" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default TermsModal;
