import React from 'react';
import { GridItem, Heading, Text, Box } from '@chakra-ui/react';

interface FormHeaderProps {
  isFromEdit?: boolean;
}

const FormHeader: React.FC<FormHeaderProps> = ({ isFromEdit }) => (
  <GridItem
    colSpan={{
      md: 1,
    }}
  >
    <Box px={[4, 0]}>
      <Heading fontSize="xl" fontWeight="lg" lineHeight="8">
        {isFromEdit ? 'Edit' : 'Create'} your studio room
      </Heading>
      <Text
        mt={1}
        fontSize="sm"
        color="gray.600"
        _dark={{
          color: 'gray.400',
        }}
      >
        This information will be displayed publicly so be careful what you
        share.
      </Text>
    </Box>
  </GridItem>
);

export default FormHeader;
