import { useMutation } from '@tanstack/react-query';
import AuthAPIClient from '@/services/auth-api-client';

export interface Error {
  response: {
    data: {
      errors: string[];
    };
  };
}
const useSignIn = () => {
  const authApiClient = new AuthAPIClient('/v1/auth/sign_in');

  return useMutation({
    mutationKey: ['signin'],
    mutationFn: (credentials: any) => authApiClient.signIn(credentials),
  });
};
export default useSignIn;
