import axios, { AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { handleErrorResponse } from '@/components/Utilities/helper';

interface SignInRequest {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface SignInResponse {
  data: {
    id: number;
    email: string;
    admin: boolean;
    authenticationToken: string;
  };
}

interface ForgotPasswordRequest {
  email: string;
}

interface ResetPasswordRequest {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
  uid: string;
  client: string;
  accessToken: string;
}

export interface RegistrationResponse {
  email: string;
  password: string;
  passwordConfirmation: string;
  confirmSuccessUrl: string;
}

export interface ChangePassword {
  currentPassword: string;
  password: string;
  passwordConfirmation: string;
}

interface ChangePasswordResponse {
  success: boolean;
  message: string;
}

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/`,
  headers: {
    'Content-Type': 'application/json',
  },
});

class AuthAPIClient<T> {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;

    // Convert camelCase to snake_case for outgoing requests
    axiosInstance.interceptors.request.use((config) => {
      if (config.data) {
        config.data = decamelizeKeys(config.data);
      }
      return config;
    });

    // Convert snake_case to camelCase for incoming responses
    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.data) {
          response.data = camelizeKeys(response.data);
        }
        return response;
      },
      (error) => handleErrorResponse(error),
    );
  }
  // Authentication starts here

  signup = (body: T, config?: AxiosRequestConfig) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
    };
    return axiosInstance
      .post<T>(`${this.endpoint}`, body, {
        ...config,
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res.data);
  };

  signIn = (body: SignInRequest, config?: AxiosRequestConfig) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
    };
    return axiosInstance
      .post<SignInResponse>(this.endpoint, body, {
        ...config,
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res);
  };

  changePassword = (body: ChangePassword, config?: AxiosRequestConfig) =>
    axiosInstance
      .put<ChangePasswordResponse>(this.endpoint, body, {
        ...config,
      })
      .then((res) => res.data);

  forgotPassword = (
    body: ForgotPasswordRequest,
    config?: AxiosRequestConfig,
  ) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
    };
    return axiosInstance
      .post<T>(this.endpoint, body, {
        ...config,
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res.data);
  };

  resetPassword = (
    resetHeader: ResetPasswordRequest,
    config?: AxiosRequestConfig,
  ) => {
    const {
      uid,
      client,
      accessToken,
      password,
      passwordConfirmation,
      resetPasswordToken,
    } = resetHeader;
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
      uid,
      client,
      'access-token': accessToken,
      ...config?.headers,
    };
    const body = {
      password,
      passwordConfirmation,
      resetPasswordToken,
    };
    return axiosInstance
      .put<ResetPasswordRequest>(this.endpoint, body, {
        ...config,
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res.data);
  };

  signOut = (config?: AxiosRequestConfig) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
    };
    return axiosInstance
      .delete<T>(this.endpoint, {
        ...config,
        headers: { ...config?.headers, ...headers },
        withCredentials: true,
      })
      .then((res) => res.data);
  };

  authenticationStatus = (config?: AxiosRequestConfig) =>
    axiosInstance
      .get<T>(this.endpoint, {
        ...config,
        withCredentials: true,
      })
      .then((res) => res.data);

  // eslint-disable-next-line class-methods-use-this
  private getCSRFToken(): string | null {
    const metaElement = document.querySelector('meta[name="csrf-token"]');
    if (metaElement && metaElement instanceof HTMLMetaElement) {
      return metaElement.content;
    }
    return null;
  }
}

export default AuthAPIClient;
