export const htmlContent = `<style>
  [data-custom-class='body'],
  [data-custom-class='body'] * {
    background: transparent !important;
  }

  [data-custom-class='title'],
  [data-custom-class='title'] * {
    font-family: Arial !important;
    font-size: 26px !important;
    color: #000000 !important;
  }

  [data-custom-class='subtitle'],
  [data-custom-class='subtitle'] * {
    font-family: Arial !important;
    color: #595959 !important;
    font-size: 14px !important;
  }

  [data-custom-class='heading_1'],
  [data-custom-class='heading_1'] * {
    font-family: Arial !important;
    font-size: 19px !important;
    color: #000000 !important;
  }

  [data-custom-class='heading_2'],
  [data-custom-class='heading_2'] * {
    font-family: Arial !important;
    font-size: 17px !important;
    color: #000000 !important;
  }

  [data-custom-class='body_text'],
  [data-custom-class='body_text'] * {
    color: #595959 !important;
    font-size: 14px !important;
    font-family: Arial !important;
  }

  [data-custom-class='link'],
  [data-custom-class='link'] * {
    color: #3030F1 !important;
    font-size: 14px !important;
    font-family: Arial !important;
    word-break: break-word !important;
  }
</style>
<div data-custom-class="body">
  <div><strong><span style="font-size: 26px;"><span data-custom-class="title">
          <bdt class="block-component"></bdt>
          <bdt class="question">PRIVACY POLICY</bdt>
          <bdt class="statement-end-if-in-editor"></bdt>
        </span></span></strong></div>
  <div><br></div>
  <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
            data-custom-class="subtitle">Last updated <bdt class="question">November 13, 2024</bdt>
            </span></span></strong></span></div>
  <div><br></div>
  <div><br></div>
  <div><br></div>
  <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
        style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">This Privacy Notice for
          <bdt class="question">MuseHive Pte Ltd<bdt class="block-component"></bdt>
          </bdt> (<bdt class="block-component"></bdt>'<strong>we</strong>', '<strong>us</strong>', or
          '<strong>our</strong>'<bdt class="else-block"></bdt></span><span data-custom-class="body_text">), describes
          how and why we might access, collect, store, use, and/or share (<bdt class="block-component"></bdt>
          '<strong>process</strong>'<bdt class="else-block"></bdt>) your personal information when you use our services
          (<bdt class="block-component"></bdt>'<strong>Services</strong>'<bdt class="else-block"></bdt>), including when
          you:</span></span></span><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
          data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
            </span></span></span></span></span></div>
  <ul>
    <li data-custom-class="body_text" style="line-height: 1.5;"><span
        style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
            data-custom-class="body_text">Visit our website<bdt class="block-component"></bdt> at <span
              style="color: rgb(0, 58, 250);">
              <bdt class="question"><a href="https://musehivebooking.com/privacy-policy" target="_blank"
                  data-custom-class="link">https://musehivebooking.com/privacy-policy</a></bdt>
            </span><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                  data-custom-class="body_text"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                      <bdt class="statement-end-if-in-editor">, or any website of ours that links to this Privacy Notice
                      </bdt>
                    </span></span></span></span></span></span></span></span></li>
  </ul>
  <div>
    <bdt class="block-component"><span style="font-size: 15px;"><span style="font-size: 15px;"><span
            style="color: rgb(127, 127, 127);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                  <bdt class="block-component"></bdt>
    </bdt></span></span></span></span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;">
      <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
    </div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;">Use <bdt
            class="question">MuseHive</bdt>. <bdt class="question">MuseHive is an online platform that connects
            customers with creative space owners</bdt></span>
        <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"></span></bdt>
      </li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span></span></span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text">Engage with us in other related ways, including any sales, marketing, or
              events<span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span></span></span></span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
            data-custom-class="body_text"><strong>Questions or concerns? </strong>Reading this Privacy Notice will help
            you understand your privacy rights and choices. We are responsible for making decisions about how your
            personal information is processed. If you do not agree with our policies and practices, please do not use
            our Services.<bdt class="block-component"></bdt> If you still have any questions or concerns, please contact
            us at <bdt class="question">inquiry</bdt>.</span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><strong><span style="font-size: 15px;"><span data-custom-class="heading_1">SUMMARY OF
            KEY POINTS</span></span></strong></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong><em>This
              summary provides key points from our Privacy Notice, but you can find out more details about any of these
              topics by clicking the link following each key point or by using our </em></strong></span></span><a
        data-custom-class="link" href="#toc"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span
            data-custom-class="body_text"><strong><em>table of contents</em></strong></span></span></a><span
        style="font-size: 15px;"><span data-custom-class="body_text"><strong><em> below to find the section you are
              looking for.</em></strong></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What
            personal information do we process?</strong> When you visit, use, or navigate our Services, we may process
          personal information depending on how you interact with us and the Services, the choices you make, and the
          products and features you use. Learn more about </span></span><a data-custom-class="link"
        href="#personalinfo"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span
            data-custom-class="body_text">personal information you disclose to us</span></span></a><span
        data-custom-class="body_text">.</span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we
            process any sensitive personal information? </strong>Some of the information may be considered <bdt
            class="block-component"></bdt>'special' or 'sensitive'<bdt class="else-block"></bdt> in certain
          jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs. <bdt
            class="block-component"></bdt>We do not process sensitive personal information.<bdt class="else-block">
          </bdt></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>Do we
            collect any information from third parties?</strong>
          <bdt class="block-component"></bdt>We do not collect any information from third parties.<bdt
            class="else-block"></bdt>
        </span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do we
            process your information?</strong> We process your information to provide, improve, and administer our
          Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
          your information for other purposes with your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about </span></span><a data-custom-class="link" href="#infouse"><span
          style="color: rgb(0, 58, 250); font-size: 15px;"><span data-custom-class="body_text">how we process your
            information</span></span></a><span data-custom-class="body_text">.</span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>In what
            situations and with which <bdt class="block-component"></bdt>parties do we share personal
            information?</strong> We may share information in specific situations and with specific <bdt
            class="block-component"></bdt>third parties. Learn more about </span></span><a data-custom-class="link"
        href="#whoshare"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span data-custom-class="body_text">when
            and with whom we share your personal information</span></span></a><span style="font-size: 15px;"><span
          data-custom-class="body_text">.<bdt class="block-component"></bdt></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>What are
            your rights?</strong> Depending on where you are located geographically, the applicable privacy law may mean
          you have certain rights regarding your personal information. Learn more about </span></span><a
        data-custom-class="link" href="#privacyrights"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span
            data-custom-class="body_text">your privacy rights</span></span></a><span
        data-custom-class="body_text">.</span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text"><strong>How do you
            exercise your rights?</strong> The easiest way to exercise your rights is by <bdt class="block-component">
            submitting a </bdt></span></span><a data-custom-class="link"
        href="https://app.termly.io/notify/9ab8d52c-4339-4cb0-85b3-455dfc224520" rel="noopener noreferrer"
        target="_blank"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span data-custom-class="body_text">data
            subject access request</span></span></a><span style="font-size: 15px;"><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>, or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">Want to learn more
          about what we do with any information we collect? </span></span><a data-custom-class="link" href="#toc"><span
          style="color: rgb(0, 58, 250); font-size: 15px;"><span data-custom-class="body_text">Review the Privacy Notice
            in full</span></span></a><span style="font-size: 15px;"><span data-custom-class="body_text">.</span></span>
    </div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><br></div>
    <div id="toc" style="line-height: 1.5;"><span style="font-size: 15px;"><span
          style="color: rgb(127, 127, 127);"><span style="color: rgb(0, 0, 0);"><strong><span
                data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infocollect"><span
            style="color: rgb(0, 58, 250);">1. WHAT INFORMATION DO WE COLLECT?</span></a></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infouse"><span
            style="color: rgb(0, 58, 250);">2. HOW DO WE PROCESS YOUR INFORMATION?<bdt class="block-component"></bdt>
            </span></a></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(0, 58, 250);"><a
            data-custom-class="link" href="#whoshare">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
            INFORMATION?</a></span><span data-custom-class="body_text">
          <bdt class="block-component"></bdt></a><span style="color: rgb(127, 127, 127);"><span
              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                  <bdt class="block-component"></bdt>
                </span></span></span></span>
        </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#cookies"><span
            style="color: rgb(0, 58, 250);">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a><span
          style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span></span></span>
        <bdt class="block-component"></bdt>
      </span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                    style="color: rgb(89, 89, 89);">
                    <bdt class="block-component"></bdt>
                  </span></span>
                <bdt class="block-component"></bdt>
              </span></span></span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#inforetain"><span
            style="color: rgb(0, 58, 250);">5. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span
          style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);">
                  <bdt class="block-component"></bdt>
                  <bdt class="block-component"></bdt>
                </span></span></span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(0, 58, 250);"><a
            data-custom-class="link" href="#privacyrights">6. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#DNT"><span
            style="color: rgb(0, 58, 250);">7. CONTROLS FOR DO-NOT-TRACK FEATURES<bdt class="block-component"></span>
          <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text"></span></bdt>
          </span></div>
    <div style="line-height: 1.5;">
      <bdt class="block-component"><span style="font-size: 15px;"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></bdt>
      <bdt class="block-component"></span></bdt>
    </div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link"
          href="#policyupdates"><span style="color: rgb(0, 58, 250);">8. DO WE MAKE UPDATES TO THIS
            NOTICE?</span></a></span></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
          style="color: rgb(0, 58, 250); font-size: 15px;">9. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
    <div style="line-height: 1.5;"><a data-custom-class="link" href="#request"><span style="color: rgb(0, 58, 250);">10.
          HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><br></div>
    <div id="infocollect" style="line-height: 1.5;"><span style="color: rgb(0, 0, 0);"><span
          style="color: rgb(0, 0, 0); font-size: 15px;"><span style="font-size: 15px; color: rgb(0, 0, 0);"><span
              style="font-size: 15px; color: rgb(0, 0, 0);"><span id="control"
                style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">1. WHAT INFORMATION DO WE
                    COLLECT?</span></strong></span></span></span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div id="personalinfo" style="line-height: 1.5;"><span data-custom-class="heading_2"
        style="color: rgb(0, 0, 0);"><span style="font-size: 15px;"><strong>Personal information you disclose to
            us</strong></span></span></div>
    <div>
      <div><br></div>
      <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
                        Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text"><strong><em> </em></strong><em>We collect personal information that
                      you provide to us.</em></span></span></span></span></span></span></div>
    </div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We collect personal
            information that you voluntarily provide to us when you <span style="font-size: 15px;">
              <bdt class="block-component"></bdt>
            </span>register on the Services, </span><span style="font-size: 15px;"><span
              data-custom-class="body_text"><span style="font-size: 15px;">
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span><span data-custom-class="body_text">express an interest in obtaining information about us or
              our products and Services, when you participate in activities on the Services, or otherwise when you
              contact us.</span></span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="font-size: 15px;">
              <bdt class="block-component"></bdt>
            </span></span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Personal
              Information Provided by You.</strong> The personal information that we collect depends on the context of
            your interactions with us and the Services, the choices you make, and the products and features you use. The
            personal information we collect may include the following:<span style="font-size: 15px;"><span
                data-custom-class="body_text">
                <bdt class="forloop-component"></bdt>
              </span></span></span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><span style="font-size: 15px;"><span data-custom-class="body_text">
                  <bdt class="question">phone numbers</bdt>
                </span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="font-size: 15px;"><span data-custom-class="body_text">
                <bdt class="forloop-component"></bdt>
              </span></span></span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><span style="font-size: 15px;"><span data-custom-class="body_text">
                  <bdt class="question">names</bdt>
                </span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="font-size: 15px;"><span data-custom-class="body_text">
                <bdt class="forloop-component"></bdt>
              </span></span></span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><span style="font-size: 15px;"><span data-custom-class="body_text">
                  <bdt class="question">email addresses</bdt>
                </span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="font-size: 15px;"><span data-custom-class="body_text">
                <bdt class="forloop-component"></bdt>
              </span><span data-custom-class="body_text">
                <bdt class="statement-end-if-in-editor"></bdt>
              </span></span></span></span></span></div>
    <div id="sensitiveinfo" style="line-height: 1.5;"><span style="font-size: 15px;"><span
          data-custom-class="body_text"><strong>Sensitive Information.</strong>
          <bdt class="block-component"></bdt>We do not process sensitive information.
        </span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">
          <bdt class="else-block"></bdt>
        </span></span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="font-size: 15px;"><span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span></span></span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Payment
              Data.</strong> We may collect data necessary to process your payment if you choose to make purchases, such
            as your payment instrument number, and the security code associated with your payment instrument. All
            payment data is handled and stored by<bdt class="forloop-component"></bdt><span
              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px;"><span data-custom-class="body_text">
                      <bdt class="block-component"></bdt>
                    </span></span></span></span></span>
            <bdt class="question">Stripe</bdt><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                          style="font-size: 15px;"><span data-custom-class="body_text">
                            <bdt class="block-component"></bdt>
                          </span></span></span><span data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                    data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px;"><span data-custom-class="body_text">
                                          <bdt class="forloop-component"></bdt>
                                        </span></span></span></span></span></span></span></span></span></span></span></span>.
                  You may find their privacy notice link(s) here:<span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                        <bdt class="forloop-component"></bdt><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px;"><span data-custom-class="body_text">
                                  <bdt class="block-component"></bdt>
                                </span></span></span></span></span>
                      </span></span></span> <span style="color: rgb(0, 58, 250);">
                    <bdt class="question"><a href="https://stripe.com/en-sg/privacy" target="_blank"
                        data-custom-class="link">https://stripe.com/en-sg/privacy</a></bdt>
                  </span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    data-custom-class="body_text"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">
                                        <bdt class="block-component"></bdt>
                                      </span></span></span></span></span></span></span></span>
                        <bdt class="forloop-component"></bdt><span style="font-size: 15px;"><span
                            data-custom-class="body_text">.<bdt class="block-component"></bdt></span></span>
                      </span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
              style="font-size: 15px;"><span data-custom-class="body_text">
                <bdt class="statement-end-if-in-editor">
                  <bdt class="block-component"></bdt>
                </bdt>
              </span></span></span></span>
        <bdt class="block-component">
      </span></span></bdt>
    </div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">All personal information
            that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such
            personal information.</span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></div>
    <div style="line-height: 1.5;"><span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
          style="font-size: 15px;"><strong>Information automatically collected</strong></span></span></div>
    <div>
      <div><br></div>
      <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
                        Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text"><strong><em> </em></strong><em>Some information — such as your
                      Internet Protocol (IP) address and/or browser and device characteristics — is collected
                      automatically when you visit our Services.</em></span></span></span></span></span></span></div>
    </div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We automatically collect
            certain information when you visit, use, or navigate the Services. This information does not reveal your
            specific identity (like your name or contact information) but may include device and usage information, such
            as your IP address, browser and device characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when you use our Services, and other
            technical information. This information is primarily needed to maintain the security and operation of our
            Services, and for our internal analytics and reporting purposes.</span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Like many businesses, we
            also collect information through cookies and similar technologies. <bdt class="block-component"></bdt>
            <bdt class="block-component"></bdt>
          </span></span></span></div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt>
          </span><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">The information we
            collect includes:<bdt class="block-component"></bdt></span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related,
              diagnostic, usage, and performance information our servers automatically collect when you access or use
              our Services and which we record in log files. Depending on how you interact with us, this log data may
              include your IP address, device information, browser type, and settings and information about your
              activity in the Services<span style="font-size: 15px;"> </span>(such as the date/time stamps associated
              with your usage, pages and files viewed, searches, and other actions you take such as which features you
              use), device event information (such as system activity, error reports (sometimes called <bdt
                class="block-component"></bdt>'crash dumps'<bdt class="else-block"></bdt>), and hardware settings).<span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span></span></span></span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;">
      <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text"></span></span>
      </bdt>
    </div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><em>Device Data.</em> We collect device data such as information about your
              computer, phone, tablet, or other device you use to access the Services. Depending on the device used,
              this device data may include information such as your IP address (or proxy server), device and application
              identification numbers, location, browser type, hardware model, Internet service provider and/or mobile
              carrier, operating system, and system configuration information.<span style="font-size: 15px;"><span
                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span></span></span></span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;">
      <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text"></span></span>
      </bdt>
    </div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><em>Location Data.</em> We collect location data such as information about
              your device's location, which can be either precise or imprecise. How much information we collect depends
              on the type and settings of the device you use to access the Services. For example, we may use GPS and
              other technologies to collect geolocation data that tells us your current location (based on your IP
              address). You can opt out of allowing us to collect this information either by refusing access to the
              information or by disabling your Location setting on your device. However, if you choose to opt out, you
              may not be able to use certain aspects of the Services.<span style="font-size: 15px;"><span
                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span></span></span></span></span></span></span></span></li>
    </ul>
    <div>
      <bdt class="block-component"><span style="font-size: 15px;"></bdt>
      </bdt>
      <bdt class="statement-end-if-in-editor"></bdt>
      </bdt></span></span></span>
      <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
    </div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><strong><span data-custom-class="heading_2">Google
            API</span></strong></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px;"><span data-custom-class="body_text">Our use of
          information received from Google APIs will adhere to </span></span><a data-custom-class="link"
        href="https://developers.google.com/terms/api-services-user-data-policy" rel="noopener noreferrer"
        target="_blank"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span
            data-custom-class="body_text">Google API Services User Data Policy</span></span></a><span
        style="font-size: 15px;"><span data-custom-class="body_text">, including the </span></span><a
        data-custom-class="link" href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
        rel="noopener noreferrer" target="_blank"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span
            data-custom-class="body_text">Limited Use requirements</span></span></a><span style="font-size: 15px;"><span
          data-custom-class="body_text">.</span><br></span></div>
    <div><span style="font-size: 15px;"><br></span></div>
    <div style="line-height: 1.5;">
      <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"></span></bdt><span
        style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
            data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                    data-custom-class="body_text">
                    <bdt class="statement-end-if-in-editor">
                      <bdt class="block-component"></bdt>
                    </bdt>
                  </span></span></span></span></bdt></span></span></span></span></span></span></span></span><span
        style="font-size: 15px;"><span data-custom-class="body_text">
          <bdt class="block-component"></bdt>
        </span></span>
    </div>
    <div style="line-height: 1.5;"><br></div>
    <div id="infouse" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
          style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                style="color: rgb(0, 0, 0);"><strong><span data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR
                    INFORMATION?</span></strong></span></span></span></span></span></div>
    <div>
      <div style="line-height: 1.5;"><br></div>
      <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In
                        Short: </em></strong><em>We process your information to provide, improve, and administer our
                      Services, communicate with you, for security and fraud prevention, and to comply with law. We may
                      also process your information for other purposes with your
                      consent.</em></span></span></span></span></span></span></div>
    </div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>We process your
              personal information for a variety of reasons, depending on how you interact with our Services,
              including:</strong>
            <bdt class="block-component"></bdt>
          </span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><strong>To facilitate account creation and authentication and otherwise
                manage user accounts. </strong>We may process your information so you can create and log in to your
              account, as well as keep your account in working order.<span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                          data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                <bdt class="statement-end-if-in-editor"></bdt>
                              </span></span></span></span></span></span></span></span></span></span></span></span></li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></div>
    <ul>
      <li data-custom-class="body_text" style="line-height: 1.5;"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
              data-custom-class="body_text"><strong>To deliver and facilitate delivery of services to the
                user. </strong>We may process your information to provide you with the requested service.<span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><span style="font-size: 15px;"><span
                                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
      </li>
    </ul>
    <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
            <bdt class="block-component"></bdt>
          </span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>
      </li>
      </ul>
      <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
            </span></span></span></div>
      <ul>
        <li data-custom-class="body_text" style="line-height: 1.5;"><span
            style="font-size: 15px; color: rgb(89, 89, 89);"><span
              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To respond to
                  user inquiries/offer support to users. </strong>We may process your information to respond to your
                inquiries and solve any potential issues you might have with the requested service.<span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                        style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                  <bdt class="statement-end-if-in-editor"></bdt>
                                </span></span></span></span></span></span></span></span></span></span></span></span>
        </li>
      </ul>
      <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
              <bdt class="block-component"></bdt>
            </span></span></span></li>
        </ul>
        <div style="line-height: 1.5;">
          <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
        </div>
        <ul>
          <li data-custom-class="body_text" style="line-height: 1.5;"><span
              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To send
                    administrative information to you. </strong>We may process your information to send you details
                  about our products and services, changes to our terms and policies, and other similar
                  information.<span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                        <bdt class="statement-end-if-in-editor"></bdt>
                      </span></span></span></span></span></span></li>
        </ul>
        <div style="line-height: 1.5;">
          <bdt class="block-component"><span style="font-size: 15px;"></bdt>
          </span></span></span></span></span></span></span></span></span></li>
          </ul>
          <div style="line-height: 1.5;">
            <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
          </div>
          <ul>
            <li data-custom-class="body_text" style="line-height: 1.5;"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                  style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To <bdt
                        class="block-component"></bdt>fulfil<bdt class="else-block"></bdt> and manage your
                      orders. </strong>We may process your information to <bdt class="block-component"></bdt>fulfil<bdt
                      class="else-block"></bdt> and manage your orders, payments, returns, and exchanges made through
                    the Services.<span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                      data-custom-class="body_text">
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span></span></span></span></span></span></span></span></span></span></span></span>
            </li>
          </ul>
          <div style="line-height: 1.5;">
            <bdt class="block-component"><span style="font-size: 15px;"><span
                  data-custom-class="body_text"></span></span></bdt>
            </li>
            </ul>
            <p style="font-size: 15px; line-height: 1.5;">
              <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
            </p>
            <ul>
              <li data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text"><strong>To enable user-to-user communications. </strong>We may
                        process your information if you choose to use any of our offerings that allow for communication
                        with another user.<span style="color: rgb(89, 89, 89);"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span></span></span></span></span></span></span></span></span></span></span></li>
            </ul>
            <p style="font-size: 15px; line-height: 1.5;">
              <bdt class="block-component"><span style="font-size: 15px;"></bdt>
              </span></span></span></span></span></span></span></span></span></span></span></li>
              </ul>
            <p style="font-size: 15px; line-height: 1.5;">
              <bdt class="block-component"></bdt>
            </p>
            <ul>
              <li data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text"><strong>To request feedback. </strong>We may process your
                        information when necessary to request feedback and to contact you about your use of our
                        Services.<span style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                                      data-custom-class="body_text">
                                      <bdt class="statement-end-if-in-editor"></bdt>
                                    </span></span></span></span></span></span></span></span></span></span></span></li>
            </ul>
            <p style="font-size: 15px; line-height: 1.5;">
              <bdt class="block-component"></bdt>
              </span></span></span></span></span></span></span></span></span></span></span></li>
              </ul>
            <div style="line-height: 1.5;">
              <bdt class="block-component"><span style="font-size: 15px;"><span
                    data-custom-class="body_text"></span></span></bdt>
            </div>
            <ul>
              <li data-custom-class="body_text" style="line-height: 1.5;"><span style="font-size: 15px;"><span
                    data-custom-class="body_text"><strong>To send you marketing and promotional
                      communications. </strong>We may process the personal information you send to us for our marketing
                    purposes, if this is in accordance with your marketing preferences. You can opt out of our marketing
                    emails at any time. For more information, see <bdt class="block-component"></bdt>'<bdt
                      class="else-block"></bdt></span></span><a href="#privacyrights"><span
                    data-custom-class="link"><span style="color: rgb(0, 58, 250); font-size: 15px;"><span
                        data-custom-class="link">WHAT ARE YOUR PRIVACY RIGHTS?</span></span></span></a><span
                  style="font-size: 15px;"><span data-custom-class="body_text">
                    <bdt class="block-component"></bdt>'<bdt class="else-block"></bdt> below.
                  </span>
                  <bdt class="statement-end-if-in-editor"><span data-custom-class="body_text"></span></bdt>
                </span></li>
            </ul>
            <div style="line-height: 1.5;">
              <bdt class="block-component"><span style="font-size: 15px;"></bdt></span></span></span></li>
              </ul>
              <div style="line-height: 1.5;">
                <bdt class="block-component"><span style="font-size: 15px;"></bdt></span></span></span></li>
                </ul>
                <div style="line-height: 1.5;"><span style="font-size: 15px;">
                    <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                  </span></span></li>
                  </ul>
                  <div style="line-height: 1.5;">
                    <bdt class="block-component"><span style="font-size: 15px;"><span data-custom-class="body_text">
                    </bdt></span></span></li>
                    </ul>
                    <div style="line-height: 1.5;">
                      <bdt class="block-component"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"></span></span></bdt>
                      </li>
                      </ul>
                      <div style="line-height: 1.5;">
                        <bdt class="block-component"><span style="font-size: 15px;"><span
                              data-custom-class="body_text"></span></span></bdt>
                        </li>
                        </ul>
                        <div style="line-height: 1.5;">
                          <bdt class="block-component"><span style="font-size: 15px;"><span
                                data-custom-class="body_text"></span></span></bdt>
                          </li>
                          </ul>
                          <div style="line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                  data-custom-class="body_text"></span></span></bdt>
                          </div>
                          <ul>
                            <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                data-custom-class="body_text"><span style="font-size: 15px;"><strong>To evaluate and
                                    improve our Services, products, marketing, and your experience.</strong> We may
                                  process your information when we believe it is necessary to identify usage trends,
                                  determine the effectiveness of our promotional campaigns, and to evaluate and improve
                                  our Services, products, marketing, and your experience.</span></span>
                              <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                    data-custom-class="body_text"></span></span></bdt>
                            </li>
                          </ul>
                          <div style="line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                  data-custom-class="body_text"></span></span></bdt>
                          </div>
                          <ul>
                            <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                data-custom-class="body_text"><span style="font-size: 15px;"><strong>To identify usage
                                    trends.</strong> We may process information about how you use our Services to better
                                  understand how they are being used so we can improve them.</span></span>
                              <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                    data-custom-class="body_text"></span></span></bdt>
                            </li>
                          </ul>
                          <div style="line-height: 1.5;">
                            <bdt class="block-component"><span style="font-size: 15px;"><span
                                  data-custom-class="body_text"></span></span></bdt>
                            </li>
                            </ul>
                            <div style="line-height: 1.5;">
                              <bdt class="block-component"><span style="font-size: 15px;"><span
                                    data-custom-class="body_text"></span></span></bdt>
                            </div>
                            <ul>
                              <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                  style="font-size: 15px;"><span data-custom-class="body_text"><strong>To determine the
                                      effectiveness of our marketing and promotional campaigns.</strong> We may process
                                    your information to better understand how to provide marketing and promotional
                                    campaigns that are most relevant to you.<bdt class="statement-end-if-in-editor">
                                    </bdt></span></span></li>
                            </ul>
                            <div style="line-height: 1.5;">
                              <bdt class="block-component"><span style="font-size: 15px;"><span
                                    data-custom-class="body_text"></bdt></span></span></li>
                              </ul>
                              <div style="line-height: 1.5;">
                                <bdt class="block-component"><span style="font-size: 15px;"><span
                                      data-custom-class="body_text"></bdt></span></span></li>
                                </ul>
                                <div style="line-height: 1.5;">
                                  <bdt class="block-component"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text"></span></span></bdt>
                                  </li>
                                  </ul>
                                  <div style="line-height: 1.5;">
                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"></span></span></bdt>
                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"></bdt></span></span>
                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"></span></span></bdt>
                                    <bdt class="block-component"><span style="font-size: 15px;"><span
                                          data-custom-class="body_text"></span></span></bdt>
                                  </div>
                                  <div style="line-height: 1.5;"><br></div>
                                  <div id="whoshare" style="line-height: 1.5;"><span
                                      style="color: rgb(127, 127, 127);"><span
                                        style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                          style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                              style="color: rgb(0, 0, 0);"><strong><span
                                                  data-custom-class="heading_1">3. WHEN AND WITH WHOM DO WE SHARE YOUR
                                                  PERSONAL
                                                  INFORMATION?</span></strong></span></span></span></span></span></div>
                                  <div style="line-height: 1.5;"><br></div>
                                  <div style="line-height: 1.5;"><span
                                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We may
                                            share information in specific situations described in this section and/or
                                            with the following <bdt class="block-component"></bdt>third
                                            parties.</em></span></span></span></div>
                                  <div style="line-height: 1.5;"><span
                                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                        style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                          data-custom-class="body_text">
                                          <bdt class="block-component">
                                        </span></div>
                                  <div style="line-height: 1.5;"><br></div>
                                  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">We <bdt class="block-component"></bdt>may need to
                                        share your personal information in the following situations:</span></span></div>
                                  <ul>
                                    <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                        style="font-size: 15px;"><span data-custom-class="body_text"><strong>Business
                                            Transfers.</strong> We may share or transfer your information in connection
                                          with, or during negotiations of, any merger, sale of company assets,
                                          financing, or acquisition of all or a portion of our business to another
                                          company.</span></span></li>
                                  </ul>
                                  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                        data-custom-class="body_text">
                                        <bdt class="block-component"></bdt>
                                      </span></span></div>
                                  <ul>
                                    <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                        style="font-size: 15px;"><span data-custom-class="body_text"><strong>When we use
                                            Google Maps Platform APIs.</strong> We may share your information with
                                          certain Google Maps Platform APIs (e.g.<bdt class="block-component"></bdt>
                                          Google Maps API, Places API).</span><span data-custom-class="body_text">
                                          <bdt class="block-component"> Google Maps uses GPS, Wi-Fi, and cell towers to
                                            estimate your location. GPS is accurate to about 20 meters, while Wi-Fi and
                                            cell towers help improve accuracy when GPS signals are weak, like indoors.
                                            This data helps Google Maps provide directions, but it is not always
                                            perfectly precise.</bdt>
                                          <bdt class="block-component"></bdt> We obtain and store on your device (<bdt
                                            class="block-component"></bdt>'cache'<bdt class="else-block"></bdt>) your
                                          location<bdt class="block-component"></bdt>. You may revoke your consent
                                          anytime by contacting us at the contact details provided at the end of this
                                          document.<bdt class="statement-end-if-in-editor"></bdt>
                                          <bdt class="block-component"></bdt>
                                        </span></span></li>
                                  </ul>
                                  <div style="line-height: 1.5;"><span style="font-size: 15px;">
                                      <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                                    </span></li>
                                    </ul>
                                    <div style="line-height: 1.5;">
                                      <bdt class="block-component"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text"></span></span></bdt>
                                    </div>
                                    <ul>
                                      <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                          data-custom-class="body_text"><span style="font-size: 15px;"><strong>Business
                                              Partners.</strong> We may share your information with our business
                                            partners to offer you certain products, services, or
                                            promotions.</span></span>
                                        <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text"></span></span></bdt>
                                      </li>
                                    </ul>
                                    <div style="line-height: 1.5;">
                                      <bdt class="block-component"><span style="font-size: 15px;"><span
                                            data-custom-class="body_text"></bdt></span></span></li>
                                      </ul>
                                      <div style="line-height: 1.5;">
                                        <bdt class="block-component"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text"></span></span></bdt>
                                        </li>
                                        </ul>
                                        <div style="line-height: 1.5;">
                                          <bdt class="block-component"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"></span></span></bdt><span
                                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"><span data-custom-class="heading_1">
                                                        <bdt class="block-component"></bdt>
                                                      </span></bdt>
                                                  </span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="cookies" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">4. DO WE USE COOKIES AND OTHER
                                                        TRACKING
                                                        TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><strong><em>In Short:</em></strong><em> We
                                                  may use cookies and other tracking technologies to collect and store
                                                  your information.</em></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">We may use cookies and similar tracking
                                                technologies (like web beacons and pixels) to gather information when
                                                you interact with our Services. Some online tracking technologies help
                                                us maintain the security of our Services<bdt class="block-component">
                                                </bdt> and your account<bdt class="statement-end-if-in-editor"></bdt>,
                                                prevent crashes, fix bugs, save your preferences, and assist with basic
                                                site functions.</span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">We also permit third parties and service
                                                providers to use online tracking technologies on our Services for
                                                analytics and advertising, including to help manage and display
                                                advertisements, to tailor advertisements to your interests, or to send
                                                abandoned shopping cart reminders (depending on your communication
                                                preferences). The third parties and service providers use their
                                                technology to provide advertising about products and services tailored
                                                to your interests which may appear either on our Services or on other
                                                websites.</span></span></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"></bdt></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">Specific information about how we use such
                                                technologies and how you can refuse certain cookies is set out in our
                                                Cookie Notice<span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>.
                                                  </span></span></span></span></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"></span></bdt><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                      style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                          style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                              data-custom-class="body_text">
                                                              <bdt class="statement-end-if-in-editor"></bdt>
                                                            </span></span></span></span></span></span></span></span></span></span><span
                                            style="font-size: 15px;">
                                            <bdt class="block-component"></bdt>
                                          </span><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                      style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                        style="font-size: 15px;"><span
                                                          style="color: rgb(89, 89, 89);"><span
                                                            style="font-size: 15px;"><span
                                                              style="color: rgb(89, 89, 89);"><span
                                                                data-custom-class="body_text">
                                                                <bdt class="block-component"></bdt>
                                                              </span>
                                                              <bdt class="block-component"><span
                                                                  data-custom-class="body_text">
                                                                  <bdt class="block-component"></bdt>
                                                                </span>
                                                            </span></span></span></span></span></span></span></span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="inforetain" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">5. HOW LONG DO WE KEEP YOUR
                                                        INFORMATION?</span></strong></span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><strong><em>In Short: </em></strong><em>We
                                                  keep your information for as long as necessary to <bdt
                                                    class="block-component"></bdt>fulfil<bdt class="else-block"></bdt>
                                                  the purposes outlined in this Privacy Notice unless otherwise required
                                                  by law.</em></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">We will only keep your personal
                                                information for as long as it is necessary for the purposes set out in
                                                this Privacy Notice, unless a longer retention period is required or
                                                permitted by law (such as tax, accounting, or other legal requirements).
                                                <bdt class="block-component"></bdt> No purpose in this notice will
                                                require us keeping your personal information for longer than <span
                                                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                      data-custom-class="body_text">
                                                      <bdt class="block-component"></bdt>
                                                    </span></span></span>
                                                <bdt class="block-component"></bdt>the period of time in which users
                                                have an account with us<bdt class="block-component"></bdt><span
                                                  style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                      data-custom-class="body_text">
                                                      <bdt class="else-block"></bdt>
                                                    </span></span></span>.
                                              </span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">When we have no ongoing legitimate
                                                business need to process your personal information, we will either
                                                delete or <bdt class="block-component"></bdt>anonymise<bdt
                                                  class="else-block"></bdt> such information, or, if this is not
                                                possible (for example, because your personal information has been stored
                                                in backup archives), then we will securely store your personal
                                                information and isolate it from any further processing until deletion is
                                                possible.<span style="color: rgb(89, 89, 89);">
                                                  <bdt class="block-component"></bdt>
                                                </span><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                  </span></span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="privacyrights" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">6. WHAT ARE YOUR PRIVACY
                                                        RIGHTS?</span></strong></span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><strong><em>In
                                                    Short:</em></strong><em> <span style="color: rgb(89, 89, 89);"><span
                                                      style="font-size: 15px;"><span data-custom-class="body_text"><em>
                                                          <bdt class="block-component"></bdt>
                                                        </em></span></span> </span>You may review, change, or terminate
                                                  your account at any time, depending on your country, province, or
                                                  state of residence.</em><span style="color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px;">
                                                    <bdt class="block-component">
                                                      <bdt class="block-component">
                                                  </span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="withdrawconsent" style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><strong><u>Withdrawing your
                                                    consent:</u></strong> If we are relying on your consent to process
                                                your personal information,<bdt class="block-component"></bdt> which may
                                                be express and/or implied consent depending on the applicable law,<bdt
                                                  class="statement-end-if-in-editor"></bdt> you have the right to
                                                withdraw your consent at any time. You can withdraw your consent at any
                                                time by contacting us by using the contact details provided in the
                                                section <bdt class="block-component"></bdt>'<bdt class="else-block">
                                                </bdt></span></span></span><a data-custom-class="link"
                                            href="#contact"><span style="font-size: 15px; color: rgb(0, 58, 250);"><span
                                                style="font-size: 15px; color: rgb(0, 58, 250);"><span
                                                  data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS
                                                  NOTICE?</span></span></span></a><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>'<bdt class="else-block"></bdt> below
                                                <bdt class="block-component"></bdt>.
                                              </span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">However, please note that this will not
                                              affect the lawfulness of the processing before its withdrawal nor,<bdt
                                                class="block-component"></bdt> when applicable law allows,<bdt
                                                class="statement-end-if-in-editor"></bdt> will it affect the processing
                                              of your personal information conducted in reliance on lawful processing
                                              grounds other than consent.<bdt class="block-component"></bdt>
                                              </span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text"><strong><u>Opting out of marketing and
                                                  promotional communications:</u></strong><strong> </strong>You can
                                              unsubscribe from our marketing and promotional communications at any time
                                              by<bdt class="block-component"></bdt> clicking on the unsubscribe link in
                                              the emails that we send,<bdt class="statement-end-if-in-editor"></bdt>
                                              <bdt class="block-component"></bdt>
                                              <bdt class="block-component"></bdt> or by contacting us using the details
                                              provided in the section <bdt class="block-component"></bdt>'<bdt
                                                class="else-block"></bdt>
                                            </span></span><a data-custom-class="link" href="#contact"><span
                                              style="color: rgb(0, 58, 250); font-size: 15px;"><span
                                                data-custom-class="body_text">HOW CAN YOU CONTACT US ABOUT THIS
                                                NOTICE?</span></span></a><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">
                                              <bdt class="block-component"></bdt>'<bdt class="else-block"></bdt> below.
                                              You will then be removed from the marketing lists. However, we may still
                                              communicate with you — for example, to send you service-related messages
                                              that are necessary for the administration and use of your account, to
                                              respond to service requests, or for other non-marketing purposes.
                                            </span><span data-custom-class="body_text">
                                              <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"></span></span></bdt>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="heading_2"><strong>Account
                                                Information</strong></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                              style="font-size: 15px;">If you would at any time like to review or change
                                              the information in your account or terminate your account, you can:<bdt
                                                class="forloop-component"></bdt></span></span></div>
                                        <ul>
                                          <li data-custom-class="body_text" style="line-height: 1.5;"><span
                                              data-custom-class="body_text"><span style="font-size: 15px;">
                                                <bdt class="question">Contact us using the contact information provided.
                                                </bdt>
                                              </span></span></li>
                                        </ul>
                                        <div style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                              style="font-size: 15px;">
                                              <bdt class="forloop-component"></bdt>
                                            </span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">Upon your request to terminate your account,
                                              we will deactivate or delete your account and information from our active
                                              databases. However, we may retain some information in our files to prevent
                                              fraud, troubleshoot problems, assist with any investigations, enforce our
                                              legal terms and/or comply with applicable legal
                                              requirements.</span></span>
                                          <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"></span></span></bdt><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                                                        style="color: rgb(89, 89, 89);"><span
                                                          data-custom-class="body_text"><span
                                                            style="font-size: 15px;"><span
                                                              style="color: rgb(89, 89, 89);">
                                                              <bdt class="block-component"></bdt>
                                                            </span></span></span></span></span></span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><strong><u>Cookies and similar
                                                    technologies:</u></strong> Most Web browsers are set to accept
                                                cookies by default. If you prefer, you can usually choose to set your
                                                browser to remove cookies and to reject cookies. If you choose to remove
                                                cookies or reject cookies, this could affect certain features or
                                                services of our Services. <bdt class="block-component"><span
                                                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text">
                                                        <bdt class="block-component"></bdt><span
                                                          style="font-size: 15px;"><span
                                                            style="color: rgb(89, 89, 89);"><span
                                                              style="font-size: 15px;"><span
                                                                style="color: rgb(89, 89, 89);"><span
                                                                  style="font-size: 15px;"><span
                                                                    style="color: rgb(89, 89, 89);">
                                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                                  </span></span></span></span></span></span>
                                                      </span></span></span></span></span></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"></span></span></bdt>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span data-custom-class="body_text"><span
                                              style="font-size: 15px;">If you have questions or comments about your
                                              privacy rights, you may email us at <bdt class="question">inquiry</bdt>
                                              .</span></span>
                                          <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"></span></span></bdt>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="DNT" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">7. CONTROLS FOR DO-NOT-TRACK
                                                        FEATURES</span></strong></span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">Most web browsers and some mobile
                                                operating systems and mobile applications include a Do-Not-Track (<bdt
                                                  class="block-component"></bdt>'DNT'<bdt class="else-block"></bdt>)
                                                feature or setting you can activate to signal your privacy preference
                                                not to have data about your online browsing activities monitored and
                                                collected. At this stage, no uniform technology standard for <bdt
                                                  class="block-component"></bdt>recognising<bdt class="else-block">
                                                </bdt> and implementing DNT signals has been <bdt
                                                  class="block-component"></bdt>finalised<bdt class="else-block"></bdt>.
                                                As such, we do not currently respond to DNT browser signals or any other
                                                mechanism that automatically communicates your choice not to be tracked
                                                online. If a standard for online tracking is adopted that we must follow
                                                in the future, we will inform you about that practice in a revised
                                                version of this Privacy Notice.</span></span></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                        </div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>
                                                </bdt>
                                              </span></span></span></span></span></span></span></span></span></span></span>
                                          </bdt></span></span></span></span></span></span></span></span></span></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"></bdt></span>
                                          <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="policyupdates" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">8. DO WE MAKE UPDATES TO THIS
                                                        NOTICE?</span></strong></span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><em><strong>In Short: </strong>Yes, we
                                                  will update this notice as necessary to stay compliant with relevant
                                                  laws.</em></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">We may update this Privacy Notice from
                                                time to time. The updated version will be indicated by an updated <bdt
                                                  class="block-component"></bdt>'Revised'<bdt class="else-block"></bdt>
                                                date at the top of this Privacy Notice. If we make material changes to
                                                this Privacy Notice, we may notify you either by prominently posting a
                                                notice of such changes or by directly sending you a notification. We
                                                encourage you to review this Privacy Notice frequently to be informed of
                                                how we are protecting your information.</span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="contact" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">9. HOW CAN YOU CONTACT US ABOUT
                                                        THIS NOTICE?</span></strong></span></span></span></span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">If you have questions or comments about
                                                this notice, you may <span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                  </span></span>contact our Data Protection Officer (DPO)<span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                      data-custom-class="body_text">by email
                                                      at </span></span></span><span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="question">yap.tingwei@musehivebooking.com</bdt>
                                                  </span></span>,<bdt class="block-component"></bdt><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                      data-custom-class="body_text">by phone at <bdt class="question">
                                                        +65 96175588</bdt>,</span></span></span>
                                                <bdt class="statement-end-if-in-editor"><span
                                                    data-custom-class="body_text"></span></bdt><span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    data-custom-class="body_text">
                                                    <bdt class="else-block">
                                                      <bdt class="block-component"></bdt> or <bdt
                                                        class="statement-end-if-in-editor"></bdt>
                                                    </bdt>
                                                  </span></span><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                      data-custom-class="body_text">contact us by post
                                                      at:</span></span></span>
                                              </span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                    style="color: rgb(89, 89, 89);"><span
                                                      style="color: rgb(89, 89, 89);"><span
                                                        data-custom-class="body_text">
                                                        <bdt class="question">MuseHive Pte Ltd</bdt>
                                                      </span></span></span></span></span><span
                                                data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                    <bdt class="block-component"></bdt>
                                                  </span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">Data Protection Officer</span><span
                                              style="color: rgb(89, 89, 89);"><span
                                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                  <bdt class="question"><span data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89);">
                                                        <bdt class="block-component">
                                                      </span></bdt>
                                                </span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><span data-custom-class="body_text"
                                            style="font-size: 15px;">
                                            <bdt class="question">22 SIN MING LANE</bdt><span
                                              style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89);">
                                                  <bdt class="block-component"></bdt>
                                                </span></span></span>
                                          </span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">
                                              <bdt class="question">#06-76, MIDVIEW CITY</bdt><span
                                                style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                  </span></span></span>
                                            </span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              style="color: rgb(89, 89, 89);"><span
                                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                  <bdt class="question">Singapore</bdt><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89);">
                                                        <bdt class="block-component"></bdt>, <bdt class="question">
                                                          Singapore</bdt>
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                        <bdt class="block-component"></bdt>
                                                        <bdt class="question">573969</bdt>
                                                        <bdt class="statement-end-if-in-editor"></bdt>
                                                      </span></span><span data-custom-class="body_text"><span
                                                        style="color: rgb(89, 89, 89);">
                                                        <bdt class="block-component"></bdt>
                                                        <bdt class="block-component"></bdt>
                                                      </span></span></span>
                                                </span></span></span></div>
                                        <div style="line-height: 1.5;"><span data-custom-class="body_text"
                                            style="font-size: 15px;"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                  <bdt class="block-component"></bdt>
                                                </span></span></span><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">
                                                  <bdt class="question">Singapore<span style="font-size: 15px;"><span
                                                        data-custom-class="body_text"><span
                                                          style="color: rgb(89, 89, 89);">
                                                          <bdt class="statement-end-if-in-editor"></bdt>
                                                          <bdt class="statement-end-if-in-editor"></bdt>
                                                          <bdt class="statement-end-if-in-editor"><span
                                                              style="font-size: 15px;"><span
                                                                data-custom-class="body_text"><span
                                                                  style="color: rgb(89, 89, 89);">
                                                                  <bdt class="statement-end-if-in-editor"></bdt>
                                                                </span></span></span></bdt>
                                                        </span></span></span>
                                                    <bdt class="block-component"></bdt>
                                                </span></span></span></bdt></span></span></span></span><span
                                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                  <bdt class="statement-end-if-in-editor"><span
                                                      style="color: rgb(89, 89, 89);"><span
                                                        style="font-size: 15px;"><span data-custom-class="body_text">
                                                          <bdt class="block-component">
                                                            <bdt class="block-component"></bdt>
                                                        </span></span></span>
                                                </span></span></span>
                                            <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
                                            <span style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                  style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                    style="font-size: 15px;"><span data-custom-class="body_text">
                                                      <bdt class="statement-end-if-in-editor">
                                                        <bdt class="block-component"></bdt>
                                                      </bdt>
                                                    </span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">If you have any further questions or
                                              comments, you may also contact us by post at the following corporate
                                              address:</span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">
                                              <bdt class="question">MuseHive Pte Ltd</bdt>
                                            </span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">
                                              <bdt class="question">22 SIN MING LANE</bdt><span
                                                style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>
                                                  </span></span></span>
                                            </span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">
                                              <bdt class="question">#06-76, MIDVIEW CITY</bdt><span
                                                style="font-size: 15px;"><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                  </span></span></span>
                                            </span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text">
                                              <bdt class="question">Singapore</bdt><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>
                                                    <bdt class="block-component"></bdt>
                                                    <bdt class="question">573969</bdt>
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                  </span></span><span data-custom-class="body_text"><span
                                                    style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>
                                                    <bdt class="block-component"></bdt>
                                                  </span></span></span>
                                            </span></span></div>
                                        <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="block-component"></bdt>
                                                  </span></span></span>
                                              <bdt class="question">Singapore</bdt><span style="font-size: 15px;"><span
                                                  data-custom-class="body_text"><span style="color: rgb(89, 89, 89);">
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                    <bdt class="statement-end-if-in-editor"></bdt>
                                                  </span></span></span>
                                            </span></span>
                                          <bdt class="block-component"><span data-custom-class="body_text"></span></bdt>
                                        </div>
                                        <div style="line-height: 1.5;"><span data-custom-class="body_text">Phone: <bdt
                                              class="question">(+65)96175588</bdt></span>
                                          <bdt class="statement-end-if-in-editor"><span
                                              data-custom-class="body_text"></span></bdt><span
                                            style="font-size: 15px;"><span data-custom-class="body_text">
                                              <bdt class="statement-end-if-in-editor"></bdt>
                                            </span></span>
                                        </div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div id="request" style="line-height: 1.5;"><span
                                            style="color: rgb(127, 127, 127);"><span
                                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                  style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                    style="color: rgb(0, 0, 0);"><strong><span
                                                        data-custom-class="heading_1">10. HOW CAN YOU REVIEW, UPDATE, OR
                                                        DELETE THE DATA WE COLLECT FROM
                                                        YOU?</span></strong></span></span></span></span></span></div>
                                        <div style="line-height: 1.5;"><br></div>
                                        <div style="line-height: 1.5;"><span
                                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                                data-custom-class="body_text">
                                                <bdt class="block-component"></bdt>Based on the applicable laws of your
                                                country<bdt class="block-component"></bdt>, you may<bdt
                                                  class="else-block">
                                                  <bdt class="block-component"> have the right to request access to the
                                                    personal information we collect from you, details about how we have
                                                    processed it, correct inaccuracies, or delete your personal
                                                    information. You may also have the right to <bdt
                                                      class="block-component"></bdt>withdraw your consent to our
                                                    processing of your personal information. These rights may be limited
                                                    in some circumstances by applicable law. To request to review,
                                                    update, or delete your personal information, please <bdt
                                                      class="block-component"></bdt>fill out and submit a
                                              </span><span style="color: rgb(0, 58, 250);"><span
                                                  data-custom-class="body_text"><span
                                                    style="color: rgb(0, 58, 250); font-size: 15px;"><a
                                                      data-custom-class="link"
                                                      href="https://app.termly.io/notify/9ab8d52c-4339-4cb0-85b3-455dfc224520"
                                                      rel="noopener noreferrer" target="_blank">data subject access
                                                      request</a></span></span></span>
                                              <bdt class="block-component"><span data-custom-class="body_text"></bdt>
                                            </span></span><span data-custom-class="body_text">.</span></span></span>
                                        </div>
                                        <style>
                                          ul {
                                            list-style-type: square;
                                          }

                                          ul>li>ul {
                                            list-style-type: circle;
                                          }

                                          ul>li>ul>li>ul {
                                            list-style-type: square;
                                          }

                                          ol li {
                                            font-family: Arial;
                                          }
                                        </style>
                                      </div>
`;
