import {
  addMinutes,
  endOfMonth,
  endOfWeek,
  format,
  parse,
  parseISO,
  setHours,
  setMinutes,
  startOfMonth,
  startOfWeek,
} from 'date-fns';

type DateInput = string | Date;
type TimeString = string;

export const formatDate = (date: DateInput) =>
  format(new Date(date), 'EEE, dd/MM/yyyy');

export const formatTime = (date: DateInput) =>
  format(new Date(date), 'hh:mm a');

export const parseDateTime = (date: DateInput, time: TimeString) => {
  // Assuming 'date' is an ISO date string and 'time' is in 'HH:mm' format
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  return parse(time, 'HH:mm', parsedDate);
};

export const calculateEndDateTime = (
  startDateAndTime: Date,
  duration: number,
): Date => addMinutes(startDateAndTime, duration);

export const durationToString = (minutes: number) => {
  if (minutes === 0) return '0 hrs';
  const hours = Math.floor(minutes / 60);
  const minutesString = minutes % 60 > 0 ? ` ${minutes % 60} mins` : '';
  return `${
    hours > 0 ? `${hours} hr${hours > 1 ? 's' : ''} ` : ''
  }${minutesString}`;
};

export const getWeekStartAndEndDates = (
  date: Date,
): { start: Date; end: Date } => {
  const start = startOfWeek(date, { weekStartsOn: 0 }); // 0 for Sunday, 1 for Monday, etc.
  const end = endOfWeek(date, { weekStartsOn: 0 });

  return { start, end };
};

export const getMonthStartAndEndDates = (
  date: Date,
): { start: Date; end: Date } => {
  const start = startOfMonth(date);
  const end = endOfMonth(date);

  return { start, end };
};

export const parseLocalDateTimeToUTC = (date: string, time: string) => {
  const datetimeString = `${date} ${time}`;
  return parse(datetimeString, 'dd-MM-yyyy hh:mm a', new Date());
};

export const calculateStartDate = (date: Date | string, time: string): Date => {
  const parsedDate = typeof date === 'string' ? parseISO(date) : date;
  const [hours, minutes] = time.split(':').map(Number);
  return setMinutes(setHours(parsedDate, hours), minutes);
};

export const convertTo12HourFormat = (time: string): string => {
  const [hour, minute] = time.split(':').map(Number);
  const period = hour >= 12 ? 'pm' : 'am';
  const adjustedHour = hour % 12 || 12;
  return `${adjustedHour}:${minute.toString().padStart(2, '0')}${period}`;
};
