import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

const useRecaptcha = () => {
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isPendingCaptcha, setIsPendingCaptcha] = useState(false); // Add state for loading

  const verifyCaptcha = async () => {
    setIsPendingCaptcha(true);
    try {
      // Get the reCAPTCHA token
      const recaptchaValue = await recaptchaRef.current?.executeAsync();
      if (!recaptchaValue) {
        throw new Error('Failed to execute reCAPTCHA.');
      }

      // Send the token to the backend for verification
      const response = await fetch(
        `${import.meta.env.VITE_BASE_URL}/api/v1/verify_captcha`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ captcha_response: recaptchaValue }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to contact reCAPTCHA verification server.');
      }

      // Parse the response
      const data = await response.json();
      return data;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      return { success: false, error: 'Error verifying reCAPTCHA.' };
    } finally {
      setIsPendingCaptcha(false); // End loading
    }
  };
  const resetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  };

  return {
    recaptchaRef,
    verifyCaptcha,
    resetCaptcha,
    isPendingCaptcha,
  };
};

export default useRecaptcha;
