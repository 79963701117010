import { Tag } from '@chakra-ui/react';
import React from 'react';

interface StatusIndicatorProps {
  status: 'pending' | 'confirmed' | 'cancelled';
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  const getStatusColor = () => {
    switch (status) {
      case 'pending':
        return 'yellow';
      case 'cancelled':
        return 'red';
      case 'confirmed':
        return 'green';
      default:
        return 'gray';
    }
  };

  return (
    <Tag
      size="md"
      variant="solid"
      fontWeight="bold"
      colorScheme={getStatusColor()}
    >
      {status.charAt(0).toUpperCase() + status.slice(1)}
    </Tag>
  );
};

export default StatusIndicator;
