import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  HStack,
  useDisclosure,
  Link,
} from '@chakra-ui/react';
import { Control, Controller } from 'react-hook-form';
import TermsModal from '@/components/CreateStudio/TermsModal';

interface SubmitButtonProps {
  control: Control<any>;
  submitBtnText: string;
}

const SubmitButton: React.FC<SubmitButtonProps> = ({
  control,
  submitBtnText,
}) => {
  const {
    isOpen: isTcOpen,
    onOpen: onTcOpen,
    onClose: onTcClose,
  } = useDisclosure();

  return (
    <>
      <Box
        px={{
          base: 4,
          sm: 6,
        }}
        py={3}
        border="1px"
        borderColor="powder.600"
        bg="white"
        rounded={[null, 'md']}
        shadow="base"
        textAlign="right"
      >
        <HStack justifyContent="space-between">
          <Controller
            name="termsAgreed"
            control={control}
            render={({ field: { onChange, value, ref } }) => (
              <>
                <Checkbox
                  colorScheme="honey"
                  isChecked={value}
                  onChange={(e) => onChange(e.target.checked)}
                  ref={ref}
                >
                  I have read and accepted the{' '}
                  <Link color="blue.500" onClick={onTcOpen}>
                    Terms and Conditions
                  </Link>
                  .
                </Checkbox>
                <Button
                  type="submit"
                  colorScheme="honey"
                  _focus={{ shadow: '' }}
                  fontWeight="md"
                  isDisabled={!value}
                >
                  {submitBtnText}
                </Button>
              </>
            )}
          />
        </HStack>
      </Box>
      <TermsModal isOpen={isTcOpen} onClose={onTcClose} />
    </>
  );
};

export default SubmitButton;
