import React from 'react';
import { Heading, HStack, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import * as zod from 'zod';

interface AuthFormHeadingProps {
  title: string;
  color?: string;
}

export const AuthFormHeading: React.FC<AuthFormHeadingProps> = ({
  title,
  color = 'honey.500',
}) => (
  <HStack
    alignItems="center"
    fontSize={{ base: '3xl', md: '4xl' }}
    justifyContent="center"
  >
    <Heading>{title} </Heading>
    <Heading color={color}>MuseHive</Heading>
  </HStack>
);

interface AuthFormFooterProps {
  linkTo: string;
  linkText: string;
  footerText: string;
}

export const AuthFormFooter: React.FC<AuthFormFooterProps> = ({
  linkTo,
  linkText,
  footerText,
}) => (
  <>
    <Text>{footerText}</Text>
    <Link to={linkTo}>
      <Text
        color="blue.500"
        _hover={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
      >
        {linkText}
      </Text>
    </Link>
  </>
);

export const passwordSchema = zod
  .string()
  .min(8, { message: 'Password must be at least 8 characters long' })
  .regex(/(?=(.*[A-Z]){1,})/, {
    message: 'Password must contain at least one uppercase letter',
  })
  .regex(/(?=(.*[a-z]){3,})/, {
    message: 'Password must contain at least three lowercase letter',
  })
  .regex(/(?=(.*\d){1,})/, {
    message: 'Password must contain at least one number',
  })
  .regex(/(?=.*[!@#$%^&*])/, {
    message: 'Password must contain at least one special character',
  });
