import { FC } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { differenceInMinutes } from 'date-fns';
import CustomNumberInput from '@/components/Forms/CustomNumberInput';
import CustomLabel from '@/components/Forms/CustomLabel';
import Button from '@/components/Buttons';
import { countryCode } from '@/components/Utilities/constants';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import useSendOtp from '@/hooks/useSendOtp';

interface ContactNumberProps {
  control: any;
  errors: any;
  value?: string;
}

const ContactNumber: FC<ContactNumberProps> = ({ control, errors, value }) => {
  const { user } = useAuthStore();
  const {
    setContactModalVisibility,
    canEditContact,
    setOTPModalVisibility,
    isBtnLoading,
  } = useUserProfileStore();
  const sendOtp = useSendOtp();

  const handleSendOtp = () => {
    if (value) {
      if (
        !user?.otpSentAt ||
        differenceInMinutes(new Date(), user?.otpSentAt || '') > 1
      ) {
        sendOtp(value, countryCode, false);
      } else {
        setOTPModalVisibility(true);
      }
    }
  };

  const renderButton = (
    onClick: () => void,
    label: string,
    variant?: any,
    isDisabled = false,
  ) => (
    <Button
      bg={variant !== 'link' ? 'garageGrey.500' : ''}
      _hover={{ bg: variant !== 'link' ? 'garageGrey.500' : '' }}
      _active={{ bg: variant !== 'link' ? 'garageGrey.500' : '' }}
      color={variant === 'link' ? 'garageGrey.500' : 'white'}
      fontWeight="bold"
      fontSize="sm"
      onClick={onClick}
      isDisabled={isDisabled}
      isLoading={isBtnLoading}
      variant={variant}
    >
      {label}
    </Button>
  );

  const renderEditableContact = () => (
    <HStack align="start" spacing={4}>
      {' '}
      {/* Adjust spacing as needed */}
      <CustomNumberInput
        name="mobileNumber"
        inputText="Contact No."
        countryCode={countryCode}
        control={control}
        isRequired
        hideLabel
        mt={0}
      />
      {renderButton(
        handleSendOtp,
        'Verify OTP',
        !(value && !errors?.mobileNumber),
      )}
    </HStack>
  );

  const renderReadOnlyContact = () => (
    <HStack>
      <Text>
        {countryCode} {value}
      </Text>
      {renderButton(() => setContactModalVisibility(true), 'Change', 'link')}
      {user?.otpSentAt &&
        renderButton(
          handleSendOtp,
          'Verify OTP',
          !(value && !errors?.mobileNumber),
        )}
    </HStack>
  );

  return (
    <Box>
      <VStack align="start" gap={0}>
        <CustomLabel labelText="Contact No." isRequired />
        {canEditContact ? renderEditableContact() : renderReadOnlyContact()}
      </VStack>
    </Box>
  );
};

export default ContactNumber;
