// theme.js
import { extendTheme } from '@chakra-ui/react';
import { switchTheme } from '@/components/Forms/SwitchTheme';

const theme = extendTheme({
  colors: {
    powder: {
      900: '#DADAD4',
      800: '#DEDEDA',
      700: '#E2E2DF',
      600: '#EDEDE8',
      500: '#F3F3F0',
      300: '#F8F8F5',
      100: '#FDFDFC',
    },
    honey: {
      900: '#C87F1E',
      800: '#D7891F',
      700: '#E49320',
      600: '#F99E26',
      500: '#F4A935',
      400: '#F6BA5D',
      300: '#F8CB85',
      200: '#FBDDAE',
      100: '#FDEED6',
    },
    oak: {
      900: '#7E8170', // Darker than 800
      800: '#8F8979',
      700: '#A89F90',
      600: '#C1B5A8',
      500: '#CFC0AF',
      400: '#D8CDBF',
      300: '#E2D9CF',
      200: '#EBE6DF',
      100: '#F5F2EF',
    },
    garageGrey: {
      900: '#1A1A1A',
      800: '#1F1F1F',
      700: '#282828',
      600: '#232323',
      500: '#333232',
      400: '#5B5B5B',
      300: '#848484',
      200: '#ADADAD',
      100: '#D6D6D6',
      50: '#EDEDED',
    },
  },
  fonts: {
    heading: 'Nunito Sans, sans-serif',
    body: 'Nunito Sans, sans-serif',
  },
  styles: {
    global: {
      'html, body': {
        fontFamily: 'Nunito Sans, sans-serif',
        backgroundColor: 'white',
      },
      // You could also target specific elements, like so:
      '*, *::before, *::after': {
        fontFamily: 'Nunito Sans, sans-serif', // Apply the Prompt font to all elements
      },
    },
  },
  components: {
    Switch: switchTheme,
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: '#EDEDE8', // Default border color
          _hover: {
            borderColor: 'powder.900', // Hover color
          },
          _checked: {
            bg: 'honey.500', // Checked background color
            borderColor: 'honey.500', // Checked border color
          },
        },
        label: {
          color: 'garageGrey.800', // Default label color
        },
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: 'honey.500',
      },
      fontFamilty: 'Nunito Sans, sans-serif',
    },
    Input: {
      baseStyle: {
        field: {
          _placeholder: {
            color: 'powder.500', // Placeholder text color
          },
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#EDEDE8', // Default border color
            _hover: {
              borderColor: 'powder.900', // Hover to the darkest powder color
            },
            _focus: {
              borderColor: 'honey.500', // Focus to honey.500
              boxShadow: '0 0 0 1px #F4A935', // Optional: Add focus ring
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'honey.500',
      },
    },
    Select: {
      baseStyle: {
        field: {
          borderColor: '#EDEDE8 !important', // Match Input default border color
        },
      },
      variants: {
        outline: {
          field: {
            borderColor: '#EDEDE8 !important', // Match Input outline variant
            _hover: {
              borderColor: 'powder.900', // Same hover as Input
            },
            _focus: {
              borderColor: 'honey.500', // Same focus as Input
            },
          },
        },
      },
      defaultProps: {
        focusBorderColor: 'honey.500', // Same focusBorderColor as Input
        hoverBorderColor: 'powder.900', // Optional if defined globally
        variant: 'outline', // Default to outline variant
      },
    },
    Menu: {
      baseStyle: {
        list: {
          bg: 'powder.100', // Background color for the menu list
          border: '1px solid',
          borderColor: 'powder.500', // Border color for the menu
        },
        item: {
          bg: 'white',
          color: 'garageGrey.800',
          _hover: {
            bg: 'honey.100', // Hover background color for menu items
          },
          _focus: {
            bg: 'honey.200', // Focus background color
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          borderColor: '#EDEDE8', // Default border color
          _hover: {
            borderColor: 'powder.900', // Hover color
          },
          _checked: {
            bg: 'honey.500', // Checked background color
            borderColor: 'honey.500', // Checked border color
          },
        },
        label: {
          color: 'garageGrey.800', // Default label color
        },
      },
    },
  },
});

export default theme;
