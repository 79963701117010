import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  Input,
  InputProps,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CustomInputProps extends InputProps {
  inputText: string;
  colSpan?: number[];
  control: any;
  name: string;
  customLabelStyle?: Record<string, any>;
}

const CustomEmailInput = ({
  inputText,
  control,
  name,
  colSpan,
  customLabelStyle = {},
  ...props
}: CustomInputProps) => (
  <Controller
    name={name}
    control={control}
    rules={{
      pattern: {
        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: 'Invalid email address',
      },
    }}
    render={({ field, fieldState }) => (
      <FormControl
        as={GridItem}
        colSpan={colSpan}
        isInvalid={fieldState.isTouched && fieldState.invalid}
        {...props}
      >
        <CustomLabel labelText={inputText} {...customLabelStyle} />
        <Input
          {...field}
          type="email"
          placeholder="johnstudio@gmail.com"
          rounded="md"
          height={10}
          p={2}
          pl={4}
          pr={4}
          _placeholder={{
            fontSize: 'sm',
            color: 'gray.400',
          }}
          onChange={field?.onChange}
          {...props}
        />
        <FormErrorMessage>
          {fieldState.isTouched && fieldState.error?.message}
        </FormErrorMessage>
      </FormControl>
    )}
  />
);

export default CustomEmailInput;
