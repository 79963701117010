import { Heading, Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CustomSubmitButton from '@/components/Forms/UsersField/CustomSubmitButton';

interface ErrorFallBackProps {
  resetErrorBoundary: any;
}

const ErrorFallback: React.FC<ErrorFallBackProps> = ({
  resetErrorBoundary,
}) => {
  const navigate = useNavigate();

  const handleReset = () => {
    resetErrorBoundary();
    navigate('/');
  };

  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Something went wrong.
      </Heading>
      <Text size="xl"> Please try again later</Text>
      <CustomSubmitButton mt={4} onClick={handleReset}>
        Return to home page
      </CustomSubmitButton>
    </Box>
  );
};

export default ErrorFallback;
