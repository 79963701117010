import { FC, memo } from 'react';
import { Card } from '@chakra-ui/react';
import { differenceInDays } from 'date-fns';
import ReactBigCalendar from '@/components/ReactBigCalendar';
import {
  buildEventForStudioDetails,
  DateRangeProps,
} from '@/components/ReactBigCalendar/helper';
import { FlattenedBooking } from '@/components/DashBoard/ManageReservations/helper';

interface CalendarViewProps {
  studioAvailability: any;
  setDateRange: (range: DateRangeProps) => void;
  dateRange: DateRangeProps;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  setSelectedReservation: (reservation: FlattenedBooking | null) => void;
  isBaseBreakpoint?: boolean;
}

const ReservationCalendarView: FC<CalendarViewProps> = ({
  studioAvailability,
  setDateRange,
  dateRange,
  isOpen,
  onClose,
  onOpen,
  setSelectedReservation,
  isBaseBreakpoint,
}) => {
  const handleInitialValue = () => {
    const dayDifference = differenceInDays(dateRange.end, dateRange.start);
    if (dayDifference === 0) {
      return 'day';
    }
    if (dayDifference >= 1 && dayDifference <= 7) {
      return 'week';
    }
    return 'month';
  };

  const handleSelectEvent = (event: any) => {
    const selectedBooking = studioAvailability.find(
      (item: FlattenedBooking) => String(item.id) === String(event.id),
    )?.attributes;

    if (isOpen) {
      onClose();
      setSelectedReservation(null);
    } else {
      onOpen();
      setSelectedReservation(selectedBooking || null);
    }
  };

  return (
    <Card h="100vh" width="100%">
      <ReactBigCalendar
        views={isBaseBreakpoint ? ['day'] : ['week', 'month', 'day']}
        events={buildEventForStudioDetails(studioAvailability, true)}
        onRangeChange={setDateRange}
        initialViews={isBaseBreakpoint ? 'day' : handleInitialValue()}
        onSelectEvent={handleSelectEvent}
        isBaseBreakpoint={isBaseBreakpoint}
        defaultBreakpointView={isBaseBreakpoint ? 'day' : undefined}
      />
    </Card>
  );
};

export default memo(ReservationCalendarView);
