import React from 'react';

export const useTimeOptions = (startTime?: string) => {
  const generateTimeOptions = (
    isStartTime: boolean,
    selectedTime?: string,
  ): JSX.Element[] => {
    const options: JSX.Element[] = [];
    let currentHour = isStartTime
      ? 0
      : parseInt(selectedTime?.split(':')[0] || '0');
    let currentMinute = isStartTime
      ? 0
      : parseInt(selectedTime?.split(':')[1] || '0');

    if (!isStartTime) {
      currentMinute += 15;
      if (currentMinute === 60) {
        currentMinute = 0;
        currentHour += 1;
      }
    }

    while (currentHour < 24) {
      const hour12 = currentHour % 12 || 12;
      const period = currentHour < 12 ? 'AM' : 'PM';
      const timeString = `${hour12.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')} ${period}`;

      options.push(
        <option
          key={`${isStartTime ? 'start' : 'close'}-${timeString}`}
          value={`${currentHour.toString().padStart(2, '0')}:${currentMinute.toString().padStart(2, '0')}`}
        >
          {timeString}
        </option>,
      );

      currentMinute += 15;
      if (currentMinute === 60) {
        currentMinute = 0;
        currentHour += 1;
      }
    }

    if (!isStartTime) {
      options.push(
        <option key="nextDay-12:00 AM" value="00:00">
          12:00 AM (+1)
        </option>,
      );
    }

    return options;
  };

  const startTimeOptions = generateTimeOptions(true);

  // Filter closeTimeOptions based on startTime
  const closeTimeOptions = generateTimeOptions(false).filter((option) => {
    if (!startTime) return true;
    const [optionHour, optionMinute] = option.props.value
      .split(':')
      .map(Number);
    const [startHour, startMinute] = startTime.split(':').map(Number);

    // Check if the option is "12:00 AM" (next day), which is represented as "00:00"
    if (option.props.value === '00:00') {
      return true;
    }

    return (
      optionHour > startHour ||
      (optionHour === startHour && optionMinute > startMinute)
    );
  });

  return { startTimeOptions, closeTimeOptions };
};
