import React, { useCallback } from 'react';
import * as z from 'zod';
import { Box, Link, Tag } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { format } from 'date-fns';
import { DownloadIcon } from '@chakra-ui/icons';
import APIClient from '@/services/api-client';
import { commonErrorMessage } from '@/components/Utilities/constants';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import {
  initialValueForReservationList,
  useBookingImportStore,
} from '@/services/store';
import { UserStudios } from '@/hooks/useOwnedStudios';
import { handleFileDownload } from '@/components/Utilities/FileDownload';
import Button from '@/components/Buttons';

export const bookingImportFormSchema = z.object({
  studioId: z.string(),
  files: z.array(z.instanceof(File)).nonempty('At least one file is required'),
});

export const bookingImportFormData = (data: any, images: File) => {
  const formDataToSend = new FormData();
  formDataToSend.append('file', images);
  formDataToSend.append('studio_id', String(data?.studioId));
  formDataToSend.append('time_zone', String(data?.timeZone));
  return formDataToSend;
};

export const getReservationHistory = async (pageNo: number | string) => {
  const apiClient = new APIClient<any>(
    `/v1/imports/reservation_history?page=${pageNo}`,
  );
  // eslint-disable-next-line no-useless-catch
  try {
    return await apiClient.get();
  } catch (error) {
    throw error;
  }
};

export const fetchReservationHistory = async (
  page: number,
  updateTableLoading: () => void,
  updateReservationList: (details: any) => void,
) => {
  updateTableLoading();
  try {
    const response = await getReservationHistory(page);
    updateReservationList(response);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error: any) {
    updateReservationList(initialValueForReservationList);
  } finally {
    updateTableLoading();
  }
};

export const useBookingImportCSV = (userId?: number) => {
  const apiClient = new APIClient<any>('/v1/imports/import_reservations');
  const showToast = useShowToastTop();
  const {
    reservationsList,
    updateTableLoading,
    updateReservationList,
    setSubmitBtnLoading,
    updateAddModalVisibility,
  } = useBookingImportStore();
  return useCallback(
    async (formData: FormData) => {
      try {
        await apiClient.post(formData);
        fetchReservationHistory(
          reservationsList?.meta?.currentPage || 1,
          updateTableLoading,
          updateReservationList,
        );
        updateAddModalVisibility();
      } catch (error: any) {
        const errorMessage =
          error?.response?.status === 400
            ? error?.response?.data?.error
            : commonErrorMessage;
        showToast({
          message: errorMessage,
          status: 'error',
        });
      } finally {
        setSubmitBtnLoading();
      }
    },
    [userId, reservationsList],
  );
};

const statusColors: { [key: string]: string } = {
  pending: 'yellow',
  completed: 'green',
  processing: 'orange',
  failed: 'red',
};

export const reservationListTableColumns = () => [
  {
    title: 'S/N',
    key: 'serialNumber',
    dataIndex: 'serialNumber',
    render: (_text: any, _record: any, index: number) => `${index + 1}.`,
  },
  {
    title: 'Date & Time',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render: (dateStr: string) =>
      `${format(new Date(dateStr), 'dd MMM yy')}, ${format(new Date(dateStr), 'hh:mm a')}`,
  },
  {
    title: 'File Name',
    key: 'fileName',
    dataIndex: 'fileName',
    render: (value: string, record: any) => (
      <Link
        href={record.fileUrl}
        color="honey.500"
        _hover={{ color: 'honey.500', textDecoration: 'underline' }}
      >
        {value}
      </Link>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (status: string) =>
      status ? (
        <Tag variant="solid" colorScheme={statusColors[status]}>
          {capitalize(status)}
        </Tag>
      ) : (
        '-'
      ),
  },
  {
    title: 'Studio Name',
    key: 'studioName',
    dataIndex: 'studio',
    render: (details: any) => details?.studioName || '-',
  },
  {
    title: <Box textAlign="center">Import Status</Box>,
    key: 'totalRecords',
    dataIndex: 'totalRecords',
    render: (value: string, record: any) => (
      <Box textAlign="center">
        {record.savedRecords} / {value}
      </Box>
    ),
  },
  {
    title: <Box textAlign="center">Error Details</Box>,
    key: 'errorFileUrl',
    dataIndex: 'errorFileUrl',
    render: (value: string) => (
      <Box textAlign="center">
        {value ? (
          <Button
            variant="outline"
            onClick={() => handleFileDownload(value)}
            size={{ base: 'xs', md: 'sm' }}
          >
            <DownloadIcon />
          </Button>
        ) : (
          '-'
        )}
      </Box>
    ),
  },
];

export const transformToStudioOptions = (studio?: UserStudios[]) =>
  (studio || []).map((studioDetails) => ({
    value: studioDetails.id?.toString() || '',
    label: `${studioDetails.studioName} - ${studioDetails?.roomName}` || '',
  }));
