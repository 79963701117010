import React from 'react';
import * as zod from 'zod';
import { useNavigate } from 'react-router-dom';
import { Link, Text, useDisclosure } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import ReCAPTCHA from 'react-google-recaptcha';
import useAuth from '@/hooks/useAuth';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import AuthForm from '@/components/Authentication/AuthForm';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import {
  AuthFormFooter,
  AuthFormHeading,
  passwordSchema,
} from '@/components/Authentication/helper';
import useRecaptcha from '@/hooks/useRecaptcha';
import SignUpTermsModal from './SignUpTermsModal';

const signUpSchema = zod
  .object({
    email: zod.string().email(),
    password: passwordSchema,
    passwordConfirmation: passwordSchema,
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    // eslint-disable-next-line
    message: 'Passwords don\'t match',
    path: ['passwordConfirmation'],
  });

const defaultValues = { email: '', password: '', passwordConfirmation: '' };

const SignUpForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate, isPending } = useAuth();
  // const recaptchaRef = useRef<ReCAPTCHA>(null);
  const { recaptchaRef, verifyCaptcha, resetCaptcha, isPendingCaptcha } =
    useRecaptcha(); // Get resetCaptcha from hook

  const showToastTop = useShowToastTop();

  const navigate = useNavigate();
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(signUpSchema),
  });

  const isPendingOverall = isPending || isPendingCaptcha;

  const onSubmit = async (data: any) => {
    const captchaResult = await verifyCaptcha(); // Use the hook to verify captcha
    if (captchaResult.success) {
      mutate(data, {
        onSuccess: () => {
          resetCaptcha();
          navigate('/auth/sign_in');
          showToastTop({
            message: 'Please check your email for the confirmation link',
            status: 'success',
          });
        },
        onError: (error: any) => {
          showToastTop({
            message:
              error?.response?.data?.errors?.full_messages[0] ||
              error?.response?.data?.error ||
              'An error occurred',
            status: 'error',
          });
        },
      });
    } else {
      showToastTop({
        message:
          `Captcha verification failed: ${captchaResult.error}` ||
          'Unknown error',
        status: 'error',
      });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  };

  return (
    <>
      <AuthForm
        methods={methods}
        heading={<AuthFormHeading title="Sign up to" />}
        onSubmit={onSubmit}
        submitButtonLabel="Agree and sign up"
        isPending={isPendingOverall}
        footerComponent={
          <AuthFormFooter
            linkTo="/auth/sign_in"
            linkText="Login"
            footerText="Already a user?"
          />
        }
      >
        <CustomInputField
          name="email"
          label="Email Address"
          type="email"
          isRequired
          control={methods.control}
        />
        <CustomInputField
          name="password"
          label="Password"
          type="password"
          isRequired
          control={methods.control}
        />
        <CustomInputField
          name="passwordConfirmation"
          label="Confirm Password"
          type="password"
          isRequired
          control={methods.control}
        />
        <Text fontSize="xs">
          By selecting <b>Agree and Sign up</b>, I agree to MuseHive’s{' '}
          <Link
            color="blue.500"
            onClick={onOpen}
            size="xs"
            textDecoration="underline"
          >
            Terms and Conditions
          </Link>{' '}
          and acknowledge the Privacy Policy.
        </Text>
        <ReCAPTCHA
          sitekey={import.meta.env.VITE_CAPTCHA_SITEKEY}
          ref={recaptchaRef}
          size="invisible"
        />
      </AuthForm>
      <SignUpTermsModal isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default React.memo(SignUpForm);
