import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';
import { DaysOfWeekProps } from '@/components/Utilities/StudiosHandler';

interface EquipmentCategory {
  equipmentCategory: string[];
}

interface StudioType {
  label: string;
  value: string;
  platformCommissionRate: number;
}

export interface StudioConstants {
  studioType: StudioType[];
  equipmentCategory: EquipmentCategory;
  daysOfWeek: DaysOfWeekProps;
}

// Fetches all studio constants
export const useStudioConstants = (id?: string) => {
  const apiClient = new APIClient<{ data: StudioConstants }>(
    `/v1/studios/constants_hash${id ? `?studio_id=${id}` : ''}`,
  );
  const { data, ...rest } = useQuery({
    queryKey: ['constants_hash', id],
    queryFn: () => apiClient.get(),
  });
  return { data: data?.data, ...rest };
};
