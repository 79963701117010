import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import ApiClient from '@/services/api-client';
import { flattenAttributes } from '@/components/Utilities/helper';
import { StudioSearchQuery } from '@/services/store';

const apiClient = new ApiClient<any>('v1/studios/top_searched');

const useTopSearchStudios = (query?: StudioSearchQuery) => {
  const [searchParams, setSearchParams] = useState<StudioSearchQuery | null>(
    null,
  );

  // Clear sessionStorage and set searchParams to null on page load (refresh)
  useEffect(() => {
    // Clear sessionStorage and reset searchParams on page refresh
    sessionStorage.removeItem('studioSearchQuery');
    setSearchParams(null); // Reset searchParams to empty values (null)

    // Optionally, you can set the searchParams based on `query` prop passed from parent component
    if (query) {
      setSearchParams(query);
    }
  }, [query]); // Effect runs when `query` prop changes, i.e., on page load

  const { data, ...rest } = useQuery({
    queryKey: ['topSearchStudios', searchParams],
    queryFn: () =>
      apiClient.getAll({
        params: {
          studio_name: searchParams?.studioName || '',
          room_name: searchParams?.roomName || '',
          address: searchParams?.address || '',
        },
      }),
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 0, // Data is always considered stale
    refetchOnMount: true, // Force refetch when the component mounts
  });

  return { data: flattenAttributes(data?.data || []), ...rest };
};

export default useTopSearchStudios;
