import React, { useEffect, useState, useCallback } from 'react';
import { Table } from 'antd';
import { Flex, Heading } from '@chakra-ui/react';
import { FiRefreshCw } from 'react-icons/fi';
import { FaFileImport } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Button from '@/components/Buttons';
import { useAuthStore, useBookingImportStore } from '@/services/store';
import {
  fetchReservationHistory,
  reservationListTableColumns,
} from '@/components/Utilities/BookingImport';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import { handleFileDownload } from '@/components/Utilities/FileDownload';
import ImportFormModal from '@/components/DashBoard/BookingImport/ImportFormModal';

const BookingImport = () => {
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const showToast = useShowToastTop();

  const {
    tableLoading,
    reservationsList,
    updateAddModalVisibility,
    updateTableLoading,
    updateReservationList,
  } = useBookingImportStore();

  const [pageNo, setPageNo] = useState(1);

  const handleReservationList = useCallback(() => {
    fetchReservationHistory(pageNo, updateTableLoading, updateReservationList);
  }, [pageNo]);

  const handleDownload = () => {
    handleFileDownload(
      `${import.meta.env.VITE_BASE_URL}/reservation_imports_csv_template.csv`,
    );
  };

  useEffect(() => {
    handleReservationList();
  }, [handleReservationList]);

  useEffect(() => {
    if (user && !user?.studioOwner) {
      navigate('/dashboard/bookings');
      showToast({
        message: 'User Not found',
        status: 'error',
      });
    }
  }, [user?.studioOwner]);

  return (
    <Flex flexDirection="column" gap={4}>
      <Flex
        flexDirection={{ base: 'column', lg: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        gap={5}
      >
        <Heading fontSize={{ base: 'xl', md: '2xl' }}>Booking Import</Heading>
        <Flex align="center" gap={3} flexWrap="wrap" justify="center">
          <Button onClick={handleDownload} variant="link" size="sm">
            Download Template
          </Button>
          <Button
            leftIcon={<FiRefreshCw />}
            onClick={handleReservationList}
            isLoading={tableLoading}
            variant="outline"
            size="sm"
          >
            Refresh
          </Button>
          <Button
            leftIcon={<FaFileImport />}
            onClick={updateAddModalVisibility}
            size="sm"
          >
            Import Excel/CSV
          </Button>
        </Flex>
      </Flex>
      <Table
        loading={tableLoading}
        columns={reservationListTableColumns()}
        dataSource={reservationsList?.data || []}
        pagination={{
          current: reservationsList?.meta?.currentPage,
          total: reservationsList?.meta?.totalEntries,
          pageSize: 10,
          onChange: setPageNo,
        }}
        rowKey="id"
        scroll={{ x: 'max-content' }}
        bordered
      />
      <ImportFormModal user={user} />
    </Flex>
  );
};

export default React.memo(BookingImport);
