import React, { useMemo } from 'react';
import { Editable, withReact, Slate } from 'slate-react';
import { createEditor } from 'slate';
import { withHistory } from 'slate-history';
import { Leaf, Element } from '@/components/Forms/SlateComponent';
import { SlateNode } from '@/hooks/useStudios';

interface SlateRendererProps {
  content: SlateNode[];
}

const SlateRenderer: React.FC<SlateRendererProps> = ({ content }) => {
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Slate editor={editor} initialValue={content} onChange={() => {}}>
      <Editable
        renderElement={(props) => <Element {...props} />}
        renderLeaf={(props) => <Leaf {...props} />}
        readOnly
      />
    </Slate>
  );
};

export default SlateRenderer;
