import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { FaArrowUp } from 'react-icons/fa6';
import ScrollToTop from 'react-scroll-to-top';

const ScrollToTopButton = () => (
  <ScrollToTop
    smooth
    component={
      <Tooltip
        label="Scroll to top"
        aria-label="Scroll to top tooltip"
        bg="garageGrey.500"
      >
        <div
          style={{
            backgroundColor: '#333232', // Customize button color
            borderRadius: '50%',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FaArrowUp color="white" />
        </div>
      </Tooltip>
    }
    style={{
      backgroundColor: 'transparent', // Make the background transparent
      boxShadow: 'none', // Remove any additional shadow
    }}
  />
);

export default ScrollToTopButton;
