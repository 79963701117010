import React from 'react';
import { FormErrorMessage } from '@chakra-ui/react';
import { FieldErrors, FieldValues } from 'react-hook-form';

interface CustomFormErrorMessageProps {
  errors: FieldErrors<FieldValues>;
  name: string;
}

const CustomFormErrorMessage: React.FC<CustomFormErrorMessageProps> = ({
  errors,
  name,
}) => {
  const getErrorMessage = (): string | null => {
    const error = errors[name];
    if (error) {
      if (typeof error.message === 'string') {
        return error.message;
      }
      return 'Error occurred';
    }
    return null;
  };

  return <FormErrorMessage>{getErrorMessage()}</FormErrorMessage>;
};

export default React.memo(CustomFormErrorMessage);
