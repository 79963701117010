import React, { useRef, useState } from 'react';
import { Box, FormControl } from '@chakra-ui/react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { SetLocalFormData } from '@/components/searchValidation';
import {
  selectFieldStyles,
  useBorderStyle,
} from '@/components/inputfields/inputUtilities';
import CustomLabel from '@/components/Forms/CustomLabel';

interface DateInputProps {
  setLocalFormData: SetLocalFormData;
  onDateSelect?: (date: Date) => void;
  defaultValue?: Date | null;
  height?: string;
  enableCustomStyles?: boolean;
}

const DateInput = ({
  setLocalFormData,
  onDateSelect,
  defaultValue,
  enableCustomStyles = true,
}: DateInputProps) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    defaultValue || new Date(),
  );
  const borderStyles = useBorderStyle('130px', 0, 'left');
  const fieldStyles = enableCustomStyles ? selectFieldStyles() : {};

  const wrapperRef = useRef(null);

  const handleDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const selected = date.toDate();
      setSelectedDate(selected);
      setLocalFormData((prev) => ({ ...prev, date: selected }));
      onDateSelect?.(selected);
    }
  };

  return (
    <Box ref={wrapperRef} position="relative">
      <FormControl {...(enableCustomStyles ? borderStyles : {})}>
        <CustomLabel labelText="Date" mb="6px" />
        <DatePicker
          size="large"
          value={selectedDate ? dayjs(selectedDate) : null}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          style={{ ...fieldStyles }}
          suffixIcon={null}
          allowClear={false}
        />
      </FormControl>
    </Box>
  );
};

export default React.memo(DateInput);
