import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { format, isEqual } from 'date-fns';
import { CiShare2 } from 'react-icons/ci';
import ImagesLayout from '@/components/StudioImages/ImagesLayout';
import StudioSideBar from '@/components/StudioSideBar/StudioSideBar';
import useStudio from '@/hooks/useStudio';
import SlateRenderer from '@/components/Utilities/SlateRenderer';
import LoadingModalOverlay from '@/components/Feedback/LoadingModalOverlay';
import { Description, SlateNode } from '@/hooks/useStudios';
import ReactBigCalendar from '@/components/ReactBigCalendar';
import useStudioAvailability from '@/hooks/useStudioAvailability';
import { useStudioQueryStore } from '@/services/store';
import {
  buildEventForStudioDetails,
  DateRangeProps,
} from '@/components/ReactBigCalendar/helper';
import {
  calculateEndDateTime,
  calculateStartDate,
} from '@/services/dateTimeUtility';
import CopyToClipboard from '@/components/CopyToClipboard';
import StudioOperatingHours from '@/components/StudioDetails/StudioOperatingHours';
import {
  BorderedBox,
  BorderedCard,
  BorderedGridItem,
} from '@/components/Utilities/CustomBorder';

const StudioDetailPage = () => {
  const { id } = useParams();
  const { data: studio, error, isLoading } = useStudio(id!);
  const {
    studioQuery: { date, time, duration },
  } = useStudioQueryStore();
  const location = useLocation();
  const baseURL = `${window.location.origin}${location.pathname}`;

  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState<DateRangeProps>({
    start: new Date(date!),
    end: new Date(date!),
  });
  const { data: studioAvailability } = useStudioAvailability(
    format(dateRange?.start, 'yyyy-MM-dd'),
    format(dateRange?.end, 'yyyy-MM-dd'),
    id!,
  );
  const [bookingEvent, setBookingEvent] = useState<any>([]);
  const isBaseBreakpoint = useBreakpointValue({ base: true, md: false });

  // Function to parse the first paragraph from the Slate JSON description
  const getFirstParagraph = (description: any) => {
    if (!description) return '';
    try {
      const parsedDescription = JSON.parse(description);
      const firstParagraphBlock = parsedDescription.find(
        (block: any) => block.type === 'paragraph',
      );
      return firstParagraphBlock
        ? firstParagraphBlock.children.map((child: any) => child.text).join(' ')
        : '';
    } catch (error) {
      console.error('Error parsing description:', error);
      return '';
    }
  };

  const firstParagraph = getFirstParagraph(studio?.description);

  useEffect(() => {
    if (date && time && duration) {
      const startDate = calculateStartDate(date, time);
      const event = {
        id: studioAvailability?.length || 0,
        title: 'Current Booking',
        start: startDate,
        end: calculateEndDateTime(startDate, duration),
      };
      setBookingEvent([event]);
      const queryParams = new URLSearchParams();
      Object.entries({ date, time, duration }).forEach(([key, value]) => {
        if (value == null || value === '') return;
        queryParams.append(
          key,
          value instanceof Date
            ? format(value, 'yyyy-MM-dd')
            : value.toString(),
        );
      });
      navigate(`${location.pathname}?${queryParams.toString()}`);
    }
  }, [date, time, duration]);

  if (isLoading) return <LoadingModalOverlay message="Loading..." />;
  if (error || !studio) throw error;

  const { images } = studio;
  const firstImageUrl =
    studio.images && studio.images.length > 0
      ? studio.images[0]
      : 'https://example.com/default-image.jpg';

  const isSlateFormat = (
    description: Description,
  ): description is SlateNode[] => {
    if (typeof description === 'string') {
      try {
        const parsed = JSON.parse(description);
        return (
          Array.isArray(parsed) &&
          parsed.length > 0 &&
          parsed[0].type !== undefined &&
          parsed[0].children !== undefined
        );
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e: any) {
        return false;
      }
    }
    return (
      Array.isArray(description) &&
      description.length > 0 &&
      description[0].type !== undefined &&
      description[0].children !== undefined
    );
  };

  const renderDescription = () => {
    let descriptionContent;

    if (typeof studio.description === 'string') {
      try {
        descriptionContent = JSON.parse(studio.description);
      } catch (e) {
        console.error('Invalid JSON format for description', e);
        descriptionContent = studio.description;
      }
    } else {
      descriptionContent = studio.description;
    }

    const hasValidSlateContent =
      isSlateFormat(descriptionContent) &&
      Array.isArray(descriptionContent) &&
      descriptionContent[0]?.children[0]?.text?.length > 0;

    const renderBox = (content: React.ReactNode) => (
      <BorderedBox bg="white" borderRadius="md" boxShadow="md" p={6}>
        <Heading fontSize={{ base: 'md', md: 'xl', lg: '2xl' }} mb={3}>
          Description
        </Heading>
        {content}
      </BorderedBox>
    );

    if (hasValidSlateContent) {
      return renderBox(
        <SlateRenderer content={descriptionContent as SlateNode[]} />,
      );
    }

    if (
      typeof descriptionContent === 'object' &&
      'text' in descriptionContent
    ) {
      return renderBox(
        <Text mt={4} fontSize="md">
          {descriptionContent.text}
        </Text>,
      );
    }

    return null;
  };

  return (
    <>
      <Box p={{ base: 3, md: 6 }} mx="auto" maxWidth="1300px">
        <HStack mb={2} justify="space-between">
          <Box>
            <Heading>{studio.studioName}</Heading>
            <Text fontSize="md">
              {studio.location.address} - {studio.location.postal}
            </Text>
          </Box>
          <CopyToClipboard contentToCopy={baseURL}>
            <CiShare2 size="20px" /> Share Link
          </CopyToClipboard>
        </HStack>
        <ImagesLayout imagesURLs={images} />
        <Grid my={6} templateColumns={{ base: '1fr', lg: '3fr 1fr' }} gap={5}>
          <GridItem width="100%">
            <Flex gap={5} direction="column">
              <BorderedCard
                width="100%"
                bg="white"
                borderRadius="md"
                boxShadow="md"
                p={6}
              >
                <Heading size="lg" mb={2}>
                  {studio.roomName}
                </Heading>
              </BorderedCard>
              <Grid templateColumns={{ base: '1fr', xl: '1fr 1fr' }} gap={2}>
                <BorderedGridItem
                  p={6}
                  maxH="450px"
                  height="100%"
                  width="100%"
                  bg="white"
                  borderRadius="md"
                  boxShadow="md"
                  className="operating-hours-container"
                >
                  <StudioOperatingHours
                    operatingHours={studio.operatingHours}
                    isAlwaysOpen={studio.alwaysOpen}
                    minDuration={(studio.minimumBookingDuration || 60) / 60}
                  />
                </BorderedGridItem>
                <BorderedGridItem
                  p={6}
                  maxH="450px"
                  height="100%"
                  width="100%"
                  minW="400px"
                  bg="white"
                  borderRadius="md"
                  boxShadow="md"
                >
                  <Box h="full" width="inherit">
                    <ReactBigCalendar
                      views={isBaseBreakpoint ? ['day'] : ['day', 'week']}
                      events={[
                        ...buildEventForStudioDetails(studioAvailability),
                        ...bookingEvent,
                      ]}
                      onRangeChange={setDateRange}
                      hideDateRangeSelector
                      initialViews="day"
                      date={
                        isEqual(dateRange?.start, dateRange?.end)
                          ? date || new Date()
                          : dateRange.start
                      }
                      isBaseBreakpoint={isBaseBreakpoint}
                      defaultBreakpointView={
                        isBaseBreakpoint ? 'day' : undefined
                      }
                    />
                  </Box>
                </BorderedGridItem>
              </Grid>
              {renderDescription()}
            </Flex>
          </GridItem>
          <GridItem minWidth="350px" maxWidth={{ base: 'full', lg: '400px' }}>
            <StudioSideBar studio={studio} setDateRange={setDateRange} />
          </GridItem>
        </Grid>
      </Box>
    </>
  );
};

export default StudioDetailPage;
