import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

interface ConfirmModalProps {
  visible: boolean;
  handleClose?: () => void;
  handleConfirm?: () => void;
  modalTitle?: string;
  children: React.ReactNode;
  confirmBtnLoading?: boolean;
  hideCloseIcon?: boolean;
  confirmBtnText?: string;
  hideModalFooter?: boolean;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  visible,
  handleClose,
  handleConfirm,
  modalTitle,
  children,
  confirmBtnLoading,
  hideCloseIcon = false,
  confirmBtnText = 'Confirm',
  hideModalFooter = false,
}) => (
  <Modal
    isCentered
    onClose={handleClose || (() => {})}
    isOpen={visible}
    motionPreset="slideInBottom"
    closeOnOverlayClick={!confirmBtnLoading}
    size={{ base: 'xs', sm: 'md', md: 'lg' }}
  >
    <ModalOverlay />
    <ModalContent>
      {modalTitle && (
        <ModalHeader borderBottom="1px solid" borderColor="gray.300">
          {modalTitle}
        </ModalHeader>
      )}
      {!hideCloseIcon && <ModalCloseButton />}
      <ModalBody p={6}>{children}</ModalBody>
      {!hideModalFooter && (
        <ModalFooter>
          <Button
            mr={3}
            variant="outline"
            onClick={handleClose}
            isDisabled={confirmBtnLoading}
          >
            Cancel
          </Button>
          <Button
            bg="honey.500"
            color="white"
            onClick={handleConfirm}
            isLoading={confirmBtnLoading}
            _hover={{ backgroundColor: 'default' }}
            _active={{ backgroundColor: 'default' }}
          >
            {confirmBtnText}
          </Button>
        </ModalFooter>
      )}
    </ModalContent>
  </Modal>
);

export default React.memo(ConfirmModal);
