import {
  FormControl,
  InputGroup,
  InputLeftAddon,
  Input,
  FormErrorMessage,
  GridItemProps,
  GridItem,
  InputRightAddon,
  Flex,
  Heading,
  Icon,
  Text,
  VStack,
  Box,
  UnorderedList,
  ListItem,
  OrderedList,
} from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { IoPricetagOutline } from 'react-icons/io5';
import { capitalize } from 'lodash';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CustomPriceProps extends GridItemProps {
  inputText: string;
  control: any;
  name: string;
  colSpan?: GridItemProps['colSpan'];
  platformFeePercentage?: number;
}

const chargeRates = {
  cards: {
    baseRate: { percentage: 3.4, fixedFee: 0.5 },
    internationalCards: 0.5,
  },
  pay_now: {
    baseRate: { percentage: 1.3, fixedFee: 0.0 },
    internationalCards: null,
  },
};

const CustomPriceInput = ({
  control,
  name,
  inputText,
  colSpan,
  platformFeePercentage = 10,
  ...props
}: CustomPriceProps) => {
  const [netProfitRange, setNetProfitRange] = useState({ min: 0, max: 0 });

  const calculateNetProfit = useCallback(
    (amount: number, percentage: number, fixedFee: number) => {
      const stripeFee = amount * percentage + fixedFee;
      const platformFee = amount * (platformFeePercentage / 100);
      return amount - stripeFee - platformFee;
    },
    [platformFeePercentage],
  );

  const handleChange = (amount: any) => {
    if (!Number.isNaN(amount)) {
      const profits = Object.values(chargeRates).map(({ baseRate }) =>
        calculateNetProfit(
          amount,
          baseRate.percentage / 100,
          baseRate.fixedFee,
        ),
      );
      const minProfit = Math.min(...profits);
      const maxProfit = Math.max(...profits);
      setNetProfitRange({ min: minProfit, max: maxProfit });
    }
  };

  useEffect(() => {
    const amount = parseFloat(control._formValues[name]);
    handleChange(amount);
  }, [control._formValues, name, calculateNetProfit]);

  const renderChargeRates = () => (
    <OrderedList mt={2} spacing={2}>
      <ListItem fontSize="sm">
        <Text as="strong">Platform Fees:</Text> {platformFeePercentage}% of the
        booking amount.
      </ListItem>
      <ListItem fontSize="sm">
        <Text as="strong">Stripe Fees:</Text>
        <UnorderedList styleType="disc" ml={6} mt={1} spacing={1}>
          {Object.entries(chargeRates).map(
            ([method, { baseRate, internationalCards }]) => (
              <ListItem key={method} fontSize="sm">
                <Text fontWeight="bold">
                  {method === 'cards'
                    ? 'Cards / Apple pay / Google pay'
                    : capitalize(method.replaceAll('_', ' '))}
                  :
                </Text>
                <Text>
                  {baseRate.percentage}%{' '}
                  {baseRate.fixedFee > 0 && (
                    <span>+ S$ {baseRate.fixedFee.toFixed(2)}</span>
                  )}{' '}
                  per transaction
                </Text>
                {internationalCards && (
                  <Text fontSize="sm" color="gray.500">
                    + {internationalCards}% for international cards
                  </Text>
                )}
              </ListItem>
            ),
          )}
        </UnorderedList>
      </ListItem>
    </OrderedList>
  );

  return (
    <VStack
      align="stretch"
      spacing={4}
      bg="white"
      px={4}
      py={6}
      rounded="md"
      border="1px"
      borderColor="powder.600"
      shadow="base"
    >
      <Heading fontSize="lg" fontWeight="medium">
        <Flex alignItems="center" fontWeight={600}>
          <Icon as={IoPricetagOutline} w={6} h={6} mr={2} />
          Pricing
        </Flex>
      </Heading>
      <Text fontSize="sm" color="gray.600">
        Please enter the hourly rate for your studio. Note that Stripe and
        platform fees will be deducted from each booking.
      </Text>
      <Box
        bg="gray.50"
        p={4}
        rounded="md"
        borderWidth="1px"
        borderColor="gray.200"
      >
        <Text fontWeight="bold">Fees Breakdown:</Text>
        {renderChargeRates()}
      </Box>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <FormControl
            as={GridItem}
            colSpan={colSpan}
            isInvalid={!!fieldState.error}
          >
            <CustomLabel labelText="Hourly rate" />
            <InputGroup borderColor="powder.500">
              <InputLeftAddon bgColor="powder.500">S$</InputLeftAddon>
              <Input
                {...field}
                type="number"
                placeholder={inputText}
                rounded="md"
                height={10}
                p={2}
                _placeholder={{ fontSize: 'sm', color: 'gray.400' }}
                onChange={(e) => {
                  const amount = parseFloat(e.target.value);
                  field.onChange(amount);
                  handleChange(amount);
                }}
                {...props}
              />
              <InputRightAddon bgColor="powder.500">/Hour</InputRightAddon>
            </InputGroup>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
      <Box
        bg="honey.100"
        p={4}
        rounded="md"
        borderWidth="1px"
        borderColor="honey.300"
      >
        <Text fontSize="sm" fontWeight="bold">
          Estimated Net Profit Range:
        </Text>
        <Text fontSize="lg" mt={2}>
          S${netProfitRange.min.toFixed(2)} - S${netProfitRange.max.toFixed(2)}{' '}
          / Hour{' '}
        </Text>
        <Text fontSize="sm">
          (Based on the payment method, the stripe fee will vary)
        </Text>
      </Box>
    </VStack>
  );
};

export default CustomPriceInput;
