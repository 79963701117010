import {
  Box,
  Container,
  Flex,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactElement } from 'react';
import { PiMoney } from 'react-icons/pi';
import { VscChecklist } from 'react-icons/vsc';
import { TbUsersGroup } from 'react-icons/tb';
import { HiOutlineCalendarDays } from 'react-icons/hi2';
import { NotAllowedIcon } from '@chakra-ui/icons';

interface CardProps {
  heading: string;
  description: string;
  icon: ReactElement;
}

const Card = ({ heading, description, icon }: CardProps) => (
  <Box
    maxW={{ base: 'full', md: '275px' }}
    w="full"
    borderWidth="1px"
    borderRadius="lg"
    overflow="hidden"
    p={5}
  >
    <Stack align="start" spacing={2}>
      <Flex
        w={16}
        h={16}
        align="center"
        justify="center"
        color="white"
        rounded="full"
        bg={useColorModeValue('oak.500', 'oak.700')}
      >
        {icon}
      </Flex>
      <Box mt={2}>
        <Heading size="md">{heading}</Heading>
        <Text mt={1} fontSize="sm" color="garageGrey.400">
          {description}
        </Text>
      </Box>
    </Stack>
  </Box>
);

const StudioOwnersJoinCTA = () => (
  <Box p={4}>
    <Stack spacing={4} as={Container} maxW="3xl" textAlign="center">
      <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight="bold">
        We support all creative spaces
      </Heading>
      <Text color="garageGrey.400" fontSize={{ base: 'sm', sm: 'lg' }}>
        Whether you run a rehearsal studio, music school, or recording suite, we
        have you covered. Join us and connect with a vibrant community of
        artists and musicians searching for spaces like yours.
      </Text>
    </Stack>

    <Container maxW="5xl" mt={12}>
      <Flex flexWrap="wrap" gridGap={6} justify="center">
        <Card
          heading="Secure Transaction"
          icon={<Icon as={PiMoney} w={10} h={10} color="white" />}
          description="Stripe integration ensures safe and secure payments."
        />
        <Card
          heading="Free Listing, Low Fees"
          icon={<Icon as={VscChecklist} w={10} h={10} color="white" />}
          description="List your studio for free and enjoy low platform fees."
        />
        <Card
          heading="Increase exposure"
          icon={<Icon as={TbUsersGroup} w={10} h={10} color="white" />}
          description="Get your studio seen by more users searching for spaces like yours."
        />
        <Card
          heading="Manage Bookings Easily"
          icon={<Icon as={HiOutlineCalendarDays} w={10} h={10} color="white" />}
          description="Handle all your bookings and schedules in one place."
        />
        <Card
          heading="Protect Against Late Cancellations"
          icon={<Icon as={NotAllowedIcon} w={10} h={10} color="white" />}
          description="Worried about cancellations? Our policy protects your bookings."
        />
      </Flex>
    </Container>
  </Box>
);

export default StudioOwnersJoinCTA;
