import React, { useEffect, useState } from 'react';
import {
  Box,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  InputGroup,
} from '@chakra-ui/react';
import { FaPlus, FaMinus } from 'react-icons/fa6';

interface NumericStepperInputProps {
  defaultValue?: number;
  min?: number;
  max?: number;
  step?: number;
  formatValue?: (value: number) => string;
  onChange: (value: number) => void;
  maxW?: string;
  inputWidth?: string;
}

const NumericStepperInput: React.FC<NumericStepperInputProps> = ({
  defaultValue = 1,
  min = 1,
  max = 24,
  step = 0.5,
  formatValue,
  onChange,
  maxW = '250px',
  inputWidth = '40px',
}) => {
  const [value, setValue] = useState<number>(defaultValue);

  const handleChange = (data: string | number) => {
    const newValue = Number(data);
    if (!Number.isNaN(newValue)) {
      setValue(newValue);
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <NumberInput
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={handleChange}
      display="flex"
      border="1px solid"
      borderColor="gray.100"
      borderRadius="md"
      maxW={maxW}
    >
      <NumberDecrementStepper
        border="none"
        px={3}
        fontSize="md"
        borderRadius={0}
      >
        <FaMinus />
      </NumberDecrementStepper>
      <InputGroup px={4} display="flex" alignItems="center">
        <NumberInputField
          border="none"
          _focus={{ border: 'none', boxShadow: 'none' }}
          readOnly
          w={inputWidth}
          p={0}
          textAlign="right"
        />
        {formatValue && <Box ml={2}>{formatValue(value)}</Box>}
      </InputGroup>
      <NumberIncrementStepper
        border="none"
        px={3}
        fontSize="md"
        borderRadius={0}
      >
        <FaPlus />
      </NumberIncrementStepper>
    </NumberInput>
  );
};

export default React.memo(NumericStepperInput);
