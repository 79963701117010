import axios, { AxiosRequestConfig } from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { handleErrorResponse } from '@/components/Utilities/helper';

export interface PaginationMeta {
  currentPage: number;
  nextPage: number | null;
  prevPage: number | null;
  totalPages: number;
  totalEntries: number;
}

export interface FetchResponse<T> {
  meta?: PaginationMeta;
  data: T[];
}

const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_BASE_URL}/api/`,
});

class APIClient<T> {
  endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;

    // Convert camelCase to snake_case for outgoing requests
    axiosInstance.interceptors.request.use(
      (config) => {
        if (config.data && !(config.data instanceof FormData)) {
          config.data = decamelizeKeys(config.data);
        }
        return config;
      },
      (error) => {
        console.error('Error in response interceptor:', error);
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        if (response.data) {
          response.data = camelizeKeys(response.data);
        }
        return response;
      },
      (error) => handleErrorResponse(error),
    );
  }

  post = (body: T, config?: AxiosRequestConfig) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
      // "Content-Type": "application/json",
      'Content-Type':
        body instanceof FormData ? 'multipart/form-data' : 'application/json',
    };
    return axiosInstance
      .post<T>(this.endpoint, body, {
        ...config,
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res.data);
  };

  getAll = (config: AxiosRequestConfig) =>
    axiosInstance
      .get<FetchResponse<T>>(this.endpoint, config)
      .then((res) => res.data);

  get = (id?: number | string) => {
    const url = id ? `${this.endpoint}/${id}` : this.endpoint;
    return axiosInstance
      .get<T>(url)
      .then((res) => res.data)
      .catch((error) => {
        console.error('An error occurred while fetching data:', error);
        throw error;
      });
  };

  delete = (id?: number | string, config?: AxiosRequestConfig) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
      'Content-Type': 'application/json',
    };

    return axiosInstance
      .delete<T>(id ? `${this.endpoint}/${id}` : this.endpoint, {
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res.data);
  };

  patch = (body?: T, config?: AxiosRequestConfig) => {
    const headers = {
      'X-CSRF-Token': this.getCSRFToken(),
      'Content-Type':
        body instanceof FormData ? 'multipart/form-data' : 'application/json',
    };

    return axiosInstance
      .patch<T>(this.endpoint, body, {
        ...config,
        headers: { ...config?.headers, ...headers },
      })
      .then((res) => res.data);
  };

  // Protection token against CSRF attacks
  // eslint-disable-next-line class-methods-use-this
  private getCSRFToken(): string | null {
    const metaElement = document.querySelector('meta[name="csrf-token"]');
    if (metaElement && metaElement instanceof HTMLMetaElement) {
      return metaElement.content;
    }
    return null;
  }
}

export default APIClient;
