import {
  Modal,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  message: string;
}

const LoadingModalOverlay = ({ message }: Props) => (
  <Modal isOpen onClose={() => {}} isCentered size="xs">
    <ModalOverlay bg="blackAlpha.600">
      <ModalContent
        background="transparent"
        boxShadow="none"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="honey.500"
          size="xl"
        />
        <Text mt={4} color="white" fontSize="lg" fontWeight="bold">
          {message}
        </Text>
        <Text mt={4} color="white" fontSize="sm">
          This may take a while...
        </Text>
      </ModalContent>
    </ModalOverlay>
  </Modal>
);

export default LoadingModalOverlay;
