import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, Flex, VStack } from '@chakra-ui/react';
import SearchFilter from '@/components/Layouts/Filter/SearchFilter';
import PageFooter from '@/components/Layouts/Footer/PageFooter';
import NavbarMain from '@/components/Layouts/Navbar/NavbarMain';

const SearchBarLayout = () => {
  const location = useLocation();
  const hideSearchFilter = location.pathname.split('/').length === 2;
  return (
    <VStack spacing={0} minHeight="100vh">
      <NavbarMain />
      {hideSearchFilter && (
        <Box as={Flex} width="full" justifyContent="center">
          <SearchFilter />
        </Box>
      )}
      <Flex flex="1" direction="column" width="full">
        <Outlet />
      </Flex>
      <PageFooter />
    </VStack>
  );
};

export default SearchBarLayout;
