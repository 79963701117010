import { z } from 'zod';
import dayjs from 'dayjs';

const mobileNumber = z
  .string()
  .min(1, 'Contact number is required.')
  .regex(/^\d{8}$/, 'Contact number must be exactly 8 digits.');

export const OTVerificationSchema = z.object({
  mobileNumber,
});

export const OTPVerificationSchema = z.object({
  otp: z
    .string()
    .length(6, { message: 'OTP is Required' })
    .regex(/^\d+$/, { message: 'OTP must contain only numbers' }),
});

export const userProfileSchema = z.object({
  firstName: z.string().min(1, 'First name is required'),
  lastName: z.string().min(1, 'Last name is required'),
  dateOfBirth: z
    .string({
      invalid_type_error: 'Date of birth is required',
    })
    .refine(
      (val) => {
        const date = new Date(val);
        return !Number.isNaN(date.getTime()) && date <= new Date();
      },
      {
        message: 'Date of birth cannot be in the future or invalid',
      },
    )
    .transform((val) => dayjs(val).format('YYYY-MM-DD')),
  mobileNumber,
  gender: z.string(),
  email: z.string().email(),
});
