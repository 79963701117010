import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';

interface studioBookings {
  reservations: any;
  id: number;
  studio_id: number;
  dateTime: string;
  duration: number;
  price: number;
  studioName: string;
  roomName: string;
  images?: string[];
}

const apiClient = new APIClient<{ data: studioBookings[] }>('v1/users');

// Fetches all reservations for a studio
const useOwnedStudioReservations = (userId: number | undefined) => {
  const url = `${userId}/studios_reservations`;

  const { data, ...rest } = useQuery({
    queryKey: ['studioBookings', userId],
    queryFn: () => apiClient.get(url),
  });

  return { data: data?.data || [], ...rest };
};

export default useOwnedStudioReservations;
