import React from 'react';

export const getDurationOptions = (minValue: number) => {
  const durationOptions: JSX.Element[] = [];

  for (let steps = 2; steps <= 48; steps += 1) {
    const hours = Math.floor(steps / 2);
    const minutes = (steps % 2) * 30;

    const durationValue = steps * 30;
    const durationLabel = `${hours} hr${hours > 1 ? 's' : ''} ${
      minutes ? `${minutes} mins` : ''
    }`.trim();

    durationOptions.push(
      <option
        key={durationLabel}
        value={durationValue}
        disabled={durationValue < minValue}
      >
        {durationLabel}
      </option>,
    );
  }

  return durationOptions;
};
