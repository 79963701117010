import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';
import { flattenAttributes } from '@/components/Utilities/helper';

export interface UserStudios {
  id: number;
  slug?: string;
  studioName: string;
  roomName: string;
  description: string;
  price: number;
  images: string[];
  minimumBookingDuration?: number;
}

const apiClient = new APIClient<{ data: UserStudios[] }>('v1/users');

// fetches all studios a user owns
const useOwnedStudios = (userId: number | undefined, type: string | undefined = 'manage_studios') => {
  const url = `${userId}/studios?index_type=${type}`;

  const { data, ...rest } = useQuery({
    queryKey: ['userStudios', userId],
    queryFn: () => apiClient.get(url),
  });

  return { data: flattenAttributes(data?.data || []), ...rest } as any;
};

export default useOwnedStudios;
