import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { UserDetails, useAuthStore } from '@/services/store';
import AuthAPIClient from '@/services/auth-api-client';

interface AuthStatus {
  isAuthenticated: boolean;
  data: UserDetails | null;
}

const useAuthenticateOnLoad = () => {
  const apiClient = new AuthAPIClient<AuthStatus>('/v1/auth/validate_token');
  const setAuth = useAuthStore((state) => state.setAuth);
  const clearAuth = useAuthStore((state) => state.clearAuth);
  const setLoading = useAuthStore((state) => state.setLoading);

  const query = useQuery({
    queryKey: ['authStatus'],
    queryFn: () => apiClient.authenticationStatus(),
    retry: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query.isSuccess && query.data && query.data.data) {
      // Check if query.data.data exists
      setLoading(false);
      setAuth(query.data.data); // Pass only the UserDetails object (query.data.data)
    } else if (query.isError) {
      setLoading(false);
      clearAuth();
    }
  }, [
    query.isSuccess,
    query.isError,
    query.data,
    setLoading,
    setAuth,
    clearAuth,
  ]);

  return query;
};

export default useAuthenticateOnLoad;
