import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import AuthAPIClient from '@/services/auth-api-client';
import { useAuthStore } from '@/services/store';

export interface Error {
  response: {
    data: {
      errors: string[];
    };
  };
}
const useSignOut = () => {
  const { clearAuth } = useAuthStore((state) => state);
  const navigate = useNavigate();

  const authApiClient = new AuthAPIClient('/v1/auth/sign_out');

  return useMutation({
    mutationKey: ['signout'],
    mutationFn: () => authApiClient.signOut(),
    onSuccess: () => {
      clearAuth();
      navigate('/', { replace: true });
    },
    onError: () => {},
  });
};
export default useSignOut;
