import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Text,
  Divider,
  Heading,
  HStack,
  IconButton,
  Spacer,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useAuthStore,
  useReservationStore,
  useStudioQueryStore,
} from '@/services/store';
import StudioSummaryCard from '@/components/BookingConfirmation/StudioSummaryCard';
import DateTimeInfoCard from '@/components/BookingConfirmation/DateTimeInfoCard';
import useStudio from '@/hooks/useStudio';
import CancellationPolicy from '@/components/BookingConfirmation/CancellationPolicy';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import { getReservationDetails } from '@/components/Utilities/ReservationHandler';
import LoadingSpinner from '@/components/LoadingSpinner';
import PaymentSessionTimeout from '@/components/BookingConfirmation/PaymentSessionTimeout';

const ConfirmationPage = () => {
  const navigate = useNavigate();
  const { getFormattedDateTime, studioQuery } = useStudioQueryStore(
    (state) => state,
  );
  const { id } = useParams();
  const { user } = useAuthStore();
  const toast = useShowToastTop();
  const handleReservation = getReservationDetails(navigate, toast, user?.id, id!);
  const { reservation } = useReservationStore();
  const studioId = reservation?.studioSlug || reservation?.studioId;
  const { data: studio } = useStudio(studioId!);
  const formattedDateTime = getFormattedDateTime();
  const { duration } = studioQuery;
  const [timeLeft, setTimeLeft] = useState(300); // 300 seconds = 5 minutes

  const {
    formattedDate,
    formattedTime,
    formattedEndDate,
    formattedEndTime,
    durationInString,
  } = formattedDateTime || {};

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    toast({
      message: 'Your reservation has timeout, please try again.',
      status: 'error',
    });
    navigate(-1);
  }, [navigate]);

  useEffect(() => {
    if (user?.id && !reservation?.id) {
      handleReservation();
    }
  }, [user]);

  if (!reservation || !studio) {
    return <LoadingSpinner height="calc(100vh - 168px)" />;
  }

  return (
    <Box bg="white" w="full" minH="calc(100vh - 88px)">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        justify="center"
        align="start"
        p={8}
        maxW="1200px"
        m="auto"
      >
        <VStack mr={4} spacing={4} w="full" align="start" maxW="600px">
          <HStack>
            <IconButton
              aria-label="Go back"
              icon={<ArrowBackIcon boxSize={6} />}
              onClick={() => navigate(-1)}
              variant="ghost"
              size="lg"
              _hover={{ bg: 'gray.100' }}
            />
            <Heading size="lg">Request to book</Heading>
          </HStack>
          <Text fontSize="xl" fontWeight="semibold">
            Your booking details
          </Text>
          <Flex direction="column" w="full">
            <DateTimeInfoCard
              date={formattedDate}
              time={formattedTime}
              durationInString={durationInString}
              endDate={formattedEndDate}
              endTime={formattedEndTime}
            />
          </Flex>
          <Spacer />
          {reservation && studio && (
            <PaymentSessionTimeout reservation={reservation} studio={studio} />
          )}
        </VStack>
        <Divider orientation="vertical" />
        <Box maxW="400px" mt={0} w="full">
          <VStack mt={4} spacing={4} align="start" w="full">
            <Flex
              flexDirection="column"
              border="1px"
              borderColor="powder.600"
              gap={2}
              w="full"
              padding={4}
              borderRadius={10}
              boxShadow="md"
            >
              <StudioSummaryCard duration={duration} studio={studio} />
            </Flex>
            <Flex flexDirection="column" width="full">
              <Spacer />
              <CancellationPolicy />
            </Flex>
          </VStack>
        </Box>
      </Flex>
    </Box>
  );
};

export default ConfirmationPage;
