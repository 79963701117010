import React, { useState } from 'react';
import {
  FormControl,
  Select,
  Textarea,
  Text,
  FormHelperText,
} from '@chakra-ui/react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Controller, useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import FormModal from '@/components/Modal/FormModal';
import CustomFormErrorMessage from '@/components/Forms/CustomFormErrorMessage';
import { useCancelBookingStore } from '@/services/store';
import useCancelStudioBooking from '@/hooks/useCancelStudioBooking';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import {
  cancelBookingConfirmationText,
  cancelBookingReasons,
  cancelBookingWarningText,
} from '@/components/Utilities/BookingCancel';
import CustomLabel from '@/components/Forms/CustomLabel';

interface CancelBookingDialogProps {
  userId: number;
  reservationId: number;
  refetch: () => void;
  bookingType?: string;
  userRole: 'studio_owner' | 'user';
}

interface CancellationFormData {
  reason: string;
  description: string;
}

const CancelBookingDialog: React.FC<CancelBookingDialogProps> = ({
  userId,
  reservationId,
  refetch,
  bookingType,
  userRole,
}) => {
  const { mutate } = useCancelStudioBooking(userId, reservationId);
  const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);

  const { isCancelBookingModalVisible, updateCancelBookingModalVisibility } =
    useCancelBookingStore();

  const showToast = useShowToastTop();
  const queryClient = useQueryClient(); // React Query's QueryClient to manage queries and cache

  const handleSubmit = (reason: string, description: string) => {
    setSubmitBtnLoading(true);

    const updatedReservation = {
      cancellationReason: reason,
      cancellationDescription: description,
      cancellationUserRole: userRole,
    };

    mutate(updatedReservation, {
      onSuccess: (data: any) => {
        updateCancelBookingModalVisibility(false);
        refetch();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        queryClient.invalidateQueries('reservations');
        showToast({
          message: data?.message,
          status: 'success',
          duration: 5000,
        });
        setSubmitBtnLoading(false);
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.error ||
          error?.message ||
          'Error cancelling booking. Please try again later.';

        showToast({
          message: errorMessage,
          status: 'error',
        });
        setSubmitBtnLoading(false);
      },
    });
  };

  const cancellationSchema = z.object({
    reason: z.string().min(1, 'Reason is required'),
    description: z
      .string()
      .min(1, 'Description is required')
      .max(500, 'Description must not exceed 500 characters'),
  });

  const methods = useForm<CancellationFormData>({
    resolver: zodResolver(cancellationSchema),
    mode: 'onChange',
  });

  const {
    control,
    formState: { errors },
  } = methods;

  const handleClose = () => {
    updateCancelBookingModalVisibility(false);
  };

  const onSubmit = (data: CancellationFormData) => {
    handleSubmit(data.reason, data.description);
  };

  return (
    <FormModal
      title="Cancel Booking"
      visible={isCancelBookingModalVisible}
      onClose={handleClose}
      onSubmit={onSubmit}
      methods={methods}
      hideCloseIcon
      isSubmitting={submitBtnLoading}
      submitButtonText="Yes, cancel booking"
      cancelButtonText="No, go back"
      submitBtnVariant="danger"
      cancelBtnVariant="default"
      modalSize={{ base: 'xs', sm: 'md' }}
    >
      <FormControl isRequired isInvalid={!!errors.reason} mb={4}>
        <CustomLabel labelText="Reason:" />
        <Controller
          name="reason"
          control={control}
          render={({ field }) => (
            <>
              <Select placeholder="Select reason" {...field}>
                {cancelBookingReasons.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
              <CustomFormErrorMessage errors={errors} name="reason" />
            </>
          )}
        />
      </FormControl>

      <FormControl isRequired isInvalid={!!errors.description} mb={4}>
        <CustomLabel labelText="Description:" />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <>
              <Textarea
                placeholder="Max 500 characters"
                maxLength={500}
                resize="vertical"
                {...field}
                onChange={field.onChange}
              />
              <FormHelperText textAlign="right">
                {(field?.value || '').length}/500
              </FormHelperText>
              <CustomFormErrorMessage errors={errors} name="description" />
            </>
          )}
        />
      </FormControl>
      {bookingType?.toLowerCase() !== 'offline' && (
        <Text fontSize="xs" mb={4}>
          <strong>{cancelBookingWarningText.boldText}</strong>{' '}
          {cancelBookingWarningText.normalText}
        </Text>
      )}
      <Text fontSize="md" fontWeight="bold">
        {cancelBookingConfirmationText}
      </Text>
    </FormModal>
  );
};

export default React.memo(CancelBookingDialog);
