import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl } from '@chakra-ui/react';
import DragAndDropUpload from '@/components/Upload/DragAndDropUpload';
import CustomFormErrorMessage from '@/components/Forms/CustomFormErrorMessage';
import CustomLabel from '@/components/Forms/CustomLabel';

interface FormFieldProps {
  name: string;
  label: string;
  accept: string;
  maxSize: number;
  isMultipleFileUpload?: boolean;
  onFileChange?: (files: File[]) => void;
  description?: string | React.ReactNode;
  isRequired?: boolean;
}

const FileUploadFormField: React.FC<FormFieldProps> = ({
  name,
  label,
  accept,
  maxSize,
  isMultipleFileUpload = false,
  onFileChange,
  description,
  isRequired,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange } }) => (
        <FormControl isInvalid={!!errors[name]}>
          <CustomLabel
            htmlFor={name}
            labelText={label}
            isRequired={isRequired}
          />
          <DragAndDropUpload
            accept={accept}
            maxSize={maxSize}
            isMultipleFileUpload={isMultipleFileUpload}
            onFileChange={(files) => {
              onChange(files);
              if (onFileChange) {
                onFileChange(files);
              }
            }}
            description={description}
          />
          <CustomFormErrorMessage errors={errors} name={name} />
        </FormControl>
      )}
    />
  );
};

export default React.memo(FileUploadFormField);
