import React from 'react';

interface CustomCalendarEventProps {
  event: any;
}

const CustomCalendarEvent: React.FC<CustomCalendarEventProps> = ({ event }) => (
  <strong>{event.title}</strong>
);

export default React.memo(CustomCalendarEvent);
