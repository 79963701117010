import React from 'react';
import { FormLabel, Text, FormLabelProps, Tooltip } from '@chakra-ui/react';

interface CustomLabelProps extends FormLabelProps {
  labelText: string | React.ReactNode;
  isRequired?: boolean;
  toolTipLabel?: string | React.ReactNode;
}

const CustomLabel = ({
  labelText,
  toolTipLabel,
  isRequired,
  ...props
}: CustomLabelProps) => (
  <Tooltip
    isDisabled={!toolTipLabel}
    label={toolTipLabel}
    hasArrow
    w="auto"
    placement="top"
    p={3}
    fontSize="md"
  >
    <FormLabel
      display="flex"
      fontSize="sm"
      fontWeight="bold"
      color="gray.700"
      _dark={{ color: 'gray.50' }}
      w="max-content"
      {...props}
    >
      {labelText}
      {isRequired && (
        <Text as="span" color="red" ml={2}>
          *
        </Text>
      )}
    </FormLabel>
  </Tooltip>
);

export default CustomLabel;
