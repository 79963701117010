import { Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as zod from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import useForgotPassword from '@/hooks/useForgotPassword';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import AuthForm from '@/components/Authentication/AuthForm';
import { commonErrorMessage } from '@/components/Utilities/constants';

const defaultValues = { email: '' };

const ForgotPasswordForm = () => {
  const { mutate, isPending } = useForgotPassword();
  const resetPasswordSchema = zod.object({ email: zod.string().email() });
  const methods = useForm({
    resolver: zodResolver(resetPasswordSchema),
    defaultValues,
  });
  const showToast = useShowToastTop();

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: () => {
        showToast({
          message: 'Please check your email for reset link',
          status: 'success',
        });
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.errors[0] ||
          error?.response?.data?.errors?.password[0] ||
          commonErrorMessage;
        showToast({ message: errorMessage, status: 'error' });
      },
    });
  };

  return (
    <AuthForm
      methods={methods}
      onSubmit={onSubmit}
      submitButtonLabel="Request reset"
      isPending={isPending}
      heading="Reset your password"
    >
      <Text
        fontSize={{ base: 'sm', sm: 'md' }}
        color={useColorModeValue('gray.800', 'gray.400')}
      >
        You&apos;ll get an email with a reset link
      </Text>
      <CustomInputField
        name="email"
        label="Email Address"
        control={methods.control}
        type="email"
        isRequired
      />
    </AuthForm>
  );
};

export default React.memo(ForgotPasswordForm);
