import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  GridItemProps,
  Input,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import '@/components/Forms/country.css';
import CustomLabel from '@/components/Forms/CustomLabel';

interface Props {
  control: any;
  name: string;
  colSpan?: GridItemProps['colSpan'];
  isRequired?: boolean;
}

const PostalField = ({ name, control, colSpan, isRequired }: Props) => (
  <GridItem rowSpan={1} colSpan={colSpan}>
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl
          isInvalid={fieldState.isTouched && fieldState.invalid}
          isRequired={isRequired}
        >
          <CustomLabel labelText="Postal" />
          <Input
            {...field}
            placeholder="123456"
            _placeholder={{
              fontSize: 'sm',
              color: 'gray.400',
            }}
          />
          <FormErrorMessage>
            {fieldState.isTouched && fieldState.error?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    />
  </GridItem>
);

export default PostalField;
