import { Button, Flex, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

const CreateStudioConfirmation = () => {
  const [timer, setTimer] = React.useState(7);
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setTimeout(() => {
      navigate('/');
    }, 7000);

    return () => clearTimeout(interval);
    // eslint-disable-next-line
  }, [navigate]);

  const handleRedirect = () => {
    navigate('/');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((time: number) => time - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Flex direction="column" align="center" justify="center" height="80vh">
      <IoIosCheckmarkCircleOutline size={100} color="#38A169" />
      <Text mb={4}>
        Your studio is now pending approval. Please allow 3-5 business days for
        update.
      </Text>
      <Button color="white" colorScheme="garageGrey" onClick={handleRedirect}>
        Go to homepage
      </Button>
      <Text fontSize="xs" pt={2}>
        You will be redirected in {timer} seconds..
      </Text>
    </Flex>
  );
};

export default CreateStudioConfirmation;
