import React, { useState } from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Button,
  Icon,
  Text,
  Avatar,
  useBreakpointValue,
  MenuGroup,
  MenuDivider,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { FaPlus } from 'react-icons/fa';
import { UserDetails } from '@/services/store';

interface UserMenuProps {
  menuItems: any[];
  user: UserDetails | null;
}

const getUserName = (user: UserDetails | null): string =>
  user?.firstName && user?.lastName
    ? `${user.firstName} ${user.lastName}`
    : user?.email || 'MuseHive User';

const registerStudioMenu = {
  label: 'List your Studio',
  key: 'register_studio',
  icon: FaPlus,
  to: '/studios/new',
};

const UserMenu: React.FC<UserMenuProps> = ({ menuItems, user }) => {
  const showFullMenu = useBreakpointValue({ base: false, md: true });
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let filteredMenuItems = menuItems;

  if (!showFullMenu) {
    filteredMenuItems = [...filteredMenuItems, registerStudioMenu];
  }

  // Divide filtered menu items into two groups: Studio Management and Account Settings
  const menuKeys: Record<string, string[]> = {
    studioManagement: [
      'my_studios',
      'manage_reservations',
      'booking_import',
      'bookings',
      'register_studio',
    ],
    accountSettings: [
      'edit_profile',
      'change_password',
      'payment_settings',
      'logout',
      'admin_panel',
    ],
  };

  const getMenuItems = (menuType: string) =>
    filteredMenuItems.filter((item) => menuKeys[menuType].includes(item.key));

  const studioManagementMenuItems = getMenuItems('studioManagement');
  const accountSettingsMenuItems = getMenuItems('accountSettings');

  const menuItemStyle = {
    borderRadius: 5,
    _hover: { bg: 'powder.500' },
    _active: { bg: 'powder.700' },
  };

  const handleMenuButtonContent = () => (
    <>
      <Avatar size="sm" name={getUserName(user)} bg="honey.500" />
      <Text display={{ base: 'none', md: 'block' }}>{getUserName(user)}</Text>
    </>
  );

  return (
    <Menu
      autoSelect={false}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
    >
      <MenuButton
        as={Button}
        border="1px"
        borderColor="powder.600"
        _hover={{ boxShadow: 'md' }}
        _active={{ bgColor: 'white' }}
        bg="white"
        textColor="garageGrey.500"
        px={{ base: '5px', md: 4 }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          {handleMenuButtonContent()}
        </Box>
      </MenuButton>
      <MenuList
        p={2}
        border="none"
        position="absolute"
        zIndex={100}
        boxShadow="xl"
        right={{
          base: '-40px',
          md: user?.firstName && user?.lastName ? '-100px' : '-140px',
          xl: '-145px',
        }}
      >
        {/* Group: Studio Management */}
        {studioManagementMenuItems.length > 0 && (
          <MenuGroup title="Studio Management">
            {studioManagementMenuItems.map(
              ({ icon, label, to, as, key, ...props }) => (
                <MenuItem
                  key={key}
                  {...menuItemStyle}
                  icon={<Icon as={icon} boxSize={4} />}
                  as={as || (to && Link)}
                  to={to}
                  href={as ? to : undefined}
                  {...props}
                >
                  {label}
                </MenuItem>
              ),
            )}
          </MenuGroup>
        )}
        {user?.studioOwner &&
          accountSettingsMenuItems.length > 0 &&
          studioManagementMenuItems.length > 0 && <MenuDivider />}
        {/* Group: Account Settings */}
        {accountSettingsMenuItems.length > 0 && (
          <MenuGroup title="Account Settings">
            {accountSettingsMenuItems.map(
              ({ icon, label, to, as, key, ...props }) => (
                <MenuItem
                  key={key}
                  {...menuItemStyle}
                  icon={<Icon as={icon} boxSize={4} />}
                  as={as || (to && Link)}
                  to={to}
                  href={as ? to : undefined}
                  {...props}
                >
                  {label}
                </MenuItem>
              ),
            )}
          </MenuGroup>
        )}
      </MenuList>
    </Menu>
  );
};

export default React.memo(UserMenu);
