import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';
import { Studio } from '@/hooks/useStudios';
import { flattenAttributes } from '@/components/Utilities/helper';

export interface UserReservation {
  id: number;
  studioId: number;
  userId: number;
  startDatetime: string;
  duration: number;
  totalPrice: number;
  studio: Studio;
  bookingNumber: number;
  status: 'pending' | 'confirmed' | 'cancelled';
  bookingType: string;
  specialRequests?: string;
  reservationSessionTimeout?: string;
  studioSlug?: string;
}

const apiClient = new APIClient<{ data: UserReservation[] }>('v1/users');

// fetches a user's reservations for studios
const useUserReservations = (
  userId: number | undefined,
  queryParams: number[],
) => {
  let url = `${userId}/reservations`;
  if (queryParams.length > 0) {
    url = `${url}?id=${queryParams.toString()}`;
  }

  const { data, ...rest } = useQuery({
    queryKey: ['userReservations', userId],
    queryFn: () => apiClient.get(url),
    enabled: !!userId, // Disable query if userId is undefined
  });

  return { data: flattenAttributes(data?.data || []), ...rest };
};

export default useUserReservations;
