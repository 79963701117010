import React, { useCallback, useEffect, useState } from 'react';
import {
  useDisclosure,
  Heading,
  Flex,
  useBreakpointValue,
} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { FaCalendar, FaTable } from 'react-icons/fa';
import { format } from 'date-fns';
import { useAuthStore, useManageReservationStore } from '@/services/store';
import useOwnedStudioReservations from '@/hooks/useOwnedStudioReservations';
import Button from '@/components/Buttons';
import { FlattenedBooking } from '@/components/DashBoard/ManageReservations/helper';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomButtonGroup from '@/components/Buttons/CustomButtonGroup';
import { DateRangeProps } from '@/components/ReactBigCalendar/helper';
import useStudioAvailability from '@/hooks/useStudioAvailability';
import { getMonthStartAndEndDates } from '@/services/dateTimeUtility';
import ReservationTableView from '@/components/DashBoard/ManageReservations/ReservationTableView';
import ReservationCalendarView from '@/components/DashBoard/ManageReservations/ReservationCalendarView';
import ManageReservationModals from '@/components/DashBoard/ManageReservations/ManageReservationModals';

interface Reservation {
  id: number;
  studio_id: number;
  dateTime: string;
  duration: number;
  price: number;
  userEmail: string;
  userId: number;
}

const ManageStudioReservations: React.FC = () => {
  const isBaseBreakpoint = useBreakpointValue({ base: true, md: false });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedReservation, setSelectedReservation] =
    useState<FlattenedBooking | null>(null);
  const { user } = useAuthStore();
  const navigate = useNavigate();
  const showToast = useShowToastTop();
  const { data, refetch, isLoading } = useOwnedStudioReservations(user?.id);
  const { updateAddModalVisibility } = useManageReservationStore();
  const [pageView, setPageView] = useState<'table' | 'calendar'>('table');
  const [dateRange, setDateRange] = useState<DateRangeProps>(
    getMonthStartAndEndDates(new Date()),
  );
  const { data: studioAvailability, refetch: calendarReFetch } =
    useStudioAvailability(
      format(dateRange?.start, 'yyyy-MM-dd'),
      format(dateRange?.end, 'yyyy-MM-dd'),
    );

  const buttons = [
    {
      label: 'Table',
      leftIcon: <FaTable />,
      onClick: () => setPageView('table'),
      variant: 'outline',
      hoverBgColor: 'honey.200',
      bg: pageView === 'table' ? 'honey.400' : 'default',
    },
    {
      label: 'Calendar',
      leftIcon: <FaCalendar />,
      onClick: () => setPageView('calendar'),
      variant: 'outline',
      hoverBgColor: 'honey.200',
      bg: pageView === 'calendar' ? 'honey.400' : 'default',
    },
  ];

  const flattenedReservations: FlattenedBooking[] =
    data?.reduce<FlattenedBooking[]>((acc, studio) => {
      const studioReservations = studio.reservations.map(
        (reservation: Reservation) => ({
          ...reservation,
          studioName: studio.studioName,
          roomName: studio.roomName,
          image: studio.images?.[0],
          userId: reservation?.userId,
        }),
      );
      return [...acc, ...studioReservations];
    }, []) || [];

  const handleReFetch = useCallback(() => {
    refetch();
    calendarReFetch();
  }, [refetch, calendarReFetch]);

  useEffect(() => {
    if (user && !user?.studioOwner) {
      navigate('/dashboard/bookings');
      showToast({
        message: 'User Not found',
        status: 'error',
      });
    }
  }, [user?.studioOwner]);

  return (
    <>
      <Flex flexDirection="column" gap={5}>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          gap={3}
        >
          <Heading fontSize={{ base: 'xl', md: '2xl' }}>
            Manage Reservations
          </Heading>
          <Flex gap={5} direction={{ base: 'column', md: 'row' }}>
            {user?.studioOwner && (
              <Button
                leftIcon={<AddIcon boxSize={{ base: 2, md: 3 }} />}
                onClick={updateAddModalVisibility}
                size="sm"
              >
                Add Reservations
              </Button>
            )}
            <CustomButtonGroup
              buttons={buttons}
              spacing={4}
              size="sm"
              isAttached
            />
          </Flex>
        </Flex>
        {pageView === 'table' && (
          <ReservationTableView
            onOpen={onOpen}
            isLoading={isLoading}
            flattenedReservations={flattenedReservations}
            setSelectedReservation={setSelectedReservation}
          />
        )}
        {pageView === 'calendar' && (
          <ReservationCalendarView
            setDateRange={setDateRange}
            studioAvailability={studioAvailability}
            dateRange={dateRange}
            onOpen={onOpen}
            isOpen={isOpen}
            onClose={onClose}
            setSelectedReservation={setSelectedReservation}
            isBaseBreakpoint={isBaseBreakpoint}
          />
        )}
      </Flex>
      <ManageReservationModals
        selectedReservation={selectedReservation}
        onClose={onClose}
        isOpen={isOpen}
        user={user}
        handleReFetch={handleReFetch}
      />
    </>
  );
};

export default React.memo(ManageStudioReservations);
