import React from 'react';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  GridItemProps,
  InputProps,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';
import CustomLabel from '@/components/Forms/CustomLabel';
import CustomAutoComplete from '@/components/Forms/CustomAutoComplete';

interface CustomInputProps extends InputProps {
  inputText: string;
  colSpan?: GridItemProps['colSpan'];
  name: string;
  control: any;
  customLabelStyle?: Record<string, any>;
  studioNames: any;
}

const ComboBox = ({
  inputText,
  control,
  name,
  colSpan,
  customLabelStyle = {},
  studioNames = [],
  ...props
}: CustomInputProps) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormControl
        mt={2}
        as={GridItem}
        colSpan={colSpan}
        isInvalid={!!fieldState.error}
        {...props}
      >
        <CustomLabel labelText={inputText} {...customLabelStyle} />
        <CustomAutoComplete
          options={studioNames.map((item: string) => ({  label: item,  value: item,}))}
          onChange={field?.onChange}
          placeholder="Studio Name"
          value={field?.value}
        />
        <FormErrorMessage>
          {fieldState.error ? fieldState.error.message : ''}
        </FormErrorMessage>
      </FormControl>
    )}
  />
);

export default ComboBox;
