import { useMutation } from '@tanstack/react-query';
import AuthAPIClient, { ChangePassword } from '@/services/auth-api-client';

const useChangePassword = () => {
  const authApiClient = new AuthAPIClient('/v1/auth/password');

  return useMutation({
    mutationKey: ['changePassword'],
    mutationFn: (changePassword: ChangePassword) =>
      authApiClient.changePassword(changePassword),
  });
};

export default useChangePassword;
