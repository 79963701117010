import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCopyToClipboard } from 'react-use';
import Button from '@/components/Buttons';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';

interface CopyToClipboardProps {
  children: React.ReactNode;
  contentToCopy: string;
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  children,
  contentToCopy,
}) => {
  const [, copyToClipboard] = useCopyToClipboard();
  const showToast = useShowToastTop();

  const handleCopyLink = () => {
    copyToClipboard(contentToCopy);
    showToast({
      message: 'Link Copied',
      status: 'success',
    });
  };

  return (
    <Button
      variant="outline"
      size="sm"
      display="flex"
      gap={2}
      onClick={handleCopyLink}
    >
      {children}
    </Button>
  );
};

export default React.memo(CopyToClipboard);
