import { toZonedTime } from 'date-fns-tz';
import { getBrowserTimeZone } from '@/components/Utilities/FormatDateTime';

export const initialTime = () => {
  const now = new Date();
  const minutes = now.getMinutes();
  const closestInterval = Math.ceil(minutes / 30) * 30;
  const difference = closestInterval - minutes;

  // Set time to the closest 30-minute interval
  now.setMinutes(now.getMinutes() + difference);
  now.setSeconds(0);
  now.setMilliseconds(0);

  // Format the time as a string
  const hours = String(now.getHours()).padStart(2, '0');
  const min = String(now.getMinutes()).padStart(2, '0');
  return `${hours}:${min}`;
};

const initialDate = new Date();
export const localDateTime = toZonedTime(initialDate, getBrowserTimeZone());
