import React from 'react';
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import useOwnedStudios, { UserStudios } from '@/hooks/useOwnedStudios';
import { useAuthStore, useStudioStore } from '@/services/store';
import GameCardSkeleton from '@/components/StudioCardSkeleton';
import StudioCard from '@/components/DashBoard/ManageStudiosComponent/StudioCard';
import CreateStudioCard from '@/components/DashBoard/ManageStudiosComponent/CreateStudioCard';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { useStudioDeleteAction } from '@/components/Utilities/StudiosHandler';

const ManageStudios = () => {
  const { user } = useAuthStore();
  const {
    updateDeleteModalVisibility,
    isDeleteModalVisible,
    studioDetails,
    submitBtnLoading,
  } = useStudioStore();

  const { data: studios, isLoading, refetch } = useOwnedStudios(user?.id);
  const handleDeleteAction = useStudioDeleteAction(refetch);

  const handleConfirm = () => {
    if (studioDetails?.id) {
      handleDeleteAction(studioDetails?.id);
    }
  };

  if (isLoading) {
    return (
      <SimpleGrid columns={[1, 1, 3]} spacing={6}>
        <GameCardSkeleton />
        <GameCardSkeleton />
        <GameCardSkeleton />
      </SimpleGrid>
    );
  }

  return (
    <Box>
      <Heading fontSize={{ base: 'xl', md: '2xl' }} mb={4}>
        Studios
      </Heading>
      <SimpleGrid columns={{ base: 1, md: 3, lg: 2, xl: 3 }} spacing={6}>
        {studios &&
          studios.map((studio: UserStudios) => (
            <StudioCard studio={studio} key={studio.id} />
          ))}
        <CreateStudioCard />
        <ConfirmModal
          visible={isDeleteModalVisible}
          handleClose={updateDeleteModalVisibility}
          handleConfirm={handleConfirm}
          confirmBtnLoading={submitBtnLoading}
          modalTitle="Delete confirmation"
          hideCloseIcon={submitBtnLoading}
        >
          <Text
            fontWeight="500"
            fontSize={{ base: 'sm', md: 'lg' }}
            style={{ marginBottom: '15px' }}
          >
            Are you sure you want to permanently delete the "
            {studioDetails?.studioName}" studio?
          </Text>

          <Text fontSize={{ base: 'sm', md: 'sm' }}>
            This action cannot be undone. All reservations, payments, and other
            records linked to this studio will be deleted.
          </Text>
        </ConfirmModal>
      </SimpleGrid>
    </Box>
  );
};

export default ManageStudios;
