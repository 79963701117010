import { FC, memo } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { IoIosInformationCircle } from 'react-icons/io';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import Button from '@/components/Buttons';

interface BookingInfoProps {
  visible: boolean;
  studioId: string | number | undefined;
}

const BookingInfoModal: FC<BookingInfoProps> = ({ visible, studioId }) => {
  const navigate = useNavigate();
  return (
    <ConfirmModal visible={visible} hideCloseIcon hideModalFooter>
      <VStack justifyContent="center" alignItems="center" gap={5}>
        <IoIosInformationCircle size="60px" color="#f30b0bc9" />
        <Text fontSize="2xl" textAlign="center" fontWeight="bold" px={12}>
          The reservation has expired. Please go back and try again.
        </Text>
        <Button onClick={() => navigate(`/studios/${studioId}`)}>
          Go Back
        </Button>
      </VStack>
    </ConfirmModal>
  );
};

export default memo(BookingInfoModal);
