import React, { useEffect } from 'react';
import { Flex, Spinner } from '@chakra-ui/react';
import {
  disconnectStripeAccount,
  getStripeAccountDetails,
  handleStripeSignIn,
  stripeConnectionInfo,
  useHandleStripeSignUp,
} from '@/components/Utilities/StripeConnection';
import { useAuthStore, useStripeConnectionStore } from '@/services/store';
import StripeConnectedCard from '@/components/DashBoard/StripeConnectedCard';
import StripeConnectOptions from '@/components/DashBoard/StripeConnectOptions';

const PaymentSettings: React.FC = () => {
  const { user } = useAuthStore();
  const handleStripeSignUp = useHandleStripeSignUp(user?.id);
  const getStripeDetails = getStripeAccountDetails(user?.id);
  const handleStripeDisconnect = disconnectStripeAccount(user?.id);
  const { isSignUpBtnLoading, isPageLoading, stripeDetails } =
    useStripeConnectionStore();

  useEffect(() => {
    if (user?.id) {
      getStripeDetails();
    }
  }, [user?.id]);

  if (isPageLoading) {
    return (
      <Flex w="full" h="full" justifyContent="center" alignItems="center">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    );
  }

  return stripeDetails ? (
    <StripeConnectedCard
      onDisconnect={handleStripeDisconnect}
      instructions={stripeConnectionInfo?.statusInstructions}
    />
  ) : (
    <StripeConnectOptions
      onSignIn={handleStripeSignIn}
      onSignUp={handleStripeSignUp}
      isSignUpLoading={isSignUpBtnLoading}
      description={stripeConnectionInfo?.description}
      instruction={stripeConnectionInfo?.instruction}
    />
  );
};

export default PaymentSettings;
