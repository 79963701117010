import { NavigateFunction } from 'react-router-dom';
import {
  profileDetailsUpdateMessage,
  publicRoutes,
  verifyContactMessage,
} from '@/components/Utilities/constants';
import { Bugsnag } from '@/bugsnag';
import { UserDetails } from '@/services/store';
import { ToastTopProps } from '@/components/Feedback/ShowToastTop';

export const handleErrorResponse = async (error: any) => {
  const currentPath = window.location.pathname;
  Bugsnag.notify(error);

  const isPublicRoute = publicRoutes.some((route) => {
    const dynamicRoutePattern = new RegExp(
      `^${route.replace(/:\w+/g, '[^/]+')}$`,
    );
    return dynamicRoutePattern.test(currentPath);
  });

  if (!isPublicRoute && error?.response?.status === 401) {
    window.location.href = '/';
  }
  return Promise.reject(error);
};

export const isArray = (value: any): value is any[] => Array.isArray(value);

const isObject = (value: any): value is Record<string, unknown> =>
  value !== null && typeof value === 'object' && !Array.isArray(value);

export const flattenAttributes = (data: any[] | Record<any, any>) => {
  if (isArray(data)) {
    return data.map(({ attributes, ...rest }: any) => ({
      ...rest,
      ...attributes,
    }));
  }
  if (isObject(data)) {
    const { attributes, ...rest } = data as { attributes: Record<string, any> };
    return { ...attributes, ...rest };
  }
};

export const handleStudioNavigation = (
  navigate: NavigateFunction,
  showToast: (props: ToastTopProps) => void,
  user?: UserDetails | null,
  fallbackUrl?: string,
) => {
  if (
    user?.stripeAccountId &&
    user?.stripeAccountStatus?.toLowerCase() === 'active' &&
    user?.otpVerifiedAt &&
    user?.firstName
  ) {
    navigate(fallbackUrl || '/studios/new');
  } else if (!user?.firstName || !user?.otpVerifiedAt) {
    showToast({
      message: !user?.firstName
        ? profileDetailsUpdateMessage
        : verifyContactMessage,
      status: 'error',
    });
    navigate('/dashboard/user_profile_edit');
  } else {
    showToast({
      // eslint-disable-next-line
      message: "To create a Studio, it's necessary to integrate a Stripe account.",
      status: 'error',
    });
    navigate('/dashboard/payment_settings');
  }
};
