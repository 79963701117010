import React from 'react';
import { Text, VStack, HStack, Divider, Center } from '@chakra-ui/react';

interface Props {
  date: string | undefined;
  time: string | undefined;
  durationInString: string | undefined;
  endDate: string | undefined;
  endTime: string | undefined;
}

const DateTimeInfoCard = ({
  date,
  time,
  durationInString,
  endDate,
  endTime,
}: Props) => (
  <HStack spacing={8} align="center">
    <VStack>
      <Text fontWeight="bold" fontSize="sm">
        Start date
      </Text>
      <HStack align="center">
        <VStack align="center">
          <Text fontWeight="semibold">{date}</Text>
          <Text color="garageGrey.500">{time}</Text>
        </VStack>
      </HStack>
    </VStack>
    <VStack>
      <Text fontWeight="bold" fontSize="sm">
        End date
      </Text>
      <HStack align="center">
        <VStack align="center">
          <Text fontWeight="semibold">{endDate}</Text>
          <Text color="garageGrey.500">{endTime}</Text>
        </VStack>
      </HStack>
    </VStack>
    <Center height="50px">
      <Divider orientation="vertical" />
    </Center>
    <HStack align="start">
      <VStack>
        <Text fontWeight="semibold">Duration</Text>
        <Text color="garageGrey.500">{durationInString}</Text>
      </VStack>
    </HStack>
  </HStack>
);

export default DateTimeInfoCard;
