import React from 'react';
import { useAuthStore } from '@/services/store';
import NavbarPostAuth from '@/components/Layouts/Navbar/NavbarPostAuth';
import NavbarPreAuth from '@/components/Layouts/Navbar/NavbarPreAuth';

const NavbarMain = () => {
  const { isAuthenticated } = useAuthStore((state) => state);

  return isAuthenticated ? <NavbarPostAuth /> : <NavbarPreAuth />;
};

export default NavbarMain;
