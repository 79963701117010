import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import * as zod from 'zod';
import useResetPassword from '@/hooks/useResetPassword';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import AuthForm from '@/components/Authentication/AuthForm';
import { commonErrorMessage } from '@/components/Utilities/constants';
import { passwordSchema } from '@/components/Authentication/helper';

const resetPasswordSchema = zod
  .object({
    password: passwordSchema,
    passwordConfirmation: passwordSchema,
  })
  .refine((data) => data.password === data.passwordConfirmation, {
    // eslint-disable-next-line
    message: 'Passwords don\'t match',
    path: ['passwordConfirmation'],
  });

const defaultValues = { password: '', passwordConfirmation: '' };

const ResetPasswordForm = () => {
  const { mutate, isPending } = useResetPassword();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const resetPasswordToken = queryParams.get('reset_password_token');
  const uid = queryParams.get('uid');
  const client = queryParams.get('client');
  const accessToken = queryParams.get('access-token');

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(resetPasswordSchema),
  });
  const showToast = useShowToastTop();
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    const payLoad = {
      ...data,
      resetPasswordToken,
      uid,
      client,
      accessToken,
    };
    mutate(payLoad, {
      onSuccess: () => {
        showToast({
          message: 'Password reset successfully! You may now proceed to login',
          status: 'success',
        });
        navigate('/auth/sign_in');
      },
      onError: (error: any) => {
        const errorMessage =
          error?.response?.data?.errors[0] ||
          error?.response?.data?.errors?.password[0] ||
          commonErrorMessage;
        showToast({
          message: errorMessage,
          status: 'error',
        });
      },
    });
  };

  return (
    <AuthForm
      methods={methods}
      onSubmit={onSubmit}
      submitButtonLabel="Reset password"
      isPending={isPending}
      heading="Enter new password"
    >
      <CustomInputField
        name="password"
        label="Password"
        control={methods.control}
        type="password"
        isRequired
      />
      <CustomInputField
        name="passwordConfirmation"
        label="Confirm Password"
        control={methods.control}
        type="password"
        isRequired
      />
    </AuthForm>
  );
};

export default ResetPasswordForm;
