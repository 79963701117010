import { CalendarIcon, EditIcon, LockIcon } from '@chakra-ui/icons';
import { FaCalendarCheck, FaFileImport, FaHome } from 'react-icons/fa';
import { MdOutlineLogout, MdOutlinePayment } from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';
import { UserDetails } from '@/services/store';

export const generateMenuItems = (
  user: UserDetails | null,
  signOut: () => void,
  isPending: boolean,
) => {
  const manageReservationMenuItem = [
    {
      icon: CalendarIcon,
      label: 'Manage Reservations',
      to: '/dashboard/manage_reservations',
      key: 'manage_reservations',
    },
  ];
  const bookingImportMenuItem = [
    {
      icon: FaFileImport,
      label: 'Booking Import',
      to: '/dashboard/booking_import',
      key: 'booking_import',
    },
  ];
  const adminMenuItem = [
    {
      icon: RiAdminFill,
      label: 'Admin Panel',
      to: '/admin',
      key: 'admin_panel',
      as: 'a',
    },
  ];
  return [
    {
      icon: FaHome,
      label: 'My Studios',
      to: '/dashboard/manage_studios',
      key: 'my_studios',
    },
    ...(user?.studioOwner
      ? [...manageReservationMenuItem, ...bookingImportMenuItem]
      : []),
    {
      icon: FaCalendarCheck,
      label: 'Bookings',
      to: '/dashboard/bookings',
      key: 'bookings',
    },
    {
      icon: MdOutlinePayment,
      label: 'Payment Settings',
      to: '/dashboard/payment_settings',
      key: 'payment_settings',
    },
    {
      icon: LockIcon,
      label: 'Change Password',
      to: '/dashboard/change_password',
      key: 'change_password',
    },
    {
      icon: EditIcon,
      label: 'Edit Profile',
      to: '/dashboard/user_profile_edit',
      key: 'edit_profile',
    },
    ...(user?.isAdmin ? adminMenuItem : []),
    {
      icon: MdOutlineLogout,
      label: 'Logout',
      key: 'logout',
      onClick: signOut,
      disabled: isPending,
    },
  ];
};
