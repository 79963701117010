import { Box, SimpleGrid, Spinner, Text } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import { orderBy } from 'lodash';
import useStudios, { Studio } from '@/hooks/useStudios';
import StudioCardSkeleton from '@/components/StudioCardSkeleton';
import StudioCardContainer from '@/components/StudioCardContainer';
import StudioCard from '@/components/StudioCard';
import SortMenu from '@/components/StudioIndex/SortMenu';

const StudioGrid = () => {
  const location = useLocation();
  const [sortOrder, setSortOrder] = useState('');
  const {
    data,
    totalEntries,
    error,
    isLoading,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useStudios();

  const sortedStudios = useMemo(() => {
    if (!data?.pages) return [];

    const studios = data.pages.flatMap((page: any) => page.data);
    const handleMissingPrice = (studio: Studio) => studio.price ?? 0;

    switch (sortOrder) {
      case 'Price Low to High':
        return orderBy(studios, [handleMissingPrice], ['asc']);
      case 'Price High to Low':
        return orderBy(studios, [handleMissingPrice], ['desc']);
      case 'Sorting by Newest':
        return orderBy(
          studios,
          [(studio) => new Date(studio.createdAt)],
          ['desc'],
        );
      case 'Sorting by Oldest':
        return orderBy(
          studios,
          [(studio) => new Date(studio.createdAt)],
          ['asc'],
        );
      default:
        return studios;
    }
  }, [data, sortOrder]);

  useEffect(() => {
    refetch();
  }, [location]);

  if (error) return <Text>{error.message}</Text>;

  const fetchStudioCount = () =>
    data?.pages.reduce((acc, page) => acc + (page.data.length || 0), 0);
  const studioCount = totalEntries;
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('query');

  const renderCard = () => {
    if (isLoading) {
      return Array.from({ length: 4 }).map((_, index) => (
        <StudioCardContainer key={index}>
          <StudioCardSkeleton />
        </StudioCardContainer>
      ));
    }
    return sortedStudios.map((item: Studio) => (
      <StudioCardContainer key={item.id}>
        <StudioCard studio={item} />
      </StudioCardContainer>
    ));
  };

  // Calculate placeholders needed to fill up to 4 items per row if fewer than 4 on the last row
  const totalStudios = sortedStudios.length;
  const itemsInLastRow = totalStudios % 4;
  const placeholdersNeeded = itemsInLastRow > 0 ? 4 - itemsInLastRow : 0;

  return (
    <InfiniteScroll
      dataLength={fetchStudioCount() || 0}
      next={fetchNextPage}
      hasMore={hasNextPage || false}
      loader={<Spinner />}
    >
      <Box maxWidth="1300px" mx="auto">
        <SortMenu setSortOrder={setSortOrder} />
        <Text
          fontSize="md"
          fontWeight="semibold"
          color="gray.600"
          textAlign="start"
          pl={4}
          mt={4}
          ml={{ base: 4, md: 4, lg: 0 }}
        >
          {query
            ? `Searching for rooms related to "${query}"`
            : `Showing all ${studioCount} rooms`}
        </Text>
        <SimpleGrid
          minChildWidth="300px"
          spacing={5}
          margin={{ base: 4, md: 4, lg: 0 }}
          placeItems={{ base: 'center', sm: 'start' }}
          padding={4}
        >
          {renderCard()}
          {Array.from({ length: placeholdersNeeded }).map((_, index) => (
            <Box
              key={`placeholder-${index}`}
              width="300px"
              height="400px"
              visibility="hidden"
            />
          ))}
        </SimpleGrid>
      </Box>
    </InfiniteScroll>
  );
};

export default StudioGrid;
