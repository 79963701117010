import React from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  Droppable,
} from '@hello-pangea/dnd';
import {
  Badge,
  Box,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Icon,
  Image as ChakraImage,
  Stack,
  Text,
  VisuallyHidden,
  chakra,
} from '@chakra-ui/react';
import { Control, Controller } from 'react-hook-form';
import { GoImage } from 'react-icons/go';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomLabel from '@/components/Forms/CustomLabel';

interface Props {
  images: (File | string)[];
  setImages: (images: (File | string)[]) => void;
  control: Control<any>;
  name: string;
  isRequired?: boolean;
  minImageWidth?: number;
  minImageHeight?: number;
}

const ImagesUpload = ({
  images = [],
  setImages = () => {},
  control,
  name,
  isRequired,
  minImageWidth = 1200,
  minImageHeight = 800,
}: Props) => {
  const toast = useShowToastTop();

  const validateFiles = async (
    files: File[],
    existingFiles: (File | string)[],
  ) => {
    const isValidType = (file: File) =>
      ['image/jpeg', 'image/png', 'image/jpg'].includes(file.type);
    const isValidSize = (file: File) => file.size <= 10 * 1024 * 1024; // 10MB
    const isDuplicate = (file: File) =>
      existingFiles.some(
        (existingFile) =>
          (typeof existingFile !== 'string' &&
            existingFile.name === file.name &&
            existingFile.size === file.size) ||
          (typeof existingFile !== 'string' &&
            existingFile.lastModified === file.lastModified &&
            existingFile.size === file.size),
      );

    const isValidDimensions = (file: File) =>
      new Promise((resolve) => {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);

        img.onload = () => {
          const isValidWidth = img.width >= minImageWidth;
          const isValidHeight = img.height >= minImageHeight;
          URL.revokeObjectURL(objectUrl);
          resolve(isValidWidth && isValidHeight);
        };

        img.src = objectUrl;
      });

    const validationResults = await Promise.all(
      files.map(async (file) => {
        const validDimensions = await isValidDimensions(file);
        return {
          file,
          isValidType: isValidType(file),
          isValidSize: isValidSize(file),
          isDuplicate: isDuplicate(file),
          isValidDimensions: validDimensions,
        };
      }),
    );

    const validFiles = validationResults
      .filter(
        (result) =>
          result.isValidType &&
          result.isValidSize &&
          !result.isDuplicate &&
          result.isValidDimensions,
      )
      .map((result) => result.file);

    const invalidTypeFiles = validationResults.filter(
      (result) => !result.isValidType,
    );
    const invalidSizeFiles = validationResults.filter(
      (result) => !result.isValidSize,
    );
    const duplicateFiles = validationResults.filter(
      (result) => result.isDuplicate,
    );
    const invalidDimensionFiles = validationResults.filter(
      (result) => !result.isValidDimensions,
    );

    if (invalidTypeFiles.length > 0) {
      toast({
        title: 'Invalid file type(s)',
        message: 'Only JPEG, PNG, and JPG files are allowed.',
        status: 'error',
      });
    }
    if (invalidSizeFiles.length > 0) {
      toast({
        title: 'Invalid file size(s)',
        message: 'File size should not exceed 10MB.',
        status: 'error',
      });
    }
    if (duplicateFiles.length > 0) {
      toast({
        title: 'Duplicate file(s)',
        message: 'Some files were already uploaded.',
        status: 'error',
      });
    }
    if (invalidDimensionFiles.length > 0) {
      toast({
        title: 'Invalid image dimensions',
        message: `Images must be at least ${minImageWidth}x${minImageHeight} pixels.`,
        status: 'error',
      });
    }

    return validFiles;
  };

  const handleImagesUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (files: (File | string)[]) => void,
  ) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const validFiles = await validateFiles(files, images);

      if (validFiles.length === 0) {
        event.target.value = ''; // Reset the input value
        return;
      }

      const newImages = [...images, ...validFiles];
      setImages(newImages);
      onChange(newImages);
      event.target.value = ''; // Reset the input value
    }
  };

  const removeImage = (
    indexToRemove: number,
    onChange: (files: (File | string)[]) => void,
  ) => {
    const newImages = images.filter((_, index) => index !== indexToRemove);
    setImages(newImages);
    onChange(newImages);
  };

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = Array.from(images);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setImages(items);
  };

  const handleFileDrop = async (
    event: any,
    onChange: (image: (File | string)[]) => void,
  ) => {
    event.preventDefault();
    const files: File[] = [];
    for (let i = 0; i < event.dataTransfer.items.length; i += 1) {
      const item = event.dataTransfer.items[i];
      if (item.kind === 'file') {
        const file = item.getAsFile() as File;
        files.push(file);
      }
    }

    const validFiles = await validateFiles(files, images);

    if (validFiles.length === 0) {
      return;
    }

    const newImages = [...images, ...validFiles];
    setImages(newImages);
    onChange(newImages);
  };

  return (
    <Box
      bg="white"
      border="1px"
      borderColor="powder.600"
      px={4}
      py={6}
      rounded={[null, 'md']}
      shadow="base"
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange }, fieldState }) => (
          <FormControl isInvalid={!!fieldState.error} isRequired={isRequired}>
            <CustomLabel
              labelText={
                <>
                  <Icon as={GoImage} w={6} h={6} mr={2} />
                  <Text as="span" fontSize="lg">
                    Studio Images
                  </Text>
                </>
              }
              display="flex"
              alignItems="center"
            />
            <Flex
              mt={4}
              justify="center"
              px={6}
              pt={5}
              pb={6}
              borderWidth={2}
              borderStyle="dashed"
              borderColor="garageGrey.200"
              rounded="md"
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => handleFileDrop(e, onChange)}
            >
              <Stack spacing={1} textAlign="center">
                <Icon
                  mx="auto"
                  boxSize={12}
                  color="gray.400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </Icon>

                <chakra.label
                  htmlFor="file-upload"
                  cursor="pointer"
                  rounded="md"
                  fontSize="md"
                  color="brand.600"
                  _dark={{ color: 'brand.200' }}
                  pos="relative"
                  _hover={{ color: 'brand.400', _dark: { color: 'brand.300' } }}
                >
                  <span>
                    <u>Upload files</u>
                  </span>
                  <VisuallyHidden>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      multiple
                      accept=".png, .jpg, .jpeg"
                      onChange={(event) => handleImagesUpload(event, onChange)}
                    />
                  </VisuallyHidden>
                </chakra.label>
                <Text pl={1}>or drag and drop</Text>
                <Text fontSize="xs" color="gray.500">
                  PNG, JPG, JPEG up to 10MB
                </Text>

                {/* Images preview */}
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="images">
                    {(provided) => (
                      <Flex
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        wrap="wrap"
                        mt={4}
                      >
                        {images.map((image, index) => (
                          <Draggable
                            key={index}
                            draggableId={String(index)}
                            index={index}
                          >
                            {(innerProvided: DraggableProvided) => (
                              <Box
                                ref={innerProvided.innerRef}
                                {...innerProvided.draggableProps}
                                {...innerProvided.dragHandleProps}
                                position="relative"
                                m={2}
                                borderRadius={10}
                              >
                                {index === 0 && (
                                  <Badge
                                    position="absolute"
                                    top="0"
                                    left="0"
                                    borderTopLeftRadius={10}
                                    zIndex="1"
                                    color="white"
                                    bg="blackAlpha.500"
                                    fontSize="xs"
                                  >
                                    Cover
                                  </Badge>
                                )}
                                <ChakraImage
                                  src={
                                    typeof image === 'string'
                                      ? image
                                      : URL.createObjectURL(image)
                                  }
                                  alt={
                                    typeof image === 'string'
                                      ? `Image ${index}`
                                      : image.name
                                  }
                                  boxSize="225px"
                                  objectFit="cover"
                                  borderRadius={10}
                                />
                                <CloseButton
                                  size="sm"
                                  position="absolute"
                                  borderRadius={30}
                                  bg="blackAlpha.500"
                                  color="white"
                                  top="0"
                                  right="0"
                                  onClick={() => removeImage(index, onChange)}
                                />
                              </Box>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </Flex>
                    )}
                  </Droppable>
                </DragDropContext>
              </Stack>
            </Flex>
            <FormHelperText>
              Please have a minimum of 5 quality images to showcase your studio.
            </FormHelperText>
            <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default ImagesUpload;
