import React from 'react';
import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';

export type ButtonVariantProps =
  | 'primary'
  | 'ghost'
  | 'outline'
  | 'link'
  | 'danger'
  | 'default';

interface ButtonProps extends ChakraButtonProps {
  variant?: ButtonVariantProps;
  icon?: React.JSX.Element;
}

const Button: React.FC<ButtonProps> = ({
  variant = 'primary',
  children,
  ...props
}) => {
  const variantStyles = {
    primary: {
      variant: 'primary',
      bg: 'honey.500',
      color: 'white',
      _hover: { bg: 'honey.500' },
      _active: { bg: 'honey.500' },
    },
    ghost: {
      variant: 'ghost',
    },
    outline: {
      variant: 'outline',
      borderColor: 'honey.700',
      color: 'honey.700',
      _hover: { bg: 'honey.100', color: 'honey.900', borderColor: 'honey.900' },
      _active: {
        bg: 'honey.100',
        color: 'honey.900',
        borderColor: 'honey.900',
      },
    },
    link: {
      variant: 'link',
      color: 'honey.700',
      textDecoration: 'underline',
      _active: { color: 'honey.700' },
    },
    danger: {
      colorScheme: 'red',
    },
    default: {
      variant: 'ghost',
      bg: 'gray.100',
      _hover: { bg: 'gray.200' },
    },
  };

  const selectedStyles = variantStyles[variant];

  return (
    <ChakraButton fontWeight={500} {...selectedStyles} {...props}>
      {children}
    </ChakraButton>
  );
};

export default React.memo(Button);
