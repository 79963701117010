import { memo, useEffect, useState } from 'react';
import { Text, VStack } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { addMinutes, differenceInSeconds } from 'date-fns';
import FormModal from '@/components/Modal/FormModal';
import { useAuthStore, useUserProfileStore } from '@/services/store';
import CustomPinInput from '@/components/Forms/CustomPinInput';
import Button from '@/components/Buttons';
import { OTPVerificationSchema } from '@/components/DashBoard/UserProfile/helper';
import useVerifyOtp from '@/hooks/useVerifyOtp';
import useSendOtp from '@/hooks/useSendOtp';
import { countryCode } from '@/components/Utilities/constants';

// Function to calculate time difference
const calculateTimeDifference = (expiryTime: Date) => {
  const remainingSeconds = differenceInSeconds(expiryTime, new Date());
  const getTime = () => ({
    minutes: String(Math.floor(remainingSeconds / 60)).padStart(2, '0'),
    seconds: String(remainingSeconds % 60).padStart(2, '0'),
  });
  return remainingSeconds > 0 ? getTime() : { minutes: '00', seconds: '00' };
};

const OTPVerificationModal = () => {
  const { isOTPModalVisible, setOTPModalVisibility, isBtnLoading } =
    useUserProfileStore();
  const { user } = useAuthStore();
  const handleVerifyOtp = useVerifyOtp();
  const handleSendOtp = useSendOtp();
  const [timeDifference, setTimeDifference] = useState(() =>
    calculateTimeDifference(addMinutes(new Date(user?.otpSentAt || ''), 1)),
  );

  const isTimeoutReached =
    timeDifference.minutes === '00' && timeDifference.seconds === '00';

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeDifference(
        calculateTimeDifference(addMinutes(new Date(user?.otpSentAt || ''), 1)),
      );
    }, 1000);

    return () => clearInterval(timer);
  }, [user]);

  const methods = useForm({
    mode: 'onChange',
    defaultValues: { otpCode: '' },
    resolver: zodResolver(OTPVerificationSchema),
  });

  const handleSubmit = (data: { otp: string }) => {
    handleVerifyOtp(data.otp);
  };

  const handleResendOtp = () => {
    if (user?.mobileNumber) {
      handleSendOtp(user.mobileNumber, countryCode, true);
    }
  };

  return (
    <FormModal
      visible={isOTPModalVisible}
      onClose={() => setOTPModalVisibility(false)}
      onSubmit={handleSubmit}
      title="Verification Code"
      methods={methods}
      modalSize={{ base: 'xs', sm: 'sm' }}
      hideCancelButton
      modalFooterStyle={{ border: 0, pt: 0 }}
      submitButtonStyle={{ width: '100%' }}
      submitButtonText="Confirm"
      isSubmitting={isBtnLoading}
    >
      <VStack justifyContent="space-between" spacing={20}>
        <VStack>
          <Text fontSize="sm" color="gray.500">
            We have sent the verification code to your mobile number
          </Text>
          <CustomPinInput
            name="otp"
            control={methods.control}
            placeholder=""
            isRequired
          />
        </VStack>
        <VStack spacing={0} color="gray.500">
          <Text fontSize="sm">
            {timeDifference.minutes} : {timeDifference.seconds}
          </Text>
          <Text fontSize="sm">
            Didn't receive the code?{' '}
            <Button
              isDisabled={!isTimeoutReached}
              variant="link"
              onClick={handleResendOtp}
            >
              Resend
            </Button>
          </Text>
        </VStack>
      </VStack>
    </FormModal>
  );
};

export default memo(OTPVerificationModal);
