import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useNavigate, useLocation } from 'react-router-dom';
import { Flex, HStack, Stack, Switch, Text } from '@chakra-ui/react';
import useSignIn, { Error } from '@/hooks/useSignIn';
import { UserDetails, useAuthStore } from '@/services/store';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import CustomInputField from '@/components/Forms/UsersField/CustomInputField';
import AuthForm from '@/components/Authentication/AuthForm';
import {
  AuthFormFooter,
  AuthFormHeading,
} from '@/components/Authentication/helper';

const signInSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z
    .string()
    .min(6, { message: 'Password must be at least 6 characters long' })
    .max(30, { message: 'Password must be at most 30 characters long' }),
  rememberMe: z.boolean(),
});

const defaultValues = { email: '', password: '', rememberMe: false };

const SignInForm = () => {
  const showToastTop = useShowToastTop();
  const location = useLocation();
  const setAuth = useAuthStore((state) => state.setAuth);
  const navigate = useNavigate();

  const methods = useForm({
    resolver: zodResolver(signInSchema),
    defaultValues,
  });

  const { mutate, isPending } = useSignIn();

  const onSubmit = (data: any) => {
    mutate(data, {
      onSuccess: (res) => {
        const user = res.data.data as UserDetails;
        setAuth(user);
        // Redirect user after successful login
        const from = location.state?.from || '/';
        navigate(from, { replace: true });
        showToastTop({
          message: `Logged in successfully, welcome ${user.firstName ? user.firstName : user.email}!`,
          status: 'success',
        });
      },
      onError: (error: unknown) => {
        const apiError = error as Error;
        const errorMessage =
          apiError.response && Array.isArray(apiError.response.data.errors)
            ? apiError.response.data.errors[0]
            : 'An unknown error occurred.';
        showToastTop({ message: errorMessage, status: 'error' });
      },
    });
  };

  const getFooterComponent = () => (
    <Flex direction="column" gap={2}>
      <Flex
        gap={2}
        direction={{ base: 'column', sm: 'row' }}
        alignItems="center"
      >
        <AuthFormFooter
          linkTo="/auth/sign_up"
          linkText="Create New Account"
          footerText="Not a member?"
        />
      </Flex>
      <Text
        _hover={{
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        color="blue.500"
        textAlign="center"
        onClick={() => navigate('/auth/forgot_password')}
      >
        Forgot password?
      </Text>
    </Flex>
  );

  return (
    <AuthForm
      heading={<AuthFormHeading title="Login to" />}
      onSubmit={onSubmit}
      submitButtonLabel="Sign in"
      isPending={isPending}
      methods={methods}
      footerComponent={getFooterComponent()}
    >
      <CustomInputField
        name="email"
        control={methods.control}
        type="email"
        label="Email Address"
      />
      <CustomInputField
        name="password"
        control={methods.control}
        type="password"
        label="Password"
      />
      <Stack spacing={10}>
        <HStack mt={4} align="start" justify="space-between" gap={5}>
          <Switch
            colorScheme="garageGrey"
            {...methods.register('rememberMe')}
            fontSize="md"
            display="inline-flex"
            whiteSpace="pre"
            alignItems="center"
            rowGap={3}
            cursor="pointer"
          >
            Remember me
          </Switch>
        </HStack>
      </Stack>
    </AuthForm>
  );
};

export default React.memo(SignInForm);
