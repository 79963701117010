import React from 'react';
import { Outlet } from 'react-router-dom';
import NavbarSimple from '@/components/Layouts/Navbar/NavbarSimple';

const BasicLayout = () => (
  <>
    <NavbarSimple />
    <Outlet />
  </>
);

export default BasicLayout;
