import React from 'react';
import {
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Heading,
} from '@chakra-ui/react';
import { FaChevronDown, FaCalendarDay, FaCalendarWeek } from 'react-icons/fa';
import { MdCalendarMonth, MdViewAgenda, MdViewWeek } from 'react-icons/md';
import { capitalize } from 'lodash';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import CustomButtonGroup from '@/components/Buttons/CustomButtonGroup';

interface CustomCalendarToolbarProps {
  onView: (view: string) => void;
  view: string;
  views: string[];
  label: string;
  onNavigate: (value: string) => void;
  hideDateRangeSelector: boolean;
  hideViewSelector: boolean;
}

const viewIcons: any = {
  day: FaCalendarDay,
  week: FaCalendarWeek,
  month: MdCalendarMonth,
  agenda: MdViewAgenda,
  work_week: MdViewWeek,
};

const CustomCalendarToolbar: React.FC<CustomCalendarToolbarProps> = ({
  onView,
  view,
  views,
  label,
  onNavigate,
  hideDateRangeSelector,
  hideViewSelector,
}) => {
  const buttons = [
    {
      label: <IoIosArrowBack />,
      onClick: () => onNavigate('PREV'),
      variant: 'outline',
      hoverBgColor: 'honey.100',
    },
    {
      label: 'Today',
      onClick: () => onNavigate('TODAY'),
      variant: 'outline',
      hoverBgColor: 'honey.100',
    },
    {
      label: <IoIosArrowForward />,
      onClick: () => onNavigate('NEXT'),
      variant: 'outline',
      hoverBgColor: 'honey.100',
    },
  ];
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={3}
    >
      {!hideDateRangeSelector && (
        <CustomButtonGroup
          buttons={buttons}
          spacing={2}
          isAttached
          size={{ base: 'sm', md: 'md' }}
        />
      )}
      <Heading fontSize={{ base: 'md', md: 'xl' }}>{label}</Heading>
      {!hideViewSelector && (
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<FaChevronDown />}
            variant="outline"
            _focus={{ borderColor: 'honey.300'}}
            _hover={{ borderColor: 'honey.300'}}
            borderColor={'powder.600'}
            _active={{ background: 'none' }}
          >
            {capitalize(view.replaceAll('_', ' '))}
          </MenuButton>
          <MenuList minW="100px" zIndex={100}>
            {views.map((viewName) => {
              const Icon = viewIcons[viewName] || null;
              return (
                <MenuItem
                  key={viewName}
                  onClick={() => onView(viewName)}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  value={viewName}
                  minW="150px"
                  _focus={{ background: 'none' }}
                  _hover={{ background: 'honey.300' }}
                  _active={{ background: 'honey.300' }}
                >
                  {Icon && <Icon />}
                  {capitalize(viewName.replaceAll('_', ' '))}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      )}
    </Box>
  );
};

export default React.memo(CustomCalendarToolbar);
