import { memo } from 'react';
import { Box, Image, Flex, Text, Divider } from '@chakra-ui/react';
import { Studio } from '@/hooks/useStudios';
import PriceCalculator from '@/components/StudioSideBar/PriceCalculator';

interface Props {
  duration: number | null;
  studio?: Studio;
}

const StudioSummaryCard = ({ duration, studio }: Props) => (
  <>
    <Flex alignItems="start">
      <Image
        borderRadius="md"
        boxSize="100px"
        src={
          studio?.images && studio?.images.length > 0 ? studio?.images[0] : ''
        }
        alt="Studio"
        mr={4}
      />
      <Box>
        <Text
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          alignItems="start"
        >
          {studio?.studioName}
        </Text>
        <Text>{studio?.roomName}</Text>
        {studio?.studioTypeLabel && (
          <Text fontSize="xs">{studio?.studioTypeLabel}</Text>
        )}
        {/* Rating and superhost badge are commented out as per the request
          <Flex alignItems="center" mt={2}>
            <Box as="span" color="red.500" mr={1}>
              ★ 4.94
            </Box>
            <Text as="span" fontSize="sm" color="gray.600">
              (253 reviews)
            </Text>
            <Box as="span" color="pink.400" ml={2} fontSize="sm">
              • Superhost
            </Box>
          </Flex>
          */}
      </Box>
    </Flex>
    <Divider my={4} />
    <Box>
      <Text as="h3" fontWeight="bold">
        Price details
      </Text>
      {studio && <PriceCalculator studio={studio} duration={duration} />}
    </Box>
  </>
);

export default memo(StudioSummaryCard);
