import React from 'react';
import { chakra, Flex, Image } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logo from '@/images/MuseHive_Horizontal-logo-Main_RGB.png';

const NavbarSimple = () => (
  <chakra.header
    w="full"
    justifyContent="center"
    px={{ base: 2, sm: 4 }}
    py={4}
  >
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      width="full"
      mx="auto"
    >
      <Link to="/" title="MuseHive">
        <Image src={logo} alt="Logo" height={14} width="auto" />
      </Link>
    </Flex>
  </chakra.header>
);

export default NavbarSimple;
