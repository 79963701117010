import { Stack, Button, Skeleton } from '@chakra-ui/react';
import { CTAButtonProps } from '@/pages/ListYourStudioCTAPage';

// eslint-disable-next-line react/prop-types
const JoinNowCTA: React.FC<CTAButtonProps> = ({ handleClick, isLoading }) => (
  <Stack align="center" pb={{ base: 4, md: 8 }}>
    <Skeleton isLoaded={!isLoading}>
      <Button onClick={handleClick} colorScheme="honey" fontWeight="bold">
        Start listing now
      </Button>
    </Skeleton>
  </Stack>
);

export default JoinNowCTA;
