export const handleFileDownload = async (
  url: string,
  fileName?: string,
  isBlob: boolean = false,
) => {
  try {
    let downloadUrl = url;
    if (isBlob) {
      const response = await fetch(url);
      const blob = await response.blob();
      downloadUrl = URL.createObjectURL(blob);
    }
    const link = document.createElement('a');
    link.href = downloadUrl;
    if (fileName) {
      link.download = fileName;
    }
    document.body.appendChild(link);
    link.click();
    link.remove();
    if (isBlob) {
      URL.revokeObjectURL(downloadUrl);
    }
  } catch (error) {
    console.error('Error downloading the file:', error);
  }
};
