import React, { useEffect, useState } from 'react';
import { SimpleGrid, Spinner, Box } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import useStudioList from '@/hooks/useStudioList';
import StudioCardContainer from '@/components/StudioCardContainer';
import StudioCardSkeleton from '@/components/StudioCardSkeleton';
import { Studio } from '@/hooks/useStudios';
import StudioCard from '@/components/StudioCard';

const StudioList = () => {
  const { data, fetchNextPage, hasNextPage, isLoading } = useStudioList();
  const studios = data?.pages.flatMap((page: any) => page.data) || [];

  const [shadow, setShadow] = useState('none');

  useEffect(() => {
    const container = document.getElementById('scrollable-container');
    if (!container) return; // Return early if container is null

    const handleScroll = () => {
      if (container.scrollTop === 0) {
        // User is at the top
        setShadow('inset 0px -8px 8px -8px rgba(0, 0, 0, 0.2)');
      } else if (
        container.scrollHeight - container.scrollTop ===
        container.clientHeight
      ) {
        // User is at the bottom
        setShadow('inset 0px 8px 8px -8px rgba(0, 0, 0, 0.2)');
      } else {
        // User is in the middle
        setShadow(
          'inset 0px -8px 8px -8px rgba(0, 0, 0, 0.2), inset 0px 8px 8px -8px rgba(0, 0, 0, 0.2)',
        );
      }
    };

    container.addEventListener('scroll', handleScroll);

    handleScroll();

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const renderCard = () => {
    if (isLoading && !studios.length) {
      return Array.from({ length: 4 }).map((_, index) => (
        <StudioCardContainer key={index}>
          <StudioCardSkeleton />
        </StudioCardContainer>
      ));
    }
    return studios.map((item: Studio) => (
      <StudioCardContainer key={item.id}>
        <StudioCard studio={item} />
      </StudioCardContainer>
    ));
  };

  // Calculate placeholders needed to fill up to 4 items per row if fewer than 4 on the last row
  const totalStudios = studios.length;
  const itemsInLastRow = totalStudios % 4;
  const placeholdersNeeded = itemsInLastRow > 0 ? 4 - itemsInLastRow : 0;
  return (
    <Box
      // id="scrollable-container"
      // maxHeight="120vh"
      // overflowY="auto"
      boxShadow={shadow}
      transition="box-shadow 0.3s"
    >
      <InfiniteScroll
        dataLength={studios.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        scrollThreshold="200px"
        // scrollableTarget="scrollable-container"
        loader={<Spinner />}
      >
        <SimpleGrid
          spacing={4}
          padding={4}
          minChildWidth="300px"
          placeItems={{ base: 'center', md: 'start' }}
        >
          {renderCard()}
          {Array.from({ length: placeholdersNeeded }).map((_, index) => (
            <Box
              key={`placeholder-${index}`}
              width="300px"
              height="400px"
              visibility="hidden"
              bg="white"
            />
          ))}
        </SimpleGrid>
      </InfiniteScroll>
    </Box>
  );
};

export default StudioList;
