import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import APIClient from '@/services/api-client';
import { Studio } from '@/hooks/useStudios';

// Function to create or edit a studio
const useStudioSubmit = (id?: number | string) => {
  const apiClient = id
    ? new APIClient(`/v1/studios/${id}`)
    : new APIClient('/v1/studios');

  return useMutation({
    mutationKey: ['studios', id],
    mutationFn: (studioData: Studio | FormData) => {
      if (id) {
        return apiClient.patch(studioData);
      }
      return apiClient.post(studioData);
    },
    onError: (error: AxiosError) => {
      if (error.response && error.response.status >= 400) {
        // This is where you would set the isError to true
        throw error;
      }
    },
  });
};

export default useStudioSubmit;
