import React, { useCallback, useState, useRef, useEffect } from 'react';
import { Box, Text, Button, Input, IconButton } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';

interface DragAndDropUploadProps {
  accept: string;
  maxSize: number;
  onFileChange: (files: any) => void;
  isMultipleFileUpload?: boolean;
  description?: string | React.ReactNode;
}

const DragAndDropUpload: React.FC<DragAndDropUploadProps> = ({
  accept,
  maxSize,
  onFileChange,
  isMultipleFileUpload = false,
  description,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const showToast = useShowToastTop();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = useCallback(
    (newFiles: File[]) => {
      if (!isMultipleFileUpload && files.length >= 1) {
        showToast({
          message: 'Only one file can be uploaded at a time.',
          status: 'error',
          title: 'Multiple file upload error',
        });
        return;
      }

      const validFiles = newFiles.filter((file) => {
        const fileType = file.type;
        const fileExtension = file.name.split('.').pop();

        // Check if the file type matches the accepted types
        if (
          !accept.includes(fileType) &&
          !accept.includes(`.${fileExtension}`)
        ) {
          showToast({
            message: `The file "${file.name}" is not an accepted type.`,
            status: 'error',
            title: 'File type error',
          });
          return false;
        }

        if (file.size > maxSize * 1024 * 1024) {
          showToast({
            message: `File ${file.name} exceeds the maximum size of ${maxSize} MB`,
            status: 'error',
            title: 'File size error',
          });
          return false;
        }
        return true;
      });

      const updatedFiles = isMultipleFileUpload
        ? [...files, ...validFiles]
        : validFiles.slice(0, 1);

      setFiles(updatedFiles);
    },
    [maxSize, isMultipleFileUpload, files, accept, showToast],
  );

  useEffect(() => {
    onFileChange(files);
  }, [files]);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      handleDrop(Array.from(event.target.files));
    }
  };

  const onDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      const newFiles = Array.from(event.dataTransfer.files);
      handleDrop(newFiles);
    },
    [handleDrop],
  );

  const handleDeleteFile = (removeFile: File) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((file) => file !== removeFile);
      if (fileInputRef?.current) {
        fileInputRef.current.value = '';
      }
      return updatedFiles;
    });
  };

  return (
    <Box>
      <Box
        border="2px dashed"
        borderColor="gray.300"
        borderRadius="md"
        textAlign="center"
        cursor="pointer"
        bg="gray.100"
        onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
        onDrop={onDrop}
      >
        <Input
          type="file"
          accept={accept}
          multiple={isMultipleFileUpload}
          onChange={handleFileSelect}
          display="none"
          id="file-upload"
          ref={fileInputRef}
        />
        <label htmlFor="file-upload">
          <Button
            as="span"
            cursor="pointer"
            h="120px"
            w="full"
            _hover={{ backgroundColor: 'none' }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap={2}
          >
            <MdOutlineCloudUpload size={40} />
            <Box whiteSpace="pre-wrap" fontSize={{ base: 'sm', md: 'initial' }}>
              Click or drag {isMultipleFileUpload ? 'files' : 'a file'} to this
              area to upload
            </Box>
            {description && (
              <Text
                whiteSpace="pre-wrap"
                fontSize={{ base: '2xs', md: 'xs' }}
                fontWeight="normal"
              >
                {description}
              </Text>
            )}
          </Button>
        </label>
      </Box>
      <Box>
        {files.length > 0 && (
          <>
            {files.map((file, index) => (
              <Box
                key={file.name}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom={files.length - 1 !== index ? '1px solid' : ''}
                borderColor="gray.200"
                py="2"
                px="4"
              >
                <Text>{file.name}</Text>
                <IconButton
                  isRound
                  icon={<DeleteIcon boxSize={4} />}
                  aria-label="Remove"
                  variant="none"
                  size="sm"
                  colorScheme="gray"
                  _hover={{ color: 'red.500' }}
                  onClick={() => handleDeleteFile(file)}
                />
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(DragAndDropUpload);
