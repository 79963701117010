import React from 'react';
import { Box, Button, Divider, Flex, Heading, Text } from '@chakra-ui/react';
import { ArrowForwardIcon, InfoIcon } from '@chakra-ui/icons';
import { BorderedBox } from '@/components/Utilities/CustomBorder';

interface StripeConnectOptionsProps {
  onSignIn: () => void;
  onSignUp: () => void;
  isSignUpLoading: boolean;
  description?: string;
  instruction?: string;
}

const StripeConnectOptions: React.FC<StripeConnectOptionsProps> = ({
  onSignIn,
  onSignUp,
  isSignUpLoading,
  description,
  instruction,
}) => (
  <>
    <Heading fontSize={{ base: 'xl', md: '2xl' }}>Payment Settings</Heading>
    <BorderedBox
      rounded="xl"
      w="full"
      maxW={{ base: 'full', lg: '2xl' }}
      display="flex"
      flexDirection="column"
      gap={4}
      p={6}
      bg="white"
      mt={15}
    >
      <Box p={4} bg="red.400" color="white">
        <Text textAlign="center" fontSize="sm">
          STRIPE CONNECTION STATUS
        </Text>
        <Text
          fontSize="md"
          display="flex"
          gap={2}
          alignItems="center"
          justifyContent="center"
          pt={2}
        >
          <InfoIcon />
          NOT CONNECTED
        </Text>
      </Box>
      <Flex flexDirection="column" gap={10}>
        <Flex flexDirection="column" gap={6}>
          <Text>{description}</Text>
          <Text>{instruction}</Text>
        </Flex>
        <Flex flexDirection="column" gap={5}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
          >
            <Text fontSize="md">Already using Stripe?</Text>
            <Button
              rightIcon={<ArrowForwardIcon />}
              bg="honey.500"
              color="white"
              borderRadius="md"
              onClick={onSignIn}
              _hover={{ backgroundColor: 'none' }}
            >
              Connect your Stripe Account
            </Button>
          </Box>
          <Divider />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={4}
          >
            <Text fontSize="md">Don't have a Stripe account yet?</Text>
            <Button
              rightIcon={<ArrowForwardIcon />}
              bg="honey.500"
              color="white"
              borderRadius="md"
              onClick={onSignUp}
              isLoading={isSignUpLoading}
              _hover={{ backgroundColor: 'none' }}
            >
              Open your Stripe Account
            </Button>
          </Box>
        </Flex>
      </Flex>
    </BorderedBox>
  </>
);

export default React.memo(StripeConnectOptions);
