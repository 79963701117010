import { useCallback } from 'react';
import APIClient from '@/services/api-client';
import { useReservationStore } from '@/services/store';
import { flattenAttributes } from '@/components/Utilities/helper';
import { UserReservation } from '@/hooks/useUserReservations';
import { ToastTopProps } from '@/components/Feedback/ShowToastTop';

export const getReservationDetails = (
  navigate: any,
  toast:  (props: ToastTopProps) => void,
  userId?: number,
  id?: string
) => {
  const apiClient = new APIClient<any>(
    `/v1/users/${userId}/reservations/${id}`,
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { setReservation } = useReservationStore();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useCallback(async () => {
    try {
      const response = await apiClient.get();
      setReservation(flattenAttributes(response?.data) as UserReservation);
    } catch (error: any) {
      if (error?.response?.status === 404) {
        navigate('/');
        toast({
          message: error?.response?.data?.error,
          status: 'error'
        });
      }
    }
  }, [userId, id]);
};
