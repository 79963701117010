import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';
import { Reservation } from '@/components/ReactBigCalendar/helper';

interface ReservationProps {
  data: { attributes: Reservation }[];
}

const useStudioAvailability = (
  startDate: string,
  endDate: string,
  id?: string,
) => {
  const url = id
    ? `/v1/studios/availability?id=${id}&start_date=${startDate}&end_date=${endDate}`
    : `/v1/studios/availability?start_date=${startDate}&end_date=${endDate}`;
  const apiClient = new APIClient<ReservationProps>(url);

  const { data, ...rest } = useQuery({
    queryKey: ['availability', id, startDate, endDate],
    queryFn: () => apiClient.get(),
  });
  return { data: data?.data, ...rest };
};

export default useStudioAvailability;
