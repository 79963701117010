import React, { useEffect } from 'react';
import {
  FormControl,
  FormErrorMessage,
  GridItem,
  GridItemProps,
  Select,
} from '@chakra-ui/react';
import { Controller, useFormContext } from 'react-hook-form';
import { allTimeZones } from '@/services/timezones';
import CustomLabel from '@/components/Forms/CustomLabel';

interface Props {
  name: string;
  control: any;
  colSpan?: GridItemProps['colSpan'];
  countryName?: string;
  isRequired?: boolean;
}

const TimeZoneField = ({
  name,
  control,
  colSpan,
  countryName,
  isRequired,
  ...props
}: Props) => {
  const { setValue } = useFormContext();
  const countryOptions = allTimeZones(countryName);
  const timeZoneOptions = countryOptions.map((tz, index) => (
    <option key={index} value={tz.name}>
      {`${tz.name} (UTC${tz.offset})`}
    </option>
  ));

  useEffect(() => {
    setValue(name, countryOptions[0]?.name);
  }, [countryOptions]);

  return (
    <GridItem rowSpan={1} colSpan={colSpan}>
      <Controller
        name={name}
        control={control}
        defaultValue={countryOptions[0]?.name}
        render={({ field, fieldState }) => (
          <FormControl
            isInvalid={fieldState.isTouched && fieldState.invalid}
            isRequired={isRequired}
          >
            <CustomLabel labelText="Time Zone" />
            <Select width="full" {...field} {...props}>
              {timeZoneOptions}
            </Select>
            <FormErrorMessage>
              {fieldState.isTouched && fieldState.error?.message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    </GridItem>
  );
};

export default TimeZoneField;
