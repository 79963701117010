import { FC, memo, useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Text,
} from '@chakra-ui/react';
import { capitalize } from 'lodash';
import {
  formatOperatingHours,
  OperatingHoursInAPIResponse,
} from '@/components/Utilities/StudiosHandler';
import { getBrowserTimeZone } from '@/components/Utilities/FormatDateTime';
import { convertTo12HourFormat } from '@/services/dateTimeUtility';
import { TimeSlot } from '@/components/CreateStudio/types';

interface StudioOperatingHours {
  operatingHours: OperatingHoursInAPIResponse[];
  isAlwaysOpen?: boolean;
  minDuration?: number;
}

type DayHours = {
  timeSlots: TimeSlot[];
};

const formatDayTimeSlots = (dayHours: DayHours): string => {
  if (dayHours.timeSlots?.length === 0) {
    return 'Closed';
  }

  return dayHours.timeSlots
    .map(
      (time) =>
        `${convertTo12HourFormat(time?.openTime || '')} - ${convertTo12HourFormat(time?.closeTime || '')}`,
    )
    .join(', ');
};

const StudioOperatingHours: FC<StudioOperatingHours> = ({
  operatingHours,
  isAlwaysOpen,
  minDuration,
}) => {
  const [containerHeight, setContainerHeight] = useState(450);
  const formattedHours = formatOperatingHours(
    operatingHours,
    getBrowserTimeZone(),
  );
  useEffect(() => {
    setContainerHeight(
      document.getElementsByClassName('operating-hours-container')[0]
        ?.clientHeight,
    );
  }, []);
  return (
    <Box>
      <HStack>
        <Heading fontSize={{ base: 'md', md: 'xl', lg: '2xl' }}>
          Operating hours
        </Heading>
        {minDuration && (
          <Badge p={2} colorScheme="orange" fontSize="sm" fontWeight="bold">
            Min. {minDuration} {minDuration > 1 ? 'hours' : 'hour'}
          </Badge>
        )}
      </HStack>
      {isAlwaysOpen ? (
        <Text mt={8}>Studio is available 24 hours a day, 7 days a week.</Text>
      ) : (
        <Flex
          direction="column"
          gap={5}
          mt={8}
          h={`calc(${containerHeight}px - 77px)`}
          overflowY="auto"
        >
          {Object.keys(formattedHours).map((day, index) => (
            <Grid templateColumns="repeat(3, 1fr)" key={index}>
              <GridItem colSpan={1}>
                <Text fontWeight={700}>{capitalize(day)}</Text>
              </GridItem>
              <GridItem colSpan={2} justifySelf="end">
                {formatDayTimeSlots(formattedHours[day])}
              </GridItem>
            </Grid>
          ))}
        </Flex>
      )}
    </Box>
  );
};

export default memo(StudioOperatingHours);
