import React from 'react';
import { Heading, Stack, useColorModeValue, Flex } from '@chakra-ui/react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import LoadingModalOverlay from '@/components/Feedback/LoadingModalOverlay';
import CustomSubmitButton from '@/components/Forms/UsersField/CustomSubmitButton';
import { BorderedBox } from '../Utilities/CustomBorder';

interface AuthFormProps {
  heading?: string | React.ReactNode;
  onSubmit: (values: FieldValues) => void;
  submitButtonLabel: string;
  isPending?: boolean;
  methods: UseFormReturn<any, any, undefined>;
  footerComponent?: React.ReactNode;
  children: React.ReactNode;
}

const AuthForm: React.FC<AuthFormProps> = ({
  heading,
  onSubmit,
  submitButtonLabel,
  isPending,
  methods,
  footerComponent,
  children,
}) => (
  <FormProvider {...methods}>
    <Flex
      align="center"
      justify="center"
      bg={useColorModeValue('white', 'gray.800')}
      direction="column"
      gap={4}
      h="85vh"
    >
      {heading && <Heading textAlign="center">{heading}</Heading>}
      <BorderedBox
        rounded="lg"
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow="lg"
        p={8}
        maxW="md"
        width={{ base: 'xs', md: 'md' }}
      >
        <Stack spacing={4} as="form" onSubmit={methods.handleSubmit(onSubmit)}>
          {children}
          <CustomSubmitButton isLoading={isPending}>
            {submitButtonLabel}
          </CustomSubmitButton>
          {footerComponent && (
            <Stack
              direction={{ base: 'column', sm: 'row' }}
              justify="center"
              alignItems="center"
            >
              {footerComponent}
            </Stack>
          )}
        </Stack>
      </BorderedBox>
      {isPending && <LoadingModalOverlay message={submitButtonLabel} />}
    </Flex>
  </FormProvider>
);

export default React.memo(AuthForm);
