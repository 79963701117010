import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
// import image from '@/images/MusicStudio.png';
import { CTAButtonProps } from '@/pages/ListYourStudioCTAPage';

const Hero: React.FC<CTAButtonProps> = ({ handleClick, isLoading }) => {
  const image =
    'https://res.cloudinary.com/dga1xuxac/image/upload/q_auto/v1730378806/Assets%20Image/Banner/MusicStudio_lqnzkp.png';

  return (
    <Box bg="white">
      {/* Hero Section */}
      <Box py={{ base: 10, sm: 16, lg: 24 }}>
        <Box px={{ base: 4, sm: 6, lg: 8 }} mx="auto">
          <Flex
            direction={{ base: 'column', lg: 'row' }}
            align="center"
            gap={6}
          >
            {/* Text Content */}
            <Box>
              <Text
                fontSize="base"
                fontWeight="semibold"
                textTransform="uppercase"
                color="blue.600"
              >
                Be part of the next creative hub for artists
              </Text>
              <Heading
                mt={{ base: 4, lg: 8 }}
                fontSize={{ base: '4xl', sm: '6xl', xl: '8xl' }}
                fontWeight="bold"
                color="black"
              >
                Earn money by listing your space
              </Heading>
              <Text
                mt={{ base: 4, lg: 8 }}
                fontSize={{ base: 'base', sm: 'xl' }}
                color="black"
              >
                Boost your revenue by renting your space to artists.
              </Text>
              <Flex justify={{ base: 'center', lg: 'flex-start' }}>
                <Skeleton isLoaded={!isLoading}>
                  <Button
                    onClick={handleClick}
                    mt={{ base: 4, lg: 8 }}
                    colorScheme="honey"
                    fontSize="lg"
                    rounded="md"
                    px={6}
                    py={6}
                  >
                    Get started
                  </Button>
                </Skeleton>
              </Flex>
            </Box>

            {/* Image Content */}
            <Box>
              <Image src={image} alt="Hero Image" w="full" h="full" />
            </Box>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
