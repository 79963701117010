export const convertTextToSlateValue = (value: string) => [
  {
    type: 'paragraph',
    children: [{ text: value }],
  },
];

export const countCharacters = (nodes: any) =>
  nodes.reduce((length: any, node: any) => {
    if ('text' in node) {
      return length + node.text.length;
    }
    if ('children' in node) {
      return length + countCharacters(node.children);
    }
    return length;
  }, 0);
