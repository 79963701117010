import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  GridItem,
  Heading,
  Icon,
  SimpleGrid,
  Stack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { PiHouse } from 'react-icons/pi';
import { SlLocationPin } from 'react-icons/sl';
import { useNavigate, useParams } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { RiTimerLine } from 'react-icons/ri';
import LoadingModalOverlay from '@/components/Feedback/LoadingModalOverlay';
import { useShowToastTop } from '@/components/Feedback/ShowToastTop';
import AddressField from '@/components/Forms/AddressField';
import CountryField from '@/components/Forms/CountryField';
import CustomPriceInput from '@/components/Forms/CustomPriceInput';
import CustomTextInput from '@/components/Forms/CustomTextInput';
import FormHeader from '@/components/Forms/FormHeader';
import ImagesUpload from '@/components/Forms/ImagesUpload';
import PostalField from '@/components/Forms/PostalField';
import TimeZoneField from '@/components/Forms/TimeZoneField';
import { RichTextBlock } from '@/components/Forms/RichTextBlock';
import SubmitButton from '@/components/Forms/SubmitButton';
import { useAuthStore, useStudioStore } from '@/services/store';
import { studioFormSchema } from '@/components/CreateStudio/StudioFormSchema';
import { createFormData } from '@/components/CreateStudio/createStudioUtilities';
import useStudioSubmit from '@/hooks/useStudioSubmit';
import { defaultValues } from '@/components/CreateStudio/Constant';
import OperatingHours from '@/components/CreateStudio/OperatingHours';
import { StudioConstants } from '@/hooks/useStudioConstants';
import CustomRadioGroup from '@/components/Forms/CustomRadioGroup';
import NumericStepperInputController from '@/components/Forms/NumericInputController';
import ComboBox from '../Forms/ComboBox';
import useOwnedStudios from '@/hooks/useOwnedStudios';

interface Props {
  data: StudioConstants | undefined;
}

interface StudioName {
  studioName: string;
}

const StudioForm: React.FC<Props> = ({ data }) => {
  const { formData, setImages, studioDetails, setStudioDetails } =
    useStudioStore();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { data: existingStudios } = useOwnedStudios(user?.id);
  const studioNames = [
    ...new Set(
      existingStudios?.map((studio: StudioName) => studio?.studioName) || [],
    ),
  ];
  const { id } = useParams();
  const showToast = useShowToastTop();
  const { mutate, isError, isPending, reset } = useStudioSubmit(id);
  const studioType = data?.studioType;
  const [deletedOperatingHours, setDeletedOperatingHours] = useState<any>();

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(studioFormSchema),
  });

  const onSubmit = (details: any) => {
    const handleError = (error: any) => {
      const errorMessage =
        isAxiosError(error) && error.response
          ? error.response?.data?.error?.[0] || 'An error occurred.'
          : 'An unknown error occurred.';
      showToast({ message: errorMessage, status: 'error' });
      reset();
    };

    const handleSuccess = () => {
      setStudioDetails(null);
      if (studioDetails) {
        showToast({
          message: 'Studio Updated successfully',
          status: 'success',
        });
        navigate('/dashboard/manage_studios');
      } else {
        navigate('/studios/new/confirmation');
      }
    };

    let removedImages: (string | File)[] = [];
    if (id) {
      const validImages = formData.images.filter(
        (item): item is string => typeof item === 'string',
      );
      removedImages = (studioDetails?.imagesUpload || []).filter(
        (img: any) => !validImages.includes(img),
      );
    }

    const formDataToSend = createFormData(
      details,
      formData.images,
      data?.daysOfWeek,
      deletedOperatingHours,
      removedImages,
    );

    mutate(formDataToSend, {
      onSuccess: handleSuccess,
      onError: handleError,
    });
  };

  useEffect(() => {
    methods.reset((studioDetails as any) || defaultValues);
  }, [studioDetails]);

  if (isPending) {
    return (
      <LoadingModalOverlay
        message={id ? 'Updating Studio...' : 'Creating Studio...'}
      />
    );
  }

  if (isError) {
    return <Heading>Something went wrong, please try again later.</Heading>;
  }

  return (
    <Box
      bg="powder"
      _dark={{ bg: '#111' }}
      marginX="auto"
      pt={8}
      px={4}
      pb={10}
      maxWidth="1300px"
    >
      <Box mt={[10, 0]}>
        <SimpleGrid
          display={{
            base: 'initial',
            md: 'grid',
          }}
          columns={{
            md: 3,
          }}
          spacing={{
            md: 6,
          }}
        >
          <FormHeader isFromEdit={!!id} />
          <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
            <FormProvider {...methods}>
              <chakra.form as="form" onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack
                  px={4}
                  py={5}
                  overflow={{
                    sm: 'hidden',
                  }}
                  spacing={4}
                  p={{
                    base: 0,
                    sm: 6,
                  }}
                >
                  <Box
                    bg="white"
                    px={4}
                    py={6}
                    border="1px"
                    borderColor="powder.600"
                    rounded={[null, 'md']}
                    shadow="base"
                  >
                    <Heading fontSize="lg" fontWeight="medium">
                      <Flex alignItems="flex-end" fontWeight={600}>
                        {' '}
                        <Icon as={PiHouse} w={6} h={6} mr={2} />
                        Studio Information
                      </Flex>
                    </Heading>
                    <SimpleGrid columns={6} spacing={4}>
                      <ComboBox
                        name="studioName"
                        control={methods.control}
                        inputText="Studio name"
                        colSpan={[6, 3]}
                        studioNames={studioNames}
                        isRequired
                      />
                      <CustomTextInput
                        name="roomName"
                        inputText="Room name"
                        control={methods.control}
                        colSpan={[6, 3]}
                        isRequired
                      />
                      <GridItem rowSpan={1} colSpan={5}>
                        <CustomRadioGroup
                          control={methods.control}
                          name="studioType"
                          data={studioType}
                          labelText="Studio Type(s)"
                          initialValue={
                            studioType && studioType?.length > 0
                              ? studioType[0]?.value
                              : ''
                          }
                          isRequired
                        />
                      </GridItem>
                    </SimpleGrid>
                  </Box>
                  <Box
                    bg="white"
                    border="1px"
                    borderColor="powder.600"
                    px={4}
                    py={6}
                    rounded={[null, 'md']}
                    shadow="base"
                  >
                    <Heading fontSize="lg" fontWeight="medium">
                      <Flex alignItems="flex-end" fontWeight={600}>
                        {' '}
                        <Icon as={SlLocationPin} w={6} h={6} mr={2} />
                        Location
                      </Flex>
                    </Heading>
                    <SimpleGrid columns={6} spacing={4}>
                      <AddressField
                        control={methods.control}
                        name="address"
                        colSpan={[6, 6]}
                        isRequired
                      />
                      <PostalField
                        control={methods.control}
                        name="postal"
                        colSpan={[6, 3]}
                        isRequired
                      />
                      <CountryField
                        control={methods.control}
                        name="country"
                        colSpan={[6, 3]}
                        isRequired
                      />
                      <TimeZoneField
                        control={methods.control}
                        name="timeZone"
                        colSpan={[6, 3]}
                        countryName={methods.watch('country')}
                        isRequired
                      />
                    </SimpleGrid>
                  </Box>
                  <OperatingHours
                    control={methods.control}
                    setDeletedOperatingHours={setDeletedOperatingHours}
                  />
                  <Box
                    bg="white"
                    px={4}
                    py={6}
                    rounded={[null, 'md']}
                    shadow="base"
                  >
                    <NumericStepperInputController
                      name="minimumBookingDuration"
                      control={methods.control}
                      labelText={
                        <Heading fontSize="lg" fontWeight="medium" mb={4}>
                          <Flex alignItems="flex-end" fontWeight="bold">
                            <Icon as={RiTimerLine} w={6} h={6} mr={2} />
                            Min. Duration
                          </Flex>
                        </Heading>
                      }
                      conversionFactor={60}
                      toolTipLabel="Enter the minimum booking duration in hours"
                    />
                  </Box>
                  <CustomPriceInput
                    name="price"
                    inputText="Price per hour"
                    colSpan={[6, 4]}
                    control={methods.control}
                    platformFeePercentage={
                      studioType?.find(
                        (item: any) =>
                          item.value === methods.watch('studioType'),
                      )?.platformCommissionRate
                    }
                  />
                  <RichTextBlock name="description" control={methods.control} />
                  <ImagesUpload
                    images={formData.images}
                    setImages={setImages}
                    name="imagesUpload"
                    control={methods.control}
                    isRequired
                  />
                  <SubmitButton
                    control={methods.control}
                    submitBtnText={id ? 'Update' : 'Create'}
                  />
                </Stack>
              </chakra.form>
            </FormProvider>
          </GridItem>
        </SimpleGrid>
      </Box>
    </Box>
  );
};

export default React.memo(StudioForm);
