import React from 'react';
import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import {
  CheckCircleIcon,
  CloseIcon,
  ExternalLinkIcon,
  InfoIcon,
} from '@chakra-ui/icons';
import { upperCase } from 'lodash';
import { useStripeConnectionStore } from '@/services/store';
import ConfirmModal from '@/components/Modal/ConfirmModal';
import { getStripeDashboardUrl } from '@/components/Utilities/StripeConnection';
import { BorderedBox } from '@/components/Utilities/CustomBorder';

interface StripeConnectedCardProps {
  onDisconnect: () => void;
  instructions?: string;
}

const StripeConnectedCard: React.FC<StripeConnectedCardProps> = ({
  onDisconnect,
  instructions,
}) => {
  const {
    stripeDetails,
    isConfirmBtnLoading,
    isModalVisible,
    updateModalVisibility,
  } = useStripeConnectionStore();
  const isAccountActive =
    stripeDetails?.stripeAccountStatus?.toLowerCase() === 'active';

  const accountDetails = [
    { heading: 'Bank Name', text: upperCase(stripeDetails?.stripeBankName) },
    { heading: 'Routing Number', text: stripeDetails?.stripeRoutingNumber },
    {
      heading: 'Account Last 4 digit',
      text: stripeDetails?.stripeLastFourDigits,
    },
  ];

  return (
    <>
      <Heading fontSize={{ base: 'xl', md: '2xl' }}>Payment Settings</Heading>
      <BorderedBox
        rounded="xl"
        w="full"
        maxW={{ base: 'full', lg: '2xl' }}
        display="flex"
        flexDirection="column"
        gap={4}
        p={6}
        bg="white"
        mt={15}
      >
        <Box p={4} bg={isAccountActive ? 'green.400' : 'red.400'} color="white">
          <Text textAlign="center" fontSize="sm">
            STRIPE CONNECTION STATUS
          </Text>
          <Text
            fontSize="lg"
            display="flex"
            gap={2}
            alignItems="center"
            justifyContent="center"
            pt={2}
          >
            {isAccountActive ? <CheckCircleIcon /> : <InfoIcon />}
            {stripeDetails?.stripeAccountStatus}
          </Text>
        </Box>
        {isAccountActive ? (
          <>
            {accountDetails.map((detail, index) => (
              <Box py={1} key={index}>
                <Heading size="xs">{detail?.heading}</Heading>
                <Text size="xs">{detail?.text}</Text>
              </Box>
            ))}
          </>
        ) : (
          <Flex
            py={6}
            flexDirection="column"
            gap={2}
            alignItems="center"
            justifyContent="center"
          >
            <Heading size="xs">What went wrong?</Heading>
            <Text fontSize={14} textAlign="center">
              {instructions}
            </Text>
          </Flex>
        )}
        <Flex
          justifyContent={{ base: 'center', md: 'space-between' }}
          flexWrap="wrap"
          gap={5}
        >
          <Button
            variant="outline"
            color="honey.500"
            borderColor="honey.500"
            _hover={{ backgroundColor: 'none' }}
            fontSize="sm"
            rightIcon={<ExternalLinkIcon />}
            onClick={() => window.open(getStripeDashboardUrl(), '_blank')}
          >
            Visit your Stripe Dashboard
          </Button>
          <Button
            variant="link"
            fontSize="sm"
            leftIcon={<CloseIcon boxSize={2.5} />}
            onClick={() => updateModalVisibility(true)}
            _hover={{ textDecoration: 'none' }}
          >
            Disconnect Stripe
          </Button>
        </Flex>
        <ConfirmModal
          visible={isModalVisible}
          handleClose={() => updateModalVisibility(false)}
          handleConfirm={onDisconnect}
          confirmBtnLoading={isConfirmBtnLoading}
          modalTitle="Do you want to disconnect your Stripe account?"
          hideCloseIcon
          confirmBtnText="Disconnect"
        >
          <Text>
            If you proceed, your studios will not be listed until the Stripe
            account is connected.
          </Text>
        </ConfirmModal>
      </BorderedBox>
    </>
  );
};

export default React.memo(StripeConnectedCard);
