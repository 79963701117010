import React from 'react';
import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  VisuallyHidden,
  Skeleton,
  Container,
} from '@chakra-ui/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '@/images/MuseHive_Horizontal-logo-Main_RGB.png';
import { useAuthStore, useStudioQueryStore } from '@/services/store';
import UserMenu from '@/components/Layouts/UserMenu';
import useSignOut from '@/hooks/useSignOut';
import { generateMenuItems } from '@/components/Layouts/helper';

const NavbarPreAuth = () => {
  const { user, isLoading } = useAuthStore();
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook

  const { resetStudioQuery } = useStudioQueryStore();
  const resetQuery = () => {
    resetStudioQuery();
  };
  const { mutate: signOut, isPending } = useSignOut();
  const menuItems = generateMenuItems(user, signOut, isPending);

  return (
    <Container
      as="header"
      borderBottom="1px"
      borderColor="powder.600"
      bg="white"
      w="full"
      position="sticky"
      maxW="100vw"
      zIndex={100}
      top={0}
    >
      <Container justifyContent="center" py={4} maxWidth="1300px">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="full"
          mx="auto"
        >
          <Flex>
            <Box title="MuseHive" display="flex" alignItems="center">
              <HStack spacing={8} alignItems="center">
                {/* Skeleton for the logo */}
                <Skeleton isLoaded={!isLoading}>
                  <Link to="/" onClick={resetQuery}>
                    <Image src={logo} alt="Logo" height={14} width="auto" />
                  </Link>
                </Skeleton>
              </HStack>
              <VisuallyHidden>MuseHive</VisuallyHidden>
            </Box>
          </Flex>
          <HStack display="flex" alignItems="center" spacing={1}>
            <HStack
              spacing={1}
              mr={1}
              color="garageGrey.500"
              display={{
                base: 'flex',
                md: 'inline-flex',
              }}
            >
              <Skeleton isLoaded={!isLoading}>
                {location.pathname !== '/list-your-studio' && (
                  <Button
                    zIndex={9999}
                    colorScheme="garageGrey"
                    onClick={() => navigate('/list-your-studio')}
                  >
                    List your studio
                  </Button>
                )}
              </Skeleton>
            </HStack>
            <Skeleton isLoaded={!isLoading}>
              <UserMenu user={user} menuItems={menuItems} />
            </Skeleton>
          </HStack>
        </Flex>
      </Container>
    </Container>
  );
};

export default NavbarPreAuth;
