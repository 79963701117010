import {
  Container,
  Stack,
  Flex,
  Box,
  Heading,
  Text,
  Image,
} from '@chakra-ui/react';
import { StudioCTAImage } from '@/images/imagesLink';

export const CTACard = () => (
  <Container maxW="7xl">
    <Stack
      align="center"
      spacing={{ base: 8, md: 10 }}
      py={{ base: 20, md: 28 }}
      direction={{ base: 'column', md: 'row' }}
    >
      <Stack flex={1} spacing={{ base: 5, md: 10 }}>
        <Heading
          lineHeight={1.1}
          fontWeight={600}
          fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
        >
          <Text
            as="span"
            position="relative"
            _after={{
              // eslint-disable-next-line quotes
              content: "''",
              width: 'full',
              height: '30%',
              position: 'absolute',
              bottom: 1,
              left: 0,
              bg: 'red.400',
              zIndex: -1,
            }}
          >
            Own a studio?
          </Text>
          <br />
          <Text as="span" color="honey.500">
            Join MuseHive Now!
          </Text>
        </Heading>
        <Text color="garageGrey.400">
          We offer studio owners a comprehensive solution to manage and unlock
          new revenue opportunities. By bridging the gap between between
          creators and spaces, MuseHive helps artists refine their craft while
          enabling studios to thrive as creative hubs.
        </Text>
      </Stack>
      <Flex
        flex={1}
        justify="center"
        align="center"
        position="relative"
        w="full"
      >
        <Box
          position="relative"
          height="300px"
          rounded="2xl"
          boxShadow="2xl"
          width="full"
          overflow="hidden"
        >
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <Image
              alt="Studio Image"
              fit="cover"
              align="center"
              w="100%"
              h="100%"
              src={StudioCTAImage}
              style={{ position: 'relative', zIndex: 1 }}
            />
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background:
                  'linear-gradient(45deg, rgba(255, 0, 0, 0.5), rgba(0, 0, 255, 0.5))',
                zIndex: 1,
                pointerEvents: 'none', // Ensures the gradient doesn't interfere with image interactions
              }}
            />
          </div>
        </Box>
      </Flex>
    </Stack>
  </Container>
);
