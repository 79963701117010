import {
  FormControl,
  FormErrorMessage,
  GridItem,
  GridItemProps,
  Select,
} from '@chakra-ui/react';
import * as React from 'react';
import { Controller } from 'react-hook-form';
import { allCountries } from 'country-region-data';
import CustomLabel from '@/components/Forms/CustomLabel';

interface Props {
  name: string;
  control: any;
  colSpan?: GridItemProps['colSpan'];
  isRequired?: boolean;
}

const CountryField = ({
  name,
  control,
  colSpan,
  isRequired,
  ...props
}: Props) => {
  const countryOptions = allCountries.map((country, index) => (
    <option key={index} value={country[0]}>
      {country[0]}
    </option>
  ));

  return (
    <GridItem rowSpan={1} colSpan={colSpan}>
      <Controller
        name={name}
        control={control}
        defaultValue="Singapore"
        render={({ field, fieldState }) => (
          <FormControl
            isInvalid={fieldState.isTouched && fieldState.invalid}
            isRequired={isRequired}
          >
            <CustomLabel labelText="Country" />
            <Select width="full" {...field} {...props}>
              {countryOptions}
            </Select>
            <FormErrorMessage>
              {fieldState.isTouched && fieldState.error?.message}
            </FormErrorMessage>
          </FormControl>
        )}
      />
    </GridItem>
  );
};

export default CountryField;
