import { useMutation } from '@tanstack/react-query';
import APIClient from '@/services/api-client';

export interface Reservation {
  id?: number;
  studio_id?: number;
  // Split Date and Time in my backend, but store dateTime
  dateTime: string | null;
  duration: number | null;
}

const useCreateReservation = (userId: number | undefined) => {
  const apiClient = new APIClient<Reservation | FormData>(
    `v1/users/${userId}/reservations`,
  );

  return useMutation({
    mutationKey: ['reservation', userId],
    mutationFn: (newReservation: Reservation | FormData) =>
      apiClient.post({ reservation: newReservation } as any),
  });
};

export default useCreateReservation;
