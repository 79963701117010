import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Flex, FormControl, Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import FormModal from '@/components/Modal/FormModal';
import {
  bookingImportFormData,
  bookingImportFormSchema,
  transformToStudioOptions,
  useBookingImportCSV,
} from '@/components/Utilities/BookingImport';
import FileUploadFormField from '@/components/Forms/FileUploadFormField';
import { useBookingImportStore, UserDetails } from '@/services/store';
import { getBrowserTimeZone } from '@/components/Utilities/FormatDateTime';
import CustomSelect from '@/components/Forms/CustomSelect';
import CustomFormErrorMessage from '@/components/Forms/CustomFormErrorMessage';
import useOwnedStudios from '@/hooks/useOwnedStudios';

interface ImportFormModalProps {
  user?: UserDetails | null;
}

interface FormValues {
  studioId: string;
  files: File[];
}

const ImportFormModal: React.FC<ImportFormModalProps> = ({ user }) => {
  const {
    isAddModalVisible,
    submitBtnLoading,
    updateAddModalVisibility,
    setSubmitBtnLoading,
  } = useBookingImportStore();
  const bookingImportCSV = useBookingImportCSV(user?.id);
  const { data: studio, isLoading } = useOwnedStudios(user?.id, 'reservations');

  const methods = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {},
    resolver: zodResolver(bookingImportFormSchema),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = methods;

  const onSubmit = (data: FormValues) => {
    setSubmitBtnLoading();
    const formData = bookingImportFormData(
      { timeZone: getBrowserTimeZone(), studioId: data?.studioId },
      data.files[0],
    );
    bookingImportCSV(formData);
  };

  return (
    <FormModal
      visible={isAddModalVisible}
      onClose={updateAddModalVisibility}
      onSubmit={handleSubmit(onSubmit)}
      title="Booking Import"
      methods={methods}
      isSubmitting={submitBtnLoading}
    >
      <Flex flexDirection="column" gap={4}>
        <Controller
          name="studioId"
          control={control}
          render={({ field: { onChange, value = '', name } }) => (
            <FormControl isInvalid={!!errors.studioId}>
              <CustomSelect
                id={name}
                name={name}
                value={value}
                onChange={onChange}
                isLoading={isLoading}
                options={transformToStudioOptions(studio)}
                labelText="Select a Studio"
                isRequired
              />
              <CustomFormErrorMessage errors={errors} name={name} />
            </FormControl>
          )}
        />
        <FileUploadFormField
          name="files"
          label="Upload File"
          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          maxSize={5} // 5 MB
          description="CSV or Excel file up to 5MB"
          isRequired
        />
        <Text display="flex" gap={2} alignItems="center" fontSize="sm">
          <InfoOutlineIcon boxSize={3} />
          <Text>
            Reservations will be created in <b>{getBrowserTimeZone()}</b>{' '}
            timezone
          </Text>
        </Text>
      </Flex>
    </FormModal>
  );
};

export default React.memo(ImportFormModal);
