import React from 'react';
import { Table } from 'antd';
import {
  FlattenedBooking,
  getTableColumns,
} from '@/components/DashBoard/ManageReservations/helper';
import { useCancelBookingStore } from '@/services/store';

interface TableViewProps {
  flattenedReservations: FlattenedBooking[];
  setSelectedReservation: (reservation: FlattenedBooking | null) => void;
  onOpen: () => void;
  isLoading: boolean;
}

const ReservationTableView: React.FC<TableViewProps> = ({
  flattenedReservations,
  setSelectedReservation,
  onOpen,
  isLoading,
}) => {
  const { updateCancelBookingModalVisibility } = useCancelBookingStore();
  return (
    <Table
      loading={isLoading}
      columns={getTableColumns(
        setSelectedReservation,
        updateCancelBookingModalVisibility,
      )}
      dataSource={flattenedReservations}
      sortDirections={['ascend', 'descend']}
      scroll={{ x: 'max-content' }}
      rowKey={(record) => record?.bookingNumber}
      onRow={(record) => ({
        onClick: () => {
          setSelectedReservation(record);
          onOpen();
        },
        style: { cursor: 'pointer' },
      })}
      bordered
    />
  );
};

export default React.memo(ReservationTableView);
