import { useQuery } from '@tanstack/react-query';
import APIClient from '@/services/api-client';
import { Studio } from '@/hooks/useStudios';
import { flattenAttributes } from '@/components/Utilities/helper';

const apiClient = new APIClient<{ data: Studio }>('/v1/studios');

const useStudio = (id: number | string) => {
  const { data, ...rest } = useQuery({
    queryKey: ['studio', id],
    queryFn: () => apiClient.get(id),
  });

  return {
    data: flattenAttributes(data?.data || {}) as Studio,
    ...rest,
  };
};

export default useStudio;
