import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';
import React from 'react';

interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Terms and Conditions</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <UnorderedList spacing={3} fontSize="xs">
          <ListItem>
            <b>1. Acceptance of Terms</b>
            <br />
            By using our platform and submitting your studio listing, you agree
            to be bound by these terms and conditions.
          </ListItem>
          <ListItem>
            <b>2. User Responsibilities</b>
            <br />
            You are responsible for providing accurate and up-to-date
            information in your studio listing and ensuring compliance with all
            applicable laws and regulations.
          </ListItem>
          <ListItem>
            <b>3. Prohibited Activities</b>
            <br />
            The following activities are prohibited on our platform: false
            advertising, copyright infringement, illegal activities, etc.
          </ListItem>
          <ListItem>
            <b>4. Content Ownership and Use</b>
            <br />
            You retain ownership of the content you upload but grant us a
            license to use this content for the operation and promotion of the
            platform.
          </ListItem>
          <ListItem>
            <b>5. Liability Disclaimer</b>
            <br />
            We are not liable for any issues arising from data breaches, loss of
            revenue, or legal violations by studio owners.
          </ListItem>
          <ListItem>
            <b>6. Indemnification</b>
            <br />
            You agree to indemnify and hold us harmless from any claims or
            damages resulting from your use of the platform.
          </ListItem>
          <ListItem>
            <b>7. Termination of Service</b>
            <br />
            We reserve the right to terminate accounts and remove listings that
            violate these terms and conditions.
          </ListItem>
          <ListItem>
            <b>8. Changes to Terms</b>
            <br />
            We may update these terms and conditions, and continued use of the
            platform constitutes acceptance of the new terms.
          </ListItem>
          <ListItem>
            <b>9. Dispute Resolution</b>
            <br />
            Any disputes will be resolved in accordance with the laws of
            Singapore, and you agree to submit to the jurisdiction of the courts
            of Singapore.
          </ListItem>
          <ListItem>
            <b>10. Cancellation Policy</b>
            <br />
            Cancellations made more than 48 hours before the scheduled booking
            time will receive a full refund.
            <br />
            Cancellations made less than 48 hours in advance will be charged 50%
            of the booking fee.
            <br />
            Cancellations made less than 24 hours in advance will be charged the
            full booking fee.
            <br />
            Failure to arrive for your booking without prior cancellation will
            be considered a no-show and will not be eligible for a refund.
            <br />
            Exceptions to this policy may be considered in cases of emergency or
            unforeseen circumstances, subject to proof and at the discretion of
            the studio management.
            <br />
            In the event of a dispute, MuseHive reserves the right to make the
            final decision.
            <br />
            Refunds will be processed within 5 to 10 business days.
          </ListItem>
          <ListItem>
            <b>11. Rate Parity</b>
            <br />
            Studio owners agree to the following terms regarding pricing:
            <br />
            <UnorderedList>
              <ListItem>
                <b>11.1. Consistent Pricing</b>
                <br />
                Studio owners must list their studios on our platform at rates
                that are equal to or lower than the rates listed on their own
                websites or other direct booking channels.
              </ListItem>
              <ListItem>
                <b>11.2. No Lower Rates on Direct Channels</b>
                <br />
                Studio owners must not offer their studios at a lower rate on
                their own websites, social media, or other direct booking
                channels than what is offered on our platform.
              </ListItem>
              <ListItem>
                <b>11.3. Reporting Obligations</b>
                <br />
                Studio owners must promptly inform our platform of any price
                changes or discounts offered on their own websites or direct
                booking channels and ensure these are reflected on our platform.
              </ListItem>
              <ListItem>
                <b>11.4. Enforcement and Monitoring</b>
                <br />
                Our platform reserves the right to regularly monitor the pricing
                of listed studios on the studio owners' own websites and direct
                booking channels. Any violations of this rate parity agreement
                may result in penalties, including but not limited to, the
                suspension or termination of the studio listing.
              </ListItem>
              <ListItem>
                <b>11.5. Exceptions</b>
                <br />
                Exceptions to this rate parity clause may be considered on a
                case-by-case basis, subject to prior written approval from our
                platform.
              </ListItem>
              <ListItem>
                <b>11.6. Dispute Resolution</b>
                <br />
                In the event of a dispute regarding pricing, our platform
                reserves the right to make the final decision. Studio owners
                agree to comply with the resolution determined by our platform.
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </ModalBody>
      <ModalFooter>
        <Button colorScheme="honey" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default TermsModal;
